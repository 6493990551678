import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { BasketInterface } from '../models/interfaces/basket.interface';
import { BasketItemInterface } from '../models/interfaces/basket-item.interface';
import { BasketItemPayloadInterface } from '../models/interfaces/basket-item-payload.interface';
import { environment } from '../../../../environments/environment';

/**
 * Service for interacting with the shopping basket through API calls.
 */
@Injectable({
  providedIn: 'root'
})
export class BasketApiService {

  /**
   * Constructor for BasketApiService.
   * @param apiService The ApiService for handling API requests.
   */
  constructor(
    private readonly apiService: ApiService,
  ) { }

  /**
   * Retrieves the shopping basket for a given user and practice.
   * @param userId The ID of the user.
   * @param practiceId The ID of the practice.
   * @returns An observable containing the shopping basket data.
   */
  getBasket(userId: number, practiceId: number): Observable<BasketInterface> {
    const url = `${environment.apiPrefix}/v1/shopping-cart/${userId}/practice/${practiceId}`;
    return this.apiService.getData(url);
  }

  /**
   * Adds an item to the shopping basket.
   * @param userId The ID of the user.
   * @param practiceId The ID of the practice.
   * @param basketItem The item to be added to the basket.
   * @returns An observable indicating the success of the operation.
   */
  addItemToBasket(userId: number, practiceId: number, basketItem: BasketItemPayloadInterface): Observable<any> {
    const url = `${environment.apiPrefix}/v1/shopping-cart/${userId}/practice/${practiceId}`;
    return this.apiService.postData(url, basketItem);
  }

  /**
   * Removes an item from the shopping basket.
   * @param userId The ID of the user.
   * @param practiceId The ID of the practice.
   * @param basketItem The item to be removed from the basket.
   * @returns An observable indicating the success of the operation.
   */
  removeItemFromBasket(userId: number, practiceId: number, basketItem: BasketItemInterface): Observable<any> {
    const url = `${environment.apiPrefix}/v1/shopping-cart/${userId}/practice/${practiceId}/items?id=${basketItem.id}`;
    return this.apiService.deleteData(url);
  }

  /**
   * Clears the entire shopping basket for a given user and practice.
   * @param userId The ID of the user.
   * @param practiceId The ID of the practice.
   * @returns An observable indicating the success of the operation.
   */
  clearBasket(userId: number, practiceId: number): Observable<any> {
    const url = `${environment.apiPrefix}/v1/shopping-cart/${userId}/practice/${practiceId}`;
    return this.apiService.deleteData(url);
  }
}
