import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { differenceInMinutes } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  private versionNumber = '2.1.36';
  private latestAPIVersion: string;
  private lastUpdated: Date;

  constructor(
    private readonly apiService: ApiService
  ) { }

  public getVersionNumber(): string {
    return this.versionNumber;
  }

  isVersionValid(): Observable<boolean> {
    // Checks api against the current app version to check if there are any updates.
    if (this.lastUpdated && this.latestAPIVersion && Math.abs(differenceInMinutes(this.lastUpdated, new Date())) <= 2) {
      return of(this.compareVersions(this.versionNumber, this.latestAPIVersion));
    }
    return this.apiService.getData(`${environment.apiPrefix}/v1/version`).pipe(
      map((data) => {
        this.latestAPIVersion = data.versions.find((x) => x.platform === 'pms')?.version_code as string;
        this.lastUpdated = new Date();
        return this.compareVersions(this.versionNumber, this.latestAPIVersion);
      })
    );
  }

  private compareVersions(appVersion: string, apiVersion: string): boolean {
    return appVersion === apiVersion;
  }
}
