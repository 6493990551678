<ng-template #labelPreview>
  <section>
    <h5>Label Preview</h5>
    <article class="label-preview">
      <img [src]="preview | urlDataSecurityBypass" alt="label preview" />
    </article>
  </section>
</ng-template>

<ng-template #printerPicker>
  <section>
    <h5>Print Label</h5>
    <p><em>Please note you will need to have the DYMO Connect software installed and a printer available to print labels</em></p>
    <mat-select [(ngModel)]="selectedPrinter" class="margin-b-sm">
      <mat-option *ngFor="let printer of printers" [value]="printer">{{printer}}</mat-option>
    </mat-select>
    <button
      mat-flat-button
      color="accent"
      [disabled]="!selectedPrinter"
      (click)="handleJobs(tempConfigs, selectedPrinter)">Print</button>
    <button
      mat-flat-button
      color="warn"
      (click)="cancelPrintLabel()">Cancel</button>
  </section>
</ng-template>
