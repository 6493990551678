<div class="order-container {{loading ? 'blur': ''}}" *ngIf="orderHistoryPagePaginationConfig?.total > 0 || orderHistoryPagePaginationConfig?.searchTerm?.length > 0 || filterStartDate || filterEndDate">
  <div class="order-history-header-container">
    <mat-icon>shopping_cart</mat-icon>
    <h6>Order history for {{fullName}}</h6>
  </div>

  <div class="search-container">
    <mat-form-field (click)="picker.open()"
      appearance="legacy"
      floatLabel="never">
      <mat-date-range-input [rangePicker]="picker" [comparisonStart]="filterStartDate" [comparisonEnd]="filterEndDate">
        <input (focusin)="picker.open()" matStartDate placeholder="Start date" [(ngModel)]="filterStartDate" name="start-date">
        <input (focusin)="picker.open()" matEndDate placeholder="End date" [(ngModel)]="filterEndDate" name="end-date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker>
        <mat-datepicker-actions>
          <button mat-button matDatepickerCancel>Cancel</button>
          <button mat-raised-button color="primary" matDatepickerApply (click)="applyFilter()">Apply</button>
        </mat-datepicker-actions>
      </mat-date-range-picker>
    </mat-form-field>
    <button *ngIf="filterStartDate || filterEndDate" class="clear-btn" matTooltip="Clear Date Filter" mat-icon-button (click)="clearDateFilter()"><mat-icon>close</mat-icon></button>
  </div>

  <div class="mat-elevation-z8 orders-table-container" *ngIf="orders?.length > 0">
    <table class="orders-table" mat-table [dataSource]="orderDataSource" matSort multiTemplateDataRows>

      <!-- ID Column -->
      <ng-container matColumnDef="order_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header data-joii-user-order-history-table-id-header> Order ID </th>
        <td mat-cell *matCellDef="let row" data-joii-user-order-history-table-id-content> {{row.order_id}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="customer_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </th>
        <td mat-cell *matCellDef="let row"> {{row.customer_name}} </td>
      </ng-container>

      <!-- Items column -->
      <ng-container matColumnDef="total_items">
        <th mat-header-cell *matHeaderCellDef mat-sort-header data-joii-user-order-history-table-total-items-header> Total items </th>
        <td mat-cell *matCellDef="let row" data-joii-user-order-history-table-total-items-content> {{row.number_of_items}} </td>
      </ng-container>

      <!-- Order total column -->
      <ng-container matColumnDef="order_total">
        <th mat-header-cell *matHeaderCellDef mat-sort-header data-joii-user-order-history-table-order-total-header> Order total </th>
        <td mat-cell *matCellDef="let row" data-joii-user-order-history-table-order-total-content> £{{(row.products_total_paid / 100) | number:'1.2-2'}} </td>
      </ng-container>

      <!-- Shipping cost column -->
      <ng-container matColumnDef="shipping_cost">
        <th mat-header-cell *matHeaderCellDef mat-sort-header data-joii-user-order-history-table-shipping-cost-header> Shipping cost </th>
        <td mat-cell *matCellDef="let row" data-joii-user-order-history-table-shipping-cost-content> £{{(row.shipping_price_paid / 100) | number:'1.2-2'}} </td>
      </ng-container>

      <!-- Shipped column -->
      <ng-container matColumnDef="shipped">
        <th mat-header-cell *matHeaderCellDef mat-sort-header data-joii-user-order-history-table-date-header> Order date </th>
        <td mat-cell *matCellDef="let row" data-joii-user-order-history-table-date-content> {{row?.shipped}} </td>
      </ng-container>

      <!-- Shipping refunded column -->
      <ng-container matColumnDef="shipping_refunded">
        <th mat-header-cell *matHeaderCellDef mat-sort-header data-joii-user-order-history-table-shipping-refunded-header> Shipping refunded? </th>
        <td mat-cell *matCellDef="let row" data-joii-user-order-history-table-shipping-refunded-content>
          <mat-icon *ngIf="row?.shipping_refunded === 0">close</mat-icon>
          <ng-container *ngIf="row?.shipping_refunded > 0">£{{(row.shipping_refunded / 100) | number:'1.2-2'}}</ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length"  >
          <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="width-100 margin-b-10 margin-t-10" *ngIf="element === expandedElement">
              <ng-container *ngTemplateOutlet="ProductsTable"></ng-container>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.example-expanded-row]="expandedElement === row" (click)="expandRow(row)">
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>

    <mat-paginator
      *ngIf="orderHistoryPagePaginationConfig"
      [pageIndex]="orderHistoryPagePaginationConfig.page - 1"
      [pageSize]="orderHistoryPagePaginationConfig.limit"
      [pageSizeOptions]="[5, 10, 15, 25, 50]"
      [length]="orderHistoryPagePaginationConfig.total"
      (page)="onPage($event)"></mat-paginator>
  </div>

</div>

<ng-container *ngIf="(!orders || orders?.length === 0) && !loading && !orderHistoryPagePaginationConfig.searchTerm && !filterStartDate && !filterEndDate">
  <div class="empty-container">
    <mat-icon>remove_shopping_cart</mat-icon>
    <p>No order history for {{fullName}}</p>
  </div>
</ng-container>

<ng-container *ngIf="loading">
  <div class="loading-container">
    <mat-progress-spinner diameter="40" mode="indeterminate"></mat-progress-spinner>
    <p>Loading order history for {{fullName}}...</p>
  </div>
</ng-container>

<ng-template #ProductsTable>
  <table class="product-table" mat-table [dataSource]="productsDataSource" matSort multiTemplateDataRows>
    <!-- ID Column -->
    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let row"> <img src="{{row.image}}"></td>
    </ng-container>
    <!-- ID Column -->
    <ng-container matColumnDef="product_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header data-joii-user-order-history-product-table-id-header> Product ID </th>
      <td mat-cell *matCellDef="let row" data-joii-user-order-history-product-table-id-content> {{row.item_id}} </td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="product_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header data-joii-user-order-history-product-table-name-header> Product Name</th>
      <td mat-cell *matCellDef="let row" data-joii-user-order-history-product-table-name-content> {{row.item_name}}</td>
    </ng-container>
    <!-- Quantity Column -->
    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header data-joii-user-order-history-product-table-quantity-header>Quantity</th>
      <td mat-cell *matCellDef="let row" data-joii-user-order-history-product-table-quantity-content>{{row?.quantity}}</td>
    </ng-container>
    <!-- Subscription Column -->
    <ng-container matColumnDef="subscription">
      <th mat-header-cell *matHeaderCellDef mat-sort-header data-joii-user-order-history-table-subscription-header> Subscription </th>
      <td mat-cell *matCellDef="let row" data-joii-user-order-history-table-subscription-content>
        <mat-icon class="no-subscription" *ngIf="row?.item_type !== 'subscription'" matTooltip="This product was bought standalone">close</mat-icon>
        <mat-icon class="subscription" *ngIf="row?.item_type === 'subscription'" matTooltip="This product was bought as a subscription">done</mat-icon>
      </td>
    </ng-container>
    <!-- Price Column -->
    <ng-container matColumnDef="price_paid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header data-joii-user-order-history-product-table-price-header>
        <mat-icon matTooltip="Total price paid : (Price per item * quantity)">info</mat-icon>Price
      </th>
      <td mat-cell *matCellDef="let row" data-joii-user-order-history-product-table-price-content>£{{(row.price_paid / 100) | number:'1.2-2'}}</td>
    </ng-container>
    <!-- Refunded Column -->
    <ng-container matColumnDef="refunded">
      <th mat-header-cell *matHeaderCellDef mat-sort-header data-joii-user-order-history-product-table-refunded-header>Refunded</th>
      <td mat-cell *matCellDef="let row" data-joii-user-order-history-product-table-refunded-content>
        £{{(row.refunded / 100) | number:'1.2-2'}}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="productDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: productDisplayedColumns;">
  </table>
</ng-template>
