import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-charity-tag',
  templateUrl: './charity-tag.component.html'
})
export class CharityTagComponent implements OnInit {

  @Input() charity: string;

  constructor() { }

  ngOnInit(): void {
  }

}
