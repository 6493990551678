import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
// Services
import { ApiService } from './api.service';
// Types
import { StaffStatusType } from '../models/types/staff-status.type';
/**
 * StaffService - Service for retrieving staff data from API (and staff member from local)
 */
@Injectable({
  providedIn: 'root'
})
export class StaffService {
  /**
   * constructor function
   * @param apiService - Service for API interactions
   */
  constructor(private apiService: ApiService) { }
  /**
   * getStaffFromLocal function - Returns currentUser from localStorage for use within the app
   */
  getStaffFromLocal() {
    return localStorage.getItem('currentUser');
  }
  /**
   * getStaffProfile function - Gets an individual staff profile from API
   * @param id - Staff ID
   */
  getStaffProfile(id): Observable<any> {
    return this.apiService.getData(environment.apiPrefix + '/v1/staff/' + id + '/profile');
  }
  /**
   * getStaffProfiles function - Gets all staff profiles from API
   */
  getStaffProfiles(): Observable<any> {
    return this.apiService.getData(environment.apiPrefix + '/v1/staff/profiles');
  }
  /**
   * updateStaffStatus function - Updates Staff Status
   * @param staffId - Staff ID
   * @param status - Staff Status
   */
  updateStaffStatus(staffId: number, status: StaffStatusType): Observable<any> {
    return this.apiService.patchData(`${environment.apiPrefix}/v1/staff/${staffId}/status`, {status});
  }
}
