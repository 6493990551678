// Angular / RXJS
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
// Services
import { LabelPrinterService } from '../../services/label-printer.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { FeatureManagementService } from '../../services/feature-management.service';
// Interfaces
import { FeatureInterface } from '../../models/interfaces/feature.interface';
// Enums
import { FeatureEnum } from '../../models/enums/feature.enum';
// Assets
import { prescriptionLabel } from '../../../../../assets/printer-labels/prescription-label';
/**
 * Label Printing Dialog Component
 */
@Component({
  selector: 'app-label-printing-dialog',
  templateUrl: './label-printing-dialog.component.html'
})
export class LabelPrintingDialogComponent implements OnInit, OnDestroy {
  featureConfig: FeatureInterface;
  preview: string; // Base64 image data
  tempConfigs: Array<any>;
  printers: Array<string>;
  selectedPrinter: string;
  private subscription: Subscription = new Subscription();
  @ViewChild('labelPreview') labelPreview: TemplateRef<any>;
  @ViewChild('printerPicker') printerPicker: TemplateRef<any>;
  /**
   *
   * @param dialog
   * @param featureManagementService
   * @param labelPrinterService
   * @param errorHandlerService
   */
  constructor(
    private readonly dialog: MatDialog,
    private readonly featureManagementService: FeatureManagementService,
    private readonly labelPrinterService: LabelPrinterService,
    private readonly errorHandlerService: ErrorHandlerService
  ) { }
  /**
   * Angular Init function - Sets feature config for label printing.
   */
  ngOnInit(): void {
    this.featureConfig = this.featureManagementService.getFeatureConfig(FeatureEnum.labelPrinting);
  }
  /**
   * Angular destroy function - clears active subscriptions
   */
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
  /**
   * previewLabel function - calls label printer service to generate a base64 image of the label to preview.
   * @param config
   */
  previewLabel(config: any) {
    if (!this.featureConfig.enabled) {
      this.errorHandlerService.handleError('Label Printing Feature Unavailable');
      return;
    }

    const label = this.labelPrinterService.readyLabel(prescriptionLabel, config);

    this.subscription.add(this.labelPrinterService.renderLabel(label).subscribe(data => {
      this.preview = `data:image/png;base64, ${data}`;
      this.dialog.open(this.labelPreview);
    }, data => {
      this.errorHandlerService.handleError(typeof data.error === 'string' ? data.error :
        'Unable to connect to Dymo connect');
    }));
  }
  /**
   * handleJobs function - Loops through all labels and sends jobs to printer.
   * @param labels
   * @param printer
   */
  handleJobs(labels: Array<any>, printer: string) {
    labels.forEach(label => {
      this.printLabel(label, printer);
    });
  }
  /**
   * printLabel function - prints a labels config on a specific printer.
   * @param config
   * @param printer
   */
  printLabel(config: any, printer: string) {
    if (!this.featureConfig.enabled) {
      this.errorHandlerService.handleError('Label Printing not enabled');
      return;
    }
    const label = this.labelPrinterService.readyLabel(prescriptionLabel, config);
    this.subscription.add(this.labelPrinterService.printLabel(printer, label).subscribe(data => {
      this.dialog.closeAll();
    }, data => {
      this.errorHandlerService.handleError(typeof data.error === 'string' ? data.error :
        'Unable to connect to Dymo connect');
    }));
  }
  /**
   * choosePrinter function - Shows printer selection dialog after getting the list of local printers.
   * Sets temp variable of pending labels so they can be printed from the dialog.
   * @param configs
   */
  choosePrinter(configs: Array<any>) {
    if (!this.featureConfig.enabled) {
      return;
    }

    this.tempConfigs = configs;

    this.subscription.add(
      this.labelPrinterService.getPrinters().subscribe(data => {
        this.printers = this.labelPrinterService.parsePrinterDetails(data);
        this.dialog.open(this.printerPicker, {disableClose: true});
      }, data => {
        this.errorHandlerService.handleError(typeof data.error === 'string' ? data.error :
          'Unable to connect to Dymo connect');
      })
    );
  }
  /**
   * cancelPrintLabel function - Closes printer selection and removes temp data.
   */
  cancelPrintLabel() {
    this.dialog.closeAll();
    this.tempConfigs = [];
    this.selectedPrinter = undefined;
  }

}
