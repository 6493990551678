import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { BasketApiService } from './basket-api.service';
import { BasketInterface } from '../models/interfaces/basket.interface';
import { BasketItemPayloadInterface } from '../models/interfaces/basket-item-payload.interface';
import { BasketItemInterface } from '../models/interfaces/basket-item.interface';
import { ConsultationSelectedProductInterface } from '../../consultation-shared/models/interfaces/consultation-selected-product.interface';

/**
 * Service for managing the shopping basket, including pending and confirmed items.
 */
@Injectable({
  providedIn: 'root'
})
export class BasketService {
  pendingBasketItems: BehaviorSubject<Array<ConsultationSelectedProductInterface>> = new BehaviorSubject([]);
  confirmedBasket: BehaviorSubject<BasketInterface> = new BehaviorSubject(null);

  /**
   * Constructor for BasketService.
   * @param basketApiService The service for interacting with the basket API.
   * @param storageService The service for managing local storage.
   */
  constructor(
    private readonly basketApiService: BasketApiService,
    private readonly storageService: LocalStorageService
  ) {}

  /**
   * Gets an observable of pending basket items.
   * @returns Observable of pending basket items.
   */
  pending(): Observable<Array<ConsultationSelectedProductInterface>> {
    return this.pendingBasketItems.asObservable();
  }

  /**
   * Gets an observable of the confirmed basket.
   * @returns Observable of the confirmed basket.
   */
  confirmed(): Observable<BasketInterface> {
    return this.confirmedBasket.asObservable();
  }

  /**
   * Gets the total value of the confirmed basket.
   * @returns The total value of the confirmed basket. Returns 0 if the basket is null.
   */
  confirmedTotal(): number {
    return this.confirmedBasket.value ? this.confirmedBasket.value.total || 0 : 0;
  }

  /**
   * Resets both pending and confirmed baskets.
   */
  reset(): void {
    this.pendingBasketItems.next([]);
    this.confirmedBasket.next(null);
  }

  /**
   * Updates the pending basket with the given products.
   * @param products Array of products to update the pending basket with.
   */
  updatePendingBasket(products: Array<ConsultationSelectedProductInterface>): void {
    this.pendingBasketItems.next(products);
    this.storageService.setData('basketProducts', this.pendingBasketItems.value);
  }

  /**
   * Sets the confirmed basket for a given user and practice.
   * @param userId The ID of the user.
   * @param practiceId The ID of the practice.
   */
  setConfirmedBasket(userId: number, practiceId: number): void {
    this.getAPIConfirmedBasket(userId, practiceId).pipe(take(1)).subscribe(
      (result) => {
        this.confirmedBasket.next(result);
      },
      (error) => {
        this.confirmedBasket.next(null);
      }
    );
  }

  /**
   * Gets the confirmed basket from the API.
   * @param userId The ID of the user.
   * @param practiceId The ID of the practice.
   * @returns Observable of the confirmed basket.
   */
  getAPIConfirmedBasket(userId: number, practiceId: number): Observable<BasketInterface> {
    return this.basketApiService.getBasket(userId, practiceId);
  }

  /**
   * Adds a product to the pending basket.
   * @param productDetails The details of the product to be added.
   */
  addProductToClientBasket(productDetails: ConsultationSelectedProductInterface): void {
    const exists = this.pendingBasketItems.value.some(check => check.product.doc_id === productDetails.product.doc_id);
    if (!exists) {
      this.pendingBasketItems.next([...this.pendingBasketItems.value, productDetails]);
    }
    this.storageService.setData('basketProducts', this.pendingBasketItems.value);
  }

  /**
   * Removes an item from the pending basket.
   * @param medication The medication to be removed.
   * @param index The index of the medication to be removed.
   */
  removeItemFromClientBasket(medication: ConsultationSelectedProductInterface, index: number): void {
    this.pendingBasketItems.value.splice(index, 1);
    this.pendingBasketItems.next([...this.pendingBasketItems.value]);
    this.storageService.setData('basketProducts', this.pendingBasketItems.value);
  }

  /**
   * Adds an item to the confirmed basket.
   * @param userID The ID of the user.
   * @param practiceID The ID of the practice.
   * @param basketItem The item to be added to the basket.
   * @returns Observable indicating the success of the operation.
   */
  addItemToConfirmedBasket(userID: number, practiceID: number, basketItem: BasketItemPayloadInterface): Observable<any> {
    return this.basketApiService.addItemToBasket(userID, practiceID, basketItem);
  }

  /**
   * Removes an item from the confirmed basket.
   * @param userID The ID of the user.
   * @param practiceID The ID of the practice.
   * @param basketItem The item to be removed from the basket.
   * @returns Observable indicating the success of the operation.
   */
  removeItemFromConfirmedBasket(userID: number, practiceID: number, basketItem: BasketItemInterface): Observable<any> {
    return this.basketApiService.removeItemFromBasket(userID, practiceID, basketItem);
  }

  /**
   * Clears the entire confirmed basket for a given user and practice.
   * @param userID The ID of the user.
   * @param practiceID The ID of the practice.
   * @returns Observable indicating the success of the operation.
   */
  clearBasket(userID: number, practiceID: number): Observable<any> {
    return this.basketApiService.clearBasket(userID, practiceID).pipe(
      map(() => this.setConfirmedBasket(userID, practiceID))
    );
  }

  /**
   * Gets the index of a medication in the pending basket.
   * @param medication The medication to find in the pending basket.
   * @returns The index of the medication in the pending basket.
   */
  getMedicationIndex(medication: ConsultationSelectedProductInterface): number {
    return this.pendingBasketItems.value.findIndex(x => x.product.doc_id === medication.product.doc_id);
  }

  /**
   * Calculates the total for a single item.
   * @param item The item to calculate the total for.
   * @returns The total for the item in pence.
   */
  calculateTotalForItemInPence(item: ConsultationSelectedProductInterface): number {
    let floatPence = 0;

    if (item && item.prescription && item.prescription.unit_quantity !== null) {
      const { unit_quantity, unit_price } = item.prescription;
      floatPence = unit_quantity * unit_price;
      return Math.round(floatPence);
    } else if (item && item.quantity >= 0 && item.product && item.product.price && item.product.price.price) {
      floatPence = item.quantity * item.product.price.price;
      return Math.round(floatPence);
    } else {
      return floatPence;
    }
  }

  /**
   * Checks if the basket is filled with pending or confirmed items.
   *
   * @returns {boolean} True if the basket is filled, otherwise false.
   */
  hasFilledBasket() {
    const pending = this.pendingBasketItems.value;
    const confirmed = this.confirmedBasket.value;

    return (pending?.length || 0) + (confirmed?.items?.length || 0) > 0;
  }
}

