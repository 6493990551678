import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// Services
import { ApiService } from '../../general-shared/services/api.service';
// Interfaces
import { PetInterface } from '../models/interfaces/pet.interface';
import { UserInterface } from '../../general-shared/models/interfaces/user.interface';
/**
 * PetService - Service for handling pet details
 */
@Injectable({
  providedIn: 'root'
})
export class PetService {
  /**
   * constructor function
   * @param apiService - Service for API interactions
   */
  constructor(private apiService: ApiService) {}
  /**
   * getPetById function - Gets pet details by Pet ID
   * @param id - Pet ID
   */
  public getPetById(id: number): Observable<PetInterface> {
    return this.apiService.getData(`${environment.apiPrefix}/v1/pets/${id}`).pipe(
      map((x) => {
        return x?.pet as PetInterface;
      })
    );
  }
  public getPetsByUser(userProfile: UserInterface): Observable<Array<any>> {
    const user = userProfile?.id?.toString();
    return this.apiService.getData(`${environment.apiPrefix}/v1/pets/optimised`, {user});
  }

  getPetBreeds(): Observable<any> {
    return this.apiService.getData(`${environment.apiPrefix}/v1/breeds`);
  }
}
