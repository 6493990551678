import { Pipe, PipeTransform } from '@angular/core';
/**
 * AgePipe - Pipe for displaying pet age
 * @ToDo: Maybe should be called pet age?
 */
@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {
  /**
   * transform function
   * @param value - pet age value (in weeks)
   */
  transform(value: any): any {
    let returnString = '';
    const petAge = Math.floor(value / 12);
    const remainder = value % 12;
    const months = Math.floor(remainder);
    const weeks = (remainder % 1) / 0.25;
    if (petAge !== 0) {
      returnString = petAge + 'y ';
    }
    if (Math.floor(remainder) !== 0) {
      returnString = returnString + months + 'm ';
    }
    if (weeks !== 0) {
      returnString = returnString + weeks + 'w';
    }
    return returnString;
  }

}
