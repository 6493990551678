<div class="process-container">
  <div class="terminal-connecting" *ngIf="status === terminalStatus.connectingReader">
    <h1>Connecting to card reader</h1>
    <div class="loader">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  <div class="payment-pending" *ngIf="status === terminalStatus.presentCard">
    <h1>Waiting for payment</h1>
    <div class="loader">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  <div class="payment-pending" *ngIf="status === terminalStatus.processingPayment">
    <h1>Processing payment</h1>
    <div class="loader">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  <div class="payment-successful" *ngIf="status === terminalStatus.paymentSuccessful">
    <h1>Payment successful</h1>
    <mat-icon>task_alt</mat-icon>
  </div>
  <div class="payment-warn" *ngIf="status === terminalStatus.paymentCancelled">
    <h1>Payment cancelled</h1>
    <mat-icon>cancel</mat-icon>
  </div>
  <div class="payment-error" *ngIf="status === terminalStatus.paymentRequiresAction">
    <h1>Payment requires action</h1>
    <mat-icon>notification_important</mat-icon>
  </div>
</div>
