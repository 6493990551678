<div class="gallery-container">
  <button *ngIf="images?.length > 1" class="back" mat-fab color="primary" (click)="onPrevious()">
    <mat-icon>arrow_back_ios_new</mat-icon>
  </button>
  <img *ngIf="images?.length > 0" [src]="images[selectedIndex]">
  <button *ngIf="images?.length > 1" mat-fab class="forward" color="primary" (click)="onNext()">
  <mat-icon>arrow_forward_ios</mat-icon>
  </button>
  <mat-icon class="close-icon">close</mat-icon>
</div>
