import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, Subscription } from 'rxjs';
import { isBoolean } from 'lodash';
import { take } from 'rxjs/operators';

import { QueueStyleType } from '../../../queue-shared/models/types/queue-style.type';
import { FeatureEnum } from '../../models/enums/feature.enum';

import { AuthService } from '../../../../auth/services/auth.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { WebsocketsService } from '../../services/websockets.service';
import { StaffService } from '../../services/staff.service';
import { RingerService } from '../../services/ringer.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { ChromeNotificationsService } from '../../services/chrome-notifications.service';
import { FeatureManagementService } from '../../services/feature-management.service';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html'
})
export class MainHeaderComponent implements OnInit, OnDestroy {
  @Input() versionNumber: string;
  menuItems: Array<any>;
  queueStyleType: QueueStyleType;
  loggedInUser: any;
  isRingerEnabled: boolean;
  nurseQueue: Array<any> = [];
  vetQueue: Array<any> = [];
  isFirstRun = true;
  movingToPractice: boolean;
  movingToRemote: boolean;
  inPractice: boolean;
  subscriptions: Subscription = new Subscription();
  ringerSubscription: Subscription = new Subscription();
  @ViewChild('practiceChangeWarning', {static: true}) practiceChangeWarning: TemplateRef<any>;

  constructor(private router: Router,
              private authService: AuthService,
              private dialog: MatDialog,
              private websocketsService: WebsocketsService,
              private readonly localStorageService: LocalStorageService,
              private readonly staffService: StaffService,
              private readonly ringerService: RingerService,
              private readonly chromeNotifications: ChromeNotificationsService,
              private readonly errorHandlerService: ErrorHandlerService,
              private readonly featureManagementService: FeatureManagementService
  ) { }

  ngOnInit() {
    this.loggedInUser = JSON.parse(this.staffService.getStaffFromLocal());
    const inPracticeCheck = localStorage.getItem('inPractice');
    if (inPracticeCheck) {
      this.inPractice = !!JSON.parse(inPracticeCheck);
    } else {
      this.inPractice = !!this.loggedInUser?.practice_id;
    }
    this.localStorageService.setData('inPractice', this.inPractice);
    this.connectWebsocket();
    this.sendQueueNotifications();
    this.ringerService.initialiseRingerStatus();
    this.chromeNotifications.requestPermissions();
    this.getRingerChanges();
    this.queueStyleType = this.localStorageService.getData('queueStyleType') || 'card';
    this.setMenuItems();
    this.getRouteParams();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.ringerSubscription.unsubscribe();
    this.ringerService.cancelRinger();
  }

  getRouteParams() {
    this.setMenuActive(this.router.url);
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.setMenuActive(event.url);
      }
    });
  }

  connectWebsocket() {
    this.websocketsService.reconnect();
  }

  setMenuActive(route) {
    this.menuItems.forEach(item => {
      if (route.includes('history') && item.route === '/history/users') {
        item.isActive = true;
        return false;
      }
      item.isActive = route === item.route;
    });
  }

  setMenuItems() {
    this.menuItems = this.getMenuItems();
  }

  logout() {
    const loggedInUser = JSON.parse(this.staffService.getStaffFromLocal()) as any;
    this.staffService.updateStaffStatus(loggedInUser.id, 'offline').subscribe();
    this.clearCachedData();
    this.router.navigate(['./auth/login']);
  }

  goToRoute(route) {
    let queryParams: any = {};
    if ((this.router.url === '/home/remote' && route === '/home/practice') ||
      (!this.inPractice && this.loggedInUser.practice_id && route === '/home/practice')) {
      this.movingToPractice = true;
      this.movingToRemote = false;
      this.dialog.open(this.practiceChangeWarning, {disableClose: true});
      return false;
    }
    if ((this.router.url === '/home/practice' && route === '/home/remote') || (this.inPractice && route === '/home/remote')) {
      this.movingToPractice = false;
      this.movingToRemote = true;
      this.dialog.open(this.practiceChangeWarning, {disableClose: true});
      return false;
    }
    const loggedInUser = JSON.parse(this.staffService.getStaffFromLocal()) as any;
    if (loggedInUser?.practice_id && (route.includes('calendar') || route.includes('rota'))) {
      queryParams = {
        practice_id: this.loggedInUser.practice_id
      };
    }
    this.router.navigate([route], {queryParams: {...queryParams}});
  }

  closeWarningModal() {
    this.dialog.closeAll();
  }

  goToHome() {
    this.closeWarningModal();
    this.websocketsService.sendQueueToggle();
    if (this.movingToRemote) {
      this.inPractice = false;
      this.router.navigate(['/home/remote']);
    } else {
      this.inPractice = true;
      this.router.navigate(['/home/practice']);
    }
    this.localStorageService.setData('inPractice', this.inPractice);
  }

  clearCachedData() {
    this.localStorageService.clearAll();
    this.websocketsService.clearAllMessages();
    this.websocketsService.disconnect();
  }

  getRingerChanges() {
    // Gets ringer changes from the status toggle.
    this.subscriptions.add(this.localStorageService.getPersistedDataChanges().subscribe(changes => {
      if (changes && changes.hasOwnProperty('ringerStatus')) {
        const newIsRingerEnabled = JSON.parse(changes.ringerStatus);
        if (isBoolean(newIsRingerEnabled) && newIsRingerEnabled !== this.isRingerEnabled) {
          this.getRingerStatus().pipe(
            take(1)
          ).subscribe((isRingerAllowed) => {
            if (isRingerAllowed && (this.vetQueue?.length > 0 || this.nurseQueue?.length > 0)) {
              this.ringerService.startRinger();
            }
            if (!isRingerAllowed || (this.vetQueue === null && this.nurseQueue === null) ||
              (this.vetQueue?.length === 0 && this.nurseQueue?.length === 0)) {
              this.ringerService.cancelRinger();
            }
          });
        }
      }
    }));
  }

  sendQueueNotifications(): void {
    this.subscriptions.add(this.websocketsService.getQueueMessages().subscribe((data) => {
        if (data?.type && data?.payload) {
          switch (data.type) {
            case 'queue':
              if (!this.router.url.includes('/consultation/') && !this.router.url.includes('/home/practice')) {
                this.sendNotificationAndRing(
                  {
                    oldQueue: JSON.parse(JSON.stringify(this.vetQueue)),
                    newQueue: JSON.parse(JSON.stringify(data.payload.vet))
                  },
                  {
                    oldQueue: JSON.parse(JSON.stringify(this.nurseQueue)),
                    newQueue: JSON.parse(JSON.stringify(data.payload.nurse))
                  }
                );
              }
              if (!this.router.url.includes('/home/practice')) {
                this.nurseQueue = this.replaceArray(this.nurseQueue || [], data.payload.nurse || []);
                this.vetQueue = this.replaceArray(this.vetQueue || [], data.payload.vet || []);
              }
              break;
          }
        }
      },
      (error) => {
        this.errorHandlerService.handleError(error?.error || error);
      }));
  }

  sendNotificationAndRing(vetQueues: { newQueue: any[], oldQueue: any[] }, nurseQueues: { newQueue: any[], oldQueue: any[] }) {
    if (this.ringerSubscription) {
      this.ringerSubscription.unsubscribe();
    }
    this.ringerSubscription = new Subscription();
    this.ringerSubscription.add(this.getRingerStatus().subscribe(isRingerAllowed => {
      this.ringerService.setRingerEnabledStatus(isRingerAllowed);
      if (isRingerAllowed && this.hasNewClientJoinedQueue(vetQueues, nurseQueues)) {
        this.ringerService.startRinger();
      }
      if (this.hasNewClientJoinedQueue(vetQueues, nurseQueues)) {
        if (!this.isFirstRun) {
          this.sendQueueUpdates(vetQueues, nurseQueues);
        }
      }
      if (isRingerAllowed && !this.hasNewClientJoinedQueue(vetQueues, nurseQueues) &&
        (vetQueues.newQueue?.length > 0 || nurseQueues.newQueue?.length > 0)) {
        this.ringerService.startRinger();
      }
      if ((vetQueues.newQueue === null && nurseQueues.newQueue === null) ||
        (vetQueues.newQueue?.length === 0 && nurseQueues.newQueue?.length === 0)) {
        this.ringerService.cancelRinger();
      }
      this.isFirstRun = false;
    }));
  }

  sendQueueUpdates(vetQueues: { newQueue: any[], oldQueue: any[] }, nurseQueues: { newQueue: any[], oldQueue: any[] }) {
    // Send required queue notifications.
    // New Client joined nurse queue
    if (nurseQueues.newQueue?.length > nurseQueues.oldQueue?.length) {
      this.chromeNotifications.sendNotification('Somebody has joined the Nurse queue.', `${nurseQueues?.newQueue?.length} ${nurseQueues?.newQueue?.length === 1 ? 'person is' : 'people are'} waiting in the Nurse queue`);
    }
    // New Client joined vet queue
    if (vetQueues.newQueue?.length > vetQueues.oldQueue?.length) {
      this.chromeNotifications.sendNotification('Somebody has joined the Vet queue.', `${vetQueues?.newQueue?.length} ${vetQueues?.newQueue?.length === 1 ? 'person is' : 'people are'} waiting in the Vet queue`);
    }
  }

  hasNewClientJoinedQueue(vetQueues: { newQueue: any[], oldQueue: any[] }, nurseQueues: { newQueue: any[], oldQueue: any[] }): boolean {
    // Empty queue
    if (vetQueues.newQueue?.length === 0 && nurseQueues.newQueue?.length === 0) {
      return false;
    }
    // new queue is longer than old queue
    return vetQueues.newQueue?.length > vetQueues.oldQueue?.length || nurseQueues.newQueue?.length > nurseQueues.oldQueue?.length;
  }

  getRingerStatus(): Observable<boolean> {
    // Returns true if the current user is not in a consult and the queue has changed and the ringer is enabled.
    this.isRingerEnabled = isBoolean(JSON.parse(this.localStorageService.getData('ringerStatus'))) ?
      JSON.parse(this.localStorageService.getData('ringerStatus')) : true;
    return of(this.isRingerEnabled && !this.ringerService.inConsult);
  }

  replaceArray(oldArray, newArray): any[] {
    oldArray.length = 0; // clear the array without losing reference
    newArray.forEach(x => oldArray.push(x));
    return oldArray;
  }

  getMenuItems() {
    const practicesFeature = this.featureManagementService.getFeatureConfig(FeatureEnum.practices);
    if ((!practicesFeature || !practicesFeature.enabled) || !this.loggedInUser.practice_id) {
      return [
        {
          label: 'Home',
          icon: 'home',
          route: '/home/',
          isActive: false
        },
        {
          label: 'History',
          icon: 'history',
          route: '/history/users',
          isActive: false
        },
        {
          label: 'Calendar',
          icon: 'calendar_today',
          route: '/calendar',
          isActive: false
        }
      ];
    } else {
      return [
        {
          label: 'Remote',
          icon: 'language',
          route: '/home/remote',
          isActive: false
        },
        {
          label: 'Practice',
          icon: 'store',
          route: '/home/practice',
          isActive: false
        },
        {
          label: 'History',
          icon: 'history',
          route: '/history/users',
          isActive: false
        },
        {
          label: 'Calendar',
          icon: 'calendar_today',
          route: '/calendar',
          isActive: false
        },
        {
          label: 'Rota',
          icon: 'perm_contact_calendar',
          route: '/rota',
          isActive: false
        }
      ];
    }
  }

}
