import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MaintenanceInterface } from '../../models/interfaces/maintenance.interface';
import { interval, Subscription } from 'rxjs';
import { LocalStorageService } from '../../services/local-storage.service';

@Component({
  selector: 'app-maintenance-dialog',
  templateUrl: './maintenance-dialog.component.html',
})
export class MaintenanceDialogComponent implements OnInit, OnDestroy {
  @Input() maintenance: MaintenanceInterface;
  clock: Date;
  private subscription: Subscription = new Subscription();

  constructor(
    private readonly localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.setClock();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setClock() {
    this.clock = new Date();
    this.subscription.add(
      interval(1000).subscribe(x => {
      this.clock = new Date();
    })
    );
  }

  onDismiss(status: string) {
    if (status === 'pending') {
      this.localStorageService.setData('maintenance-pending-dismissed', true);
    }
  }

}
