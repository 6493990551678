import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(
    private readonly apiService: ApiService
  ) { }

  getUserAddresses(userId: number) {
    return this.apiService.getData(`${environment.apiPrefix}/v1/addresses/users/${userId}`);
  }

  createNewUserAddress(address: any) {
    return this.apiService.postData(`${environment.apiPrefix}/v1/addresses`, address);
  }

  updateAddress(address: any): Observable<any> {
    return this.apiService.putData(`${environment.apiPrefix}/v1/addresses/${address.id}`, address);
  }

  deleteUserAddress(id: number, userID: any): Observable<any> {
    return this.apiService.deleteData(`${environment.apiPrefix}/v1/addresses/${id}?user=${userID}`);
  }
}
