import { Component, OnDestroy, OnInit } from '@angular/core';
import { ThemeType } from '../../models/types/theme.type';
import { LocalStorageService } from '../../services/local-storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-theme-selection',
  templateUrl: './theme-selection.component.html',
})
export class ThemeSelectionComponent implements OnInit, OnDestroy {

  currentTheme: ThemeType = 'os-theme';
  currentIcon = 'contrast';
  subscription: Subscription;

  constructor(
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.initTheme();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  initTheme() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = new Subscription();

    this.subscription.add(
      this.localStorageService.getPersistedDataChanges().subscribe(changes => {
        if (changes?.theme) {
          this.currentTheme = changes.theme;
          this.currentIcon = this.getThemeIcon(this.currentTheme);
        }
      })
    );
  }

  changeTheme(theme: ThemeType) {
    this.localStorageService.setData('theme', theme);
  }

  getThemeIcon(theme: ThemeType): string {
    switch (theme) {
      case 'light-theme': {
        return 'light_mode';
      }
      case 'dark-theme': {
        return 'dark_mode';
      }
      case 'os-theme': {
        return 'contrast';
      }
    }
  }

}
