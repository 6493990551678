import { Component, EventEmitter, Input, Output } from '@angular/core';

// Third party
import * as Highcharts from 'highcharts/highcharts';
import HC_timeline from 'highcharts/modules/timeline';
HC_timeline(Highcharts);

// Models
import { TimelineChartPointInterface } from './interfaces/timeline-chart-point.interface';

@Component({
  selector: 'app-timeline-chart',
  templateUrl: './timeline-chart.component.html',
})
export class TimelineChartComponent {
  @Input() chartHeight: number;
  @Input() set data(data: Array<TimelineChartPointInterface>) {
    this.points = data;
    this.updateOptionsFromDataPoints();
  }
  @Input() timelineError = 'No data for timeline';
  @Output() chartClicked: EventEmitter<TimelineChartPointInterface> = new EventEmitter<TimelineChartPointInterface>();

  points: Array<TimelineChartPointInterface>;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: any;

  constructor() { }

  updateOptionsFromDataPoints(): void {
    this.chartOptions = {
      colors: ['#1CB7A0', '#61E3E7', '#BE82EB', '#F08006', '#EF5560'],
      chart: {
        zoomType: 'x',
        type: 'timeline',
        reflow: true
      },
      xAxis: {
        type: 'datetime',
        visible: false
      },
      yAxis: {
        gridLineWidth: 1,
        title: null,
        labels: {
          enabled: false
        }
      },
      title: {
        text: null
      },
      tooltip: {
        style: {
          width: 200
        },
        dateFormat: ''
      },
      series: [{
        dataLabels: {
          allowOverlap: false,
          enabled: true,
          width: 200,
        },
        marker: {
          symbol: 'circle',
          radius: 10
        },
        events: {
          click: (data: any) => this.outputClickEvent(data)
        },
        data: this.points
      }]
    };
  }

  outputClickEvent(point: any): void {
    this.chartClicked.emit(point.point.options);
  }

}
