import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }
  /**
   * getData function - Calls http.get (with endpoint param) and gets appropriate headers from getHeaders call
   * @param: (String) endpoint
   */
  getData<T = any>(endpoint, params?: {[p: string]: string | string[]}): Observable<T> {
    let headers;
    if (params) {
      headers = {params} as any;
    }
    return this.http.get(endpoint, headers) as unknown as Observable<T>;
  }
  /**
   * postData function - Calls http.post (with endpoint and data params) and gets appropriate headers
   * from getHeaders calls (based on isSimple param)
   * @param: (String) endpoint
   * @param: (Object) data
   * @param: (String) isSimple
   */
  postData<T = any>(endpoint, data): Observable<any> {
    return this.http.post(endpoint, data);
  }
  /**
   * putData function - Calls http.put (with endpoint and data params) and gets appropriate headers
   * from getHeaders calls (based on isSimple param)
   * @param: (String) endpoint
   * @param: (Object) data
   * @param: (String) isSimple
   */
  putData(endpoint, data) {
    return this.http.put(endpoint, data);
  }
  /**
   * patchData function - Calls http.patch (with endpoint and data params) and gets appropriate headers
   * from getHeaders calls (based on isSimple param)
   * @param: (String) endpoint
   * @param: (Object) data
   * @param: (String) isSimple
   */
  patchData(endpoint, data) {
    return this.http.patch(endpoint, data);
  }
  /**
   * deleteData function - Calls http.delete (with endpoint params) and gets appropriate headers
   * from getHeaders calls (based on isSimple param)
   * @param: (String) endpoint
   * @param: (String) isSimple
   */
  deleteData(endpoint) {
    return this.http.delete(endpoint);
  }
}
