import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-version-update-dialog',
  templateUrl: './version-update-dialog.component.html'
})
export class VersionUpdateDialogComponent {

  constructor(
    @Inject(Window) private window: Window
  ) { }

  onRefresh() {
    window.location.reload();
  }

}
