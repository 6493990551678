import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { isString } from 'lodash';

@Directive({
  selector: '[appDataAttribute]'
})
export class DataAttributeDirective {

  @Input() set attr(value: string) {
    if (value && value !== this.currentAttribute) {
      this.setAttributeOnElement(value, this.currentAttribute, this.currentAttributeValue);
    }
  }
  @Input() set attrValue(value: string) {
    if (value && value !== this.currentAttributeValue) {
      this.setAttributeOnElement(this.currentAttribute, this.currentAttribute, value);
    }
  }
  private currentAttribute: string;
  private currentAttributeValue: string;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private readonly renderer: Renderer2
  ) { }

  setAttributeOnElement(newAttr: string, oldAttr?: string, value?: string): void {
    if (isString(oldAttr)) {
      this.renderer.removeAttribute(this.elementRef.nativeElement, oldAttr);
    }
    if (isString(newAttr)) {
      this.renderer.setAttribute(this.elementRef.nativeElement, newAttr, value || '');
    }
    this.currentAttribute = newAttr;
    this.currentAttributeValue = value;
  }

}
