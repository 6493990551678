<section>
  <h1>Client Registration</h1>
  <p>This form is to sign up a practice client within the PMS, rather than forcing them to use the app.</p>
  <form
    [formGroup]="clientRegistrationForm"
    (ngSubmit)="saveForm()">
    <h2>Client Details</h2>
    <p *ngIf="practiceLoadingProblem" class="error-warning">
      <mat-icon class="inline-icon joii-text-danger">warning</mat-icon>
      <span class="joii-text-danger ">Warning: Practices Not Loaded</span>
      <button
        mat-icon-button
        color="primary"
        type="button"
        matTooltip="Retry get practices"
        (click)="getAvailablePractices()">
        <mat-icon>refresh</mat-icon>
      </button>
    </p>
    <section formGroupName="user" class="formgrid grid margin-b-sm">
      <div class="input-holder">
        <label for="name">Client Name*</label>
        <input type="text" id="name" autocomplete="off" pInputText formControlName="name"/>
        <div *ngIf="clientRegistrationForm && clientRegistrationForm.get('user.name').invalid && clientRegistrationForm.get('user.name').dirty">
          <span class="p-error" *ngIf="clientRegistrationForm.get('user.name').hasError('required')">Client Name is required.</span>
        </div>
      </div>
      <div class="input-holder">
        <label for="email">Client Email*</label>
        <input type="text" id="email" autocomplete="off" pInputText formControlName="email"/>
        <div *ngIf="clientRegistrationForm && clientRegistrationForm.get('user.email').invalid && clientRegistrationForm.get('user.email').dirty">
          <span class="p-error" *ngIf="clientRegistrationForm.get('user.email').hasError('required')">Email is required.</span>
          <span class="p-error" *ngIf="clientRegistrationForm.get('user.email').hasError('email')">Invalid email address.</span>
        </div>
      </div>
      <div class="input-holder">
        <label>Client Practice*</label>
        <p-dropdown formControlName="practice" [options]="availablePractices" optionLabel="name" [autoDisplayFirst]="false" appendTo="body" ></p-dropdown>
        <div *ngIf="clientRegistrationForm && clientRegistrationForm.get('user.practice').invalid && clientRegistrationForm.get('user.practice').dirty">
          <span class="p-error" *ngIf="clientRegistrationForm.get('user.practice').hasError('required')">Practice is required.</span>
        </div>
      </div>
      <div class="input-holder">
        <label>Client Phone</label>
        <p-inputMask formControlName="phone" mask="9999 999 9999" type="tel" autocomplete="off" ></p-inputMask>
      </div>
      <div class="input-holder">
        <label for="postcode">Client Post Code</label>
        <input type="text" id="postcode" autocomplete="off" pInputText formControlName="post_code"/>
        <div *ngIf="clientRegistrationForm && clientRegistrationForm.get('user.post_code').invalid && clientRegistrationForm.get('user.post_code').dirty">
          <span class="p-error" *ngIf="clientRegistrationForm.get('user.post_code').hasError('pattern')">Invalid UK postcode format.</span>
        </div>
      </div>
    </section>
    <h2>Pet Details</h2>
    <article *ngFor="let pet of pets.controls; let i = index;" [formGroup]="$any(pet)" class="pet-form">
      <div class="formgrid grid">
        <div class="input-holder">
          <label for="petname">Pet Name*</label>
          <input type="text" id="petname" autocomplete="off" pInputText formControlName="name"/>
          <div *ngIf="$any(pet) && $any(pet).get('name').invalid && $any(pet).get('name').dirty">
            <span class="p-error" *ngIf="$any(pet).get('name').hasError('required')">Pet Name is required.</span>
          </div>
        </div>
        <div class="input-holder">
          <label for="petspecies">Pet Species*</label>
          <p-dropdown formControlName="species" id="petspecies" [options]="availableSpecies" (onChange)="handleSpeciesChange($event, pet)" [autoDisplayFirst]="false" ></p-dropdown>
          <div *ngIf="$any(pet) && $any(pet).get('species').invalid && $any(pet).get('species').touched">
            <span class="p-error" *ngIf="$any(pet).get('species').hasError('required')">Pet Species is required.</span>
          </div>
        </div>
        <div class="input-holder">
          <label for="petgender">Pet Sex*</label>
          <p-dropdown formControlName="gender" id="petgender" [options]="availableSexes" [autoDisplayFirst]="false" ></p-dropdown>
          <div *ngIf="$any(pet) && $any(pet).get('gender').invalid && $any(pet).get('gender').touched">
            <span class="p-error" *ngIf="$any(pet).get('gender').hasError('required')">Pet Sex is required.</span>
          </div>
        </div>
        <div class="col-3 input-holder">
          <label>Pet Breeds*</label>
          <p-multiSelect
            [disabled]="!pet.controls.species.value"
            [options]="pet.controls.available_breeds.value"
            [filter]="true"
            [optionLabel]="'name'"
            [optionValue]="'name'"
            [showClear]="true"
            [resetFilterOnHide]="true"
            appendTo="body"
            display="chip"
            formControlName="breeds"></p-multiSelect>
          <div *ngIf="$any(pet) && $any(pet).get('breeds').invalid && $any(pet).get('breeds').touched">
            <span class="p-error" *ngIf="$any(pet).get('breeds').hasError('noBreeds')">At least 1 breed must be added.</span>
          </div>
        </div>
        <div class="input-holder">
          <label>Pet Age Years*</label>
          <p-inputNumber
            formControlName="age_years"
            [min]="0"
            mode="decimal"
            maxFractionDigits="0"
            [showButtons]="true"
          ></p-inputNumber>
          <div *ngIf="$any(pet) && $any(pet).get('age_years').invalid && $any(pet).get('age_years').dirty">
            <span class="p-error" *ngIf="$any(pet).get('age_years').hasError('required')">Years is required.</span>
          </div>
        </div>
        <div class="input-holder">
          <label>Pet Age Months*</label>
          <p-inputNumber
            formControlName="age_months"
            [min]="0"
            mode="decimal"
            maxFractionDigits="0"
            [max]="12"
            [showButtons]="true"
          ></p-inputNumber>
          <div *ngIf="$any(pet) && $any(pet).get('age_months').invalid && $any(pet).get('age_months').dirty">
            <span class="p-error" *ngIf="$any(pet).get('age_months').hasError('required')">Months is required.</span>
          </div>
        </div>
        <div class="input-holder">
          <label>Microchip Number</label>
          <input type="text" autocomplete="off" pInputText formControlName="microchip_number"/>
        </div>

        <div class="input-holder ">
          <label>Weight (kg)</label>
          <p-inputNumber formControlName="weight" class="p-inputtext-sm"></p-inputNumber>
        </div>
        <div class="input-holder">
          <label class="checkbox-label">Neutered</label>
          <p-dropdown formControlName="neutered" optionLabel="label" optionValue="value" [options]="[{label: 'Yes', value: true}, {label: 'No', value: false}]"></p-dropdown>
        </div>
        <div class="input-holder note-input">
          <label>Note</label>
          <textarea #Note formControlName="note" pInputTextarea rows="2" [autoResize]="true" [maxlength]="280"></textarea>
          <label class="char-count">{{Note.value.length || 0}}/280</label>
        </div>
        <label class="p-error" *ngIf="failedJob($any(pet).getRawValue())">There was an error when saving the note for this pet. Please remove any special characters and emojis and try again!</label>
      </div>
      <button
        *ngIf="pets.controls.length > 1"
        mat-icon-button
        type="button"
        color="primary"
        class="remove-pet-btn remove-btn"
        (click)="removePet(i)">
        <mat-icon>delete</mat-icon>
      </button>
    </article>
    <div>
      <p-button
        type="button"
        class="add-pet-btn"
        label="Add Pet"
        (onClick)="addPet()"></p-button>
    </div>
    <div>
      <p-button
        *ngIf="failedJobs?.length === 0"
        type="submit"
        label="Create Client"
        [loading]="savingDetails"
        [disabled]="savingDetails || clientRegistrationForm.invalid || practiceLoadingProblem"></p-button>
      <p-button
        *ngIf="failedJobs?.length > 0"
        styleClass="p-button-danger"
        (onClick)="retryNotesSave()"
        type="button"
        [icon]="'pi pi-refresh'"
        label="Retry Create Client"
        [loading]="savingDetails"
        [disabled]="savingDetails || clientRegistrationForm.invalid || practiceLoadingProblem"></p-button>
    </div>
  </form>
</section>
