import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MessagingService } from '../../services/messaging.service';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MessageInterface } from '../../models/interfaces/message.interface';
import { MessageStatusTypeEnum } from '../../models/enums/message-status-type.enum';
import { listAnimation } from '../../animations/list.animation';
import { messageItemsAnimation } from '../../animations/message.animation';
import { MessageTypeEnum } from '../../models/enums/message-type.enum';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  animations: [listAnimation, messageItemsAnimation]
})
export class MessagesComponent implements OnInit {
  private privateIsGlobal = false;
  get isGlobal(): boolean {
    return this.privateIsGlobal;
  }
  @Input()
  set isGlobal(value: boolean) {
    this.privateIsGlobal = coerceBooleanProperty(value);
  }
  // Banner messages that cannot be cleared by the user.
  noticeMessages: Observable<Array<MessageInterface>>;
  messages: Observable<Array<MessageInterface>>;

  // tslint:disable-next-line:no-input-rename
  @Input('messages') inlineMessages: Array<MessageInterface>;
  messageStatusTypeEnum = MessageStatusTypeEnum;
  messageTypeEnum = MessageTypeEnum;

  constructor(
    private messagingService: MessagingService
  ) { }

  ngOnInit(): void {
    if (this.isGlobal) {
      this.noticeMessages = this.messagingService.getNoticeMessages();
      this.messages = this.messagingService.getMessages();
    }
  }

}
