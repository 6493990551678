<header class="main-header">
  <span class="logo-holder">
    <img src="../../../../../assets/logos/joii-logo.png" alt="Joii" title="Joii" />
  </span>
  <span class="version-details">v{{versionNumber}}</span>
  <app-theme-selection></app-theme-selection>
  <nav class="main-nav">
    <ul class="list-unstyled">
      <li *ngFor="let item of menuItems" class="menu-item" [class.is-active]="item.isActive">
        <a (click)="goToRoute(item.route)">
          <mat-icon>{{item.icon}}</mat-icon>
          <span>{{item.label}}</span>
        </a>
      </li>
      <li class="menu-item">
        <a (click)="logout()">
          <mat-icon>login</mat-icon>
          <span>Logout</span>
        </a>
      </li>
    </ul>
  </nav>
</header>
<ng-template #practiceChangeWarning>
  <section class="text-center">
    <h1 class="joii-text-danger">
      <mat-icon>warning</mat-icon>
      <span>Warning: You are about to switch to&nbsp;</span>
      <span *ngIf="movingToRemote">Remote</span>
      <span *ngIf="movingToPractice">Practice</span>
      <span>&nbsp;Mode</span>
    </h1>
    <p><strong>Please Note:</strong> You will not receive updates on the
      <span *ngIf="movingToPractice">remote</span>
      <span *ngIf="movingToRemote">practice</span> queue.</p>
    <p>Are you sure?</p>
    <mat-dialog-actions align="center">
      <button mat-flat-button class="success-btn" (click)="goToHome()">Yes</button>
      <button mat-flat-button color="warn" class="danger-btn" (click)="closeWarningModal()">No</button>
    </mat-dialog-actions>
  </section>
</ng-template>
