// Angular
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, TitleCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Components
import { PetProfileImageComponent } from './components/pet-profile-image/pet-profile-image.component';
import { CharityTagComponent } from './components/charity-tag/charity-tag.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { MaintenanceDialogComponent } from './components/maintenance-dialog/maintenance-dialog.component';
import { MaintenanceHolderComponent } from './components/maintenance-holder/maintenance-holder.component';
import { MessagesComponent } from './components/messages/messages.component';
import { SimpleGalleryComponent } from './components/simple-gallery/simple-gallery.component';
import { StatusComponent } from './components/status/status.component';
import { TableSearchBarComponent } from './components/table-search-bar/table-search-bar.component';
import { ThemeSelectionComponent } from './components/theme-selection/theme-selection.component';
import { TimelineChartComponent } from './components/timeline-chart/timeline-chart.component';
import { UserOrderHistoryComponent } from './components/user-order-history/user-order-history.component';
import { VersionUpdateDialogComponent } from './components/version-update-dialog/version-update-dialog.component';
import { VideoGalleryComponent } from './components/video-gallery/video-gallery.component';
import { ClientRegistrationComponent } from './components/client-registration/client-registration.component';
import { LabelPrintingDialogComponent } from './components/label-printing-dialog/label-printing-dialog.component';
// Directives
import { DataAttributeDirective } from './directives/data-attribute.directive';
// Pipes
import { InsuranceFormatPipe } from './pipes/insurance-format.pipe';
import { AppointmentStatusPipe } from './pipes/appointment-status.pipe';
import { BodyMapPipe } from './pipes/body-map.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { JoiiCurrencyPipe } from './pipes/joii-currency.pipe';
import { MaintenanceCountdownPipe } from './pipes/maintenance-countdown.pipe';
import { OnlineStatusFormatPipe } from './pipes/online-status-format.pipe';
import { PricePipe } from './pipes/price.pipe';
import { VenomTermSearchResultPipe } from './pipes/venom-term-search-result.pipe';
import { TimezoneFormatPipe } from './pipes/timezone-format.pipe';
import { PetVariablesPipe } from './pipes/pet-variables.pipe';
import { AgePipe } from './pipes/age.pipe';
import { UrlDataSecurityBypassPipe } from './pipes/url-data-security-bypass.pipe';
import { PomProductCheckPipe } from './pipes/pom-product-check.pipe';
// Materials
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { MatRadioModule } from '@angular/material/radio';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';
// NG Prime
import { TagModule } from 'primeng/tag';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { MessageModule } from 'primeng/message';
import { RippleModule } from 'primeng/ripple';
import { DividerModule } from 'primeng/divider';
import { MessagesModule } from 'primeng/messages';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TooltipModule } from 'primeng/tooltip';
import { StepsModule } from 'primeng/steps';
import { ImageModule } from 'primeng/image';
import { MatExpansionModule } from '@angular/material/expansion';
import { SidebarModule } from 'primeng/sidebar';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule} from 'primeng/inputtextarea';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ListboxModule } from 'primeng/listbox';
import { SplitButtonModule } from 'primeng/splitbutton';
import { FieldsetModule } from 'primeng/fieldset';
import { AccordionModule } from 'primeng/accordion';
import { MultiSelectModule } from 'primeng/multiselect';
import { AvatarModule } from 'primeng/avatar';
import { ChipModule } from 'primeng/chip';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
// Third Party
import { HighchartsChartModule } from 'highcharts-angular';
import { DataViewModule } from 'primeng/dataview';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { PaymentProcessingStateComponent } from './components/payment-processing-state/payment-processing-state.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { TabViewModule } from 'primeng/tabview';
import { AddressSelectionComponent } from './components/address-selection/address-selection.component';
import { ConsultationStatusPipe } from './pipes/consultation-status.pipe';
import { JoiiJsonParsePipe } from './pipes/joii-json-parse.pipe';
import { ClientDetailsComponent } from './components/client-details/client-details.component';
import { ClientBasketComponent } from './components/client-basket/client-basket.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputMaskModule } from 'primeng/inputmask';
import { ClientBasketItemComponent } from './components/client-basket-item/client-basket-item.component';
import { PendingBasketTotalPipe } from './pipes/pending-basket-total.pipe';
import { ProductUnitPricePipe } from './pipes/product-unit-price.pipe';
import { PaymentLabelPipe } from './pipes/payment-label.pipe';



@NgModule({
  declarations: [
    // Components
    CharityTagComponent,
    MainHeaderComponent,
    MaintenanceDialogComponent,
    MaintenanceHolderComponent,
    MessagesComponent,
    PetProfileImageComponent,
    SimpleGalleryComponent,
    StatusComponent,
    TableSearchBarComponent,
    ThemeSelectionComponent,
    TimelineChartComponent,
    UserOrderHistoryComponent,
    VersionUpdateDialogComponent,
    VideoGalleryComponent,
    ClientRegistrationComponent,
    // Directives
    DataAttributeDirective,
    // Pipes
    AgePipe,
    TimezoneFormatPipe,
    PetVariablesPipe,
    InsuranceFormatPipe,
    AppointmentStatusPipe,
    BodyMapPipe,
    FilterPipe,
    JoiiCurrencyPipe,
    MaintenanceCountdownPipe,
    OnlineStatusFormatPipe,
    PricePipe,
    VenomTermSearchResultPipe,
    PaymentProcessingStateComponent,
    VenomTermSearchResultPipe,
    UrlDataSecurityBypassPipe,
    AddressSelectionComponent,
    ConsultationStatusPipe,
    JoiiJsonParsePipe,
    PomProductCheckPipe,
    ClientDetailsComponent,
    ClientBasketComponent,
    ClientBasketItemComponent,
    LabelPrintingDialogComponent,
    PendingBasketTotalPipe,
    ProductUnitPricePipe,
    PaymentLabelPipe
  ],
  imports: [
    // Modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    // Materials
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatTableModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatDialogModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatMenuModule,
    MatRadioModule,
    MatSliderModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    // NG Prime
    TagModule,
    OverlayPanelModule,
    AutoCompleteModule,
    ScrollPanelModule,
    MessagesModule,
    MessageModule,
    StepsModule,
    DividerModule,
    ButtonModule,
    RippleModule,
    ImageModule,
    TooltipModule,
    DataViewModule,
    TableModule,
    DropdownModule,
    InputNumberModule,
    TabViewModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    CalendarModule,
    CheckboxModule,
    ListboxModule,
    AccordionModule,
    SplitButtonModule,
    FieldsetModule,
    MultiSelectModule,
    AvatarModule,
    ChipModule,
    ContextMenuModule,
    ProgressSpinnerModule,
    ContextMenuModule,
    DialogModule,
    ConfirmPopupModule,
    RadioButtonModule,
    InputMaskModule
  ],
    exports: [
        // Modules
        FormsModule,
        ReactiveFormsModule,
        HighchartsChartModule,
        // Components
        CharityTagComponent,
        MainHeaderComponent,
        MaintenanceDialogComponent,
        MaintenanceHolderComponent,
        MessagesComponent,
        PetProfileImageComponent,
        SimpleGalleryComponent,
        StatusComponent,
        TableSearchBarComponent,
        ThemeSelectionComponent,
        TimelineChartComponent,
        UserOrderHistoryComponent,
        VersionUpdateDialogComponent,
        VideoGalleryComponent,
        PaymentProcessingStateComponent,
        LabelPrintingDialogComponent,
        ClientRegistrationComponent,
        ClientBasketComponent,
        // Directives
        DataAttributeDirective,
        // Materials
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatTabsModule,
        MatTableModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatDialogModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSortModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatMenuModule,
        MatRadioModule,
        MatSliderModule,
        ScrollingModule,
        ExperimentalScrollingModule,
        // NG Prime
        TagModule,
        OverlayPanelModule,
        AutoCompleteModule,
        ScrollPanelModule,
        MessagesModule,
        MessageModule,
        StepsModule,
        DividerModule,
        ButtonModule,
        RippleModule,
        ImageModule,
        TooltipModule,
        SidebarModule,
        DataViewModule,
        TableModule,
        DropdownModule,
        InputNumberModule,
        TabViewModule,
        DropdownModule,
        InputTextModule,
        InputTextareaModule,
        CalendarModule,
        CheckboxModule,
        ListboxModule,
        AccordionModule,
        SplitButtonModule,
        FieldsetModule,
        MultiSelectModule,
        AvatarModule,
        ChipModule,
        ContextMenuModule,
        ProgressSpinnerModule,
        ConfirmPopupModule,
        DialogModule,
        RadioButtonModule,
        InputMaskModule,
        // Pipes
        TimezoneFormatPipe,
        PetVariablesPipe,
        InsuranceFormatPipe,
        AppointmentStatusPipe,
        BodyMapPipe,
        FilterPipe,
        JoiiCurrencyPipe,
        MaintenanceCountdownPipe,
        OnlineStatusFormatPipe,
        PricePipe,
        VenomTermSearchResultPipe,
        AgePipe,
        UrlDataSecurityBypassPipe,
        AddressSelectionComponent,
        ConsultationStatusPipe,
        JoiiJsonParsePipe,
        PomProductCheckPipe,
        ClientDetailsComponent,
        PendingBasketTotalPipe,
        ProductUnitPricePipe,
        PaymentLabelPipe
    ],
  providers: [
    MatDialog,
    AppointmentStatusPipe,
    BodyMapPipe,
    DecimalPipe,
    AgePipe,
    TitleCasePipe,
    { provide: Window, useValue: window },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ]
})
export class GeneralSharedModule { }
