// Angular
import { Injectable } from '@angular/core';
// Models / Interfaces
import { FeatureInterface } from '../models/interfaces/feature.interface';
// Data
import { environment } from '../../../../environments/environment';
// Third party
import { find, some } from 'lodash';
import { FeatureEnum } from '../models/enums/feature.enum';

@Injectable({
  providedIn: 'root'
})
export class FeatureManagementService {

  private features: Array<FeatureInterface>;

  /**
   * constructor function: inits feature list from environment variables
   */
  constructor() {
  }

  /**
   * getFeatureConfig returns matched parent feature configuration
   */
  getFeatureConfig(feature: FeatureEnum): FeatureInterface {
    return find(this.features, x => x.name === feature);
  }

  /**
   * setFeatures setter function sets base features on private service variable
   */
  setFeatures() {
    this.features = environment.features as Array<FeatureInterface>;
  }
}
