import { Pipe, PipeTransform } from '@angular/core';

// @ToDo: Rename to clean text pipe or similar
@Pipe({
  name: 'bodyMap'
})
export class BodyMapPipe implements PipeTransform {

  transform(value: string): any {
    if (!value || typeof value !== 'string' || value.length === 0) {
      return value;
    }
    return value.replace(/[^a-z0-9]+|\s+/gmi, ' ').toUpperCase().trim();
  }

}
