import { FeatureEnum } from '../app/shared/general-shared/models/enums/feature.enum';
import { SubFeatureEnum } from '../app/shared/general-shared/models/enums/sub-feature.enum';

export const environment = {
  production: false,
  apiPrefix: 'https://api-dev.vet-ai.com',
  apiConfigPrefix: 'https://config-dev.vet-ai.com',
  authPrefix: 'https://authentication-dev.vet-ai.com',
  messagesPrefix: 'wss://messages-dev.vet-ai.com',
  mediaPrefix: 'https://media-dev.vet-ai.com',
  hillsPrefix: 'https://staging-1.hills4me.co.uk',
  labelPrinterPrefix: 'https://127.0.0.1:41951/DYMO/DLS/Printing',
  client_id: '428534bd7d469b30',
  client_secret: '175bcc796d55813245732d62e009670eb6907f79123f55fa9413cf8c4421b184',
  api_key: '46404582',
  addressApiPrefix: 'https://api.getaddress.io/find/',
  addressApiToken: 'znbu-0y19ECRj4C4wNdjYw17347',
  ringerEnabled: false,
  NG_BUILD_DIFFERENTIAL_FULL: 1,
  pomProductLegalCategories: ['pom-v', 'pom-schedule', 'pom-human', 'pharmacy', 'clinical-trial'],
  card_readers: [
    {
      reader_id: 'tmr_EmcpQbjxLKy50o',
      name: 'James Test'
    }
  ],
  tracking: {
    enabled: true,
    console_logging_enabled: true,
    googleAnalytics: {
      trackingKey: 'G-LSH54YV1S5'
    }
  },
  features: [
    {
      name: FeatureEnum.standaloneAppointment,
      enabled: true,
      subFeatures: {
        [SubFeatureEnum.cancelAppointment] : {
          enabled: true
        }
      }
    },
    {
      name: FeatureEnum.consultationAppointmentManagement,
      enabled: true,
      subFeatures: {
        [SubFeatureEnum.cancelAppointment] : {
          enabled: true
        },
        [SubFeatureEnum.editAppointment] : {
          enabled: true
        },
        [SubFeatureEnum.practiceFiltering] : {
          enabled: true
        },
      }
    },
    {
      name: FeatureEnum.practiceAppointment,
      enabled: true
    },
    {
      name: FeatureEnum.practices,
      enabled: true,
      subFeatures: {
        [SubFeatureEnum.practiceConsultations] : {
          enabled: true
        }
      }
    },
    {
      name: FeatureEnum.hillsReco,
      enabled: true
    },
    {
      name: FeatureEnum.sessions,
      enabled: true
    },
    {
      name: FeatureEnum.labelPrinting,
      enabled: true
    }
  ]
};
