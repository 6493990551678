import { Pipe, PipeTransform } from '@angular/core';
import { isFinite } from 'lodash';

@Pipe({
  name: 'price',
  pure: true
})
export class PricePipe implements PipeTransform {
  // Convert pence to pounds
  transform(value: number): number {
    if (!isFinite(value)) {
      return value;
    }
    return value / 100;
  }

}
