<div class="status-container">
  <div
    class="user-container"
    matTooltip="Status: {{loggedInUserStatus?.status | onlineStatusFormat}}"
    matTooltipPosition="below"><span class="online-status {{loggedInUserStatus?.status}}"></span>
    <div class="name text-truncate">{{loggedInUserStatus?.name}}</div>
  </div>
  <button
    mat-icon-button
    matTooltip="Set status to {{isOnline ? 'Away' : 'Online'}}"
    matTooltipPosition="below"
    [ngClass]="{'action-disabled': !isOnline}"
    (click)="updateStaffStatus(loggedInUserStatus, !isOnline)"
    data-joii-home-status-away-toggle-btn>
    <mat-icon
      *ngIf="isOnline"
      >public</mat-icon>
    <mat-icon *ngIf="!isOnline">public_off</mat-icon>
  </button>
  <button
    mat-icon-button
    matTooltip="{{isRingEnabled? 'Disable' : 'Enable'}} Ringer"
    matTooltipPosition="below"
    [ngClass]="{'action-disabled': !isRingEnabled}"
    (click)="onRingStatusChange()"
    data-joii-home-status-ringing-toggle-btn>
    <mat-icon *ngIf="isRingEnabled">music_note</mat-icon>
    <mat-icon *ngIf="!isRingEnabled">music_off</mat-icon>
  </button>
</div>

