export enum AppointmentStatusEnum {
  confirmed = 'confirmed',
  denied = 'denied',
  pending = 'pending',
  missed = 'missed',
  unconfirmed = 'unconfirmed',
  initiated = 'initiated',
  completed = 'completed',
  joinedTriage = 'joined-triage',
  rescheduled = 'rescheduled',
  cancelled = 'cancelled'
}

