import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  constructor(private apiService: ApiService) { }

  getPetNotes(petID): Observable<any> {
    const url = `${environment.apiPrefix}/v1/notes/pet/${petID}`;
    return this.apiService.getData(url);
  }

  getUserNotes(clientID): Observable<any> {
    const url = `${environment.apiPrefix}/v1/notes/user/${clientID}`;
    return this.apiService.getData(url);
  }

  deleteNote(noteID): Observable<any> {
    const url = `${environment.apiPrefix}/v1/notes/${noteID}`;
    return this.apiService.deleteData(url);
  }

  createNote(noteDetails): Observable<any> {
    const url = `${environment.apiPrefix}/v1/notes`;
    return this.apiService.postData(url, noteDetails);
  }
}
