import { Injectable, OnDestroy } from '@angular/core';
import { MessageInterface } from '../models/interfaces/message.interface';
import { MessageTypeEnum } from '../models/enums/message-type.enum';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { AlertService } from './alert.service';
import { MessageStatusTypeEnum } from '../models/enums/message-status-type.enum';
import { delay, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessagingService implements OnDestroy {

  private messagesSubject: BehaviorSubject<Array<MessageInterface>> = new BehaviorSubject<Array<MessageInterface>>([]);
  private noticeMessagesSubject: BehaviorSubject<Array<MessageInterface>> = new BehaviorSubject<Array<MessageInterface>>([]);
  private messages: Array<MessageInterface> = [];
  private noticeMessages: Array<MessageInterface> = [];
  private subscriptions: Subscription = new Subscription();

  constructor(
    private alertService: AlertService
  ) { }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  addMessage(message: MessageInterface) {
    this.setStatusIcon(message);
    this.messages.push(message);
    this.messagesSubject.next(this.messages);

    switch (message.messageType) {
      case MessageTypeEnum.toast :
      this.alertService.showAlert(message.message, message.statusType === MessageStatusTypeEnum.announcement ? 'info' : MessageStatusTypeEnum[message.statusType]);
      break;
    }

    if (message?.duration > 0) {
      this.clearAfterDuration(message);
    }
  }

  addNoticeMessage(message: MessageInterface) {
    message.messageType = MessageTypeEnum.banner;
    this.setStatusIcon(message);
    this.noticeMessages.push(message);
    this.noticeMessagesSubject.next(this.noticeMessages);
  }

  getMessages(): Observable<Array<MessageInterface>> {
    return this.messagesSubject.asObservable();
  }

  getNoticeMessages(): Observable<Array<MessageInterface>> {
    return this.noticeMessagesSubject.asObservable();
  }

  clearAfterDuration(message) {
    this.subscriptions.add(
      of(message).pipe(
        delay(message.duration),
        take(1)
      ).subscribe(() => {
        console.log('Clearing', message);
        this.messages = this.messages.filter(x => x !== message);
        this.messagesSubject.next(this.messages);
      })
    );
  }

  clearMessage(message, isNotice: boolean = false) {
    if (isNotice) {
      this.noticeMessages = this.noticeMessages.filter(x => x !== message);
      this.noticeMessagesSubject.next(this.noticeMessages);
      return false;
    }
    this.messages = this.messages.filter(x => x !== message);
    this.messagesSubject.next(this.messages);
  }

  setStatusIcon(message: MessageInterface) {
    switch (message.statusType) {
      case MessageStatusTypeEnum.info :
        message.icon = 'info_outline';
        break;
      case MessageStatusTypeEnum.announcement :
        message.icon = 'campaign';
        break;
      case MessageStatusTypeEnum.success :
        message.icon = 'done';
        break;
      case MessageStatusTypeEnum.warn :
        message.icon = 'warning';
        break;
      case MessageStatusTypeEnum.error :
        message.icon = 'remove_circle_outline';
        break;
    }
  }
}
