import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'venomTermSearchResult'
})
export class VenomTermSearchResultPipe implements PipeTransform {

  transform(venomName: string, searchTerm: string): string {
    if (searchTerm == null || searchTerm === '') {
      return venomName;
    }
    const startIndex = venomName.toLowerCase().indexOf(searchTerm.toLowerCase());
    if (startIndex < 0) {
      return venomName;
    }
    const endIndex = startIndex + searchTerm.length;
    return venomName.slice(0, startIndex) +
      `<mark class="orange">` +
      venomName.slice(startIndex, endIndex) +
      `</mark>` + venomName.slice(endIndex);
  }

}
