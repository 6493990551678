import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { VersionService } from './shared/general-shared/services/version.service';
import { NavigationEnd, Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { WebsocketsService } from './shared/general-shared/services/websockets.service';
import { MaintenanceInterface } from './shared/general-shared/models/interfaces/maintenance.interface';
import { addMinutes, format } from 'date-fns';
import { ConsultationsService } from './shared/consultation-shared/services/consultations.service';
import { MessagingService } from './shared/general-shared/services/messaging.service';
import { LocalStorageService } from './shared/general-shared/services/local-storage.service';
import { ThemeService } from './shared/general-shared/services/theme.service';
import { GoogleAnalyticsService } from './shared/general-shared/services/google-analytics.service';
import { FeatureManagementService } from './shared/general-shared/services/feature-management.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('VersionUpdate', {static: true}) versionUpdateDialog: TemplateRef<any>;
  title = 'joii-pms';
  isLogin = true;
  versionNumber: string;
  requiresUpdate = false;
  isActiveConsultation: boolean;
  maintenance: MaintenanceInterface;
  maintenanceDialogActive: boolean;
  subscription: Subscription = new Subscription();

  constructor(
    private readonly versionService: VersionService,
    private readonly websocketsService: WebsocketsService,
    private readonly themeService: ThemeService,
    private consultationsService: ConsultationsService,
    private readonly messagingService: MessagingService,
    private readonly localStorageService: LocalStorageService,
    private router: Router,
    private readonly dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private googleAnalytics: GoogleAnalyticsService,
    private featureManagement: FeatureManagementService
  ) {
  }

  ngOnInit() {
    this.getVersionChanges();
    this.getMaintenanceChanges();
    this.getThemeChanges();
    this.featureManagement.setFeatures();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getVersionChanges() {
    this.subscription.add(
      this.router.events.subscribe((result) => {
        this.versionNumber = this.versionService.getVersionNumber();
        if (result instanceof NavigationEnd) {
          this.isLogin = this.router.url.includes('auth');
          if (!this.isLogin) {
            this.checkVersion();
            this.websocketsService.sendMaintenanceMessage(this.maintenance);
          }
        }
      })
    );
  }

  getMaintenanceChanges() {
    this.subscription.add(
      this.websocketsService.getMaintenanceMessages().pipe(
        mergeMap((response) => {
          if (!response) {
            return of(null);
          }
          const currentUser = this.localStorageService.getData('currentUser');
          return this.consultationsService.checkIfInConsultation(currentUser).pipe(
            catchError(() => {
              return of({active_consultation: null});
            }),
            map(result => {
              this.isActiveConsultation = result.active_consultation?.id >= 0;
              return response;
            })
          );
        })
      ).subscribe((response) => {
        if (!response) {
          return false;
        }
        if (response.payload.start && response.payload.duration) {
          this.maintenance = {
            status: response.payload.status,
            message: response.payload.message,
            start: format(new Date(response.payload.start), 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx'),
            duration: response.payload.duration,
            end: format(addMinutes(new Date(response.payload.start), response.payload.duration), 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx')
          };
        } else {
          this.maintenance = {
            status: response?.payload?.status || 'none'
          };
        }
      })
    );
  }

  getThemeChanges() {
    this.themeService.initThemeUpdates();
  }

  checkVersion(): void {
    this.subscription.add(
      this.versionService.isVersionValid().pipe(
        take(1)
      ).subscribe((isValidVersion) => {
        if (!isValidVersion) {
          this.requiresUpdate = true;
          this.dialog.open(
            this.versionUpdateDialog,
          {
            disableClose: true,
            minWidth: 'calc(464px + 1rem)',
            minHeight: 'calc(286px + 1rem)'
          }
          );
        }
      })
    );
  }

  onMaintenanceDialogChange(active: boolean) {
    this.maintenanceDialogActive = active;
    this.cdr.detectChanges();
  }
}
