import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor() {}

  getPersistedDataChanges(): Observable<any> {
    return this.dataSubject.asObservable();
  }

  setData(key: string, value: any) {
    if (this.storageAvailable()) {
      const jsonData = JSON.stringify(value);
      localStorage.setItem(key, jsonData);
    }
    const existingData = this.dataSubject.value;
    // Broadcast changes
    this.dataSubject.next({...existingData, [key]: value});
    return value;
  }

  getData(key: string): any {
    if (!this.storageAvailable()) {
      return null;
    }
    const existingData = this.dataSubject.value;
    let data;
    try {
      data = JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.error(e);
      data = null;
    }
    if (!existingData.hasOwnProperty(key)) {
      // If local storage item doesn't exist in changes object then broadcast the newly pulled value.
      this.dataSubject.next({...existingData, [key]: data});
    }
    return data;
  }

  removeData(key: string) {
    const existingData = this.dataSubject.value;
    delete existingData[key];
    if (this.storageAvailable()) {
      localStorage.removeItem(key);
    }
    // Broadcast changes
    this.dataSubject.next(existingData);
  }

  getMultipleData(keys: Array<string>) {
    let data = {};
    keys.forEach(x => data = {...data, [x] : JSON.parse(localStorage.getItem(x))});
    // TODO: broadcast changes
    return data;
  }

  clearStorageFromKeys(keys: Array<string>) {
    const existingData = this.dataSubject.value;
    keys.forEach(key => {
      delete existingData[key];
      localStorage.removeItem(key);
    });

    // Broadcast changes
    this.dataSubject.next(existingData);
  }

  clearAll() {
    localStorage.clear();
    this.dataSubject.next({});
  }

  storageAvailable(): boolean {
    try {
      const x = '__storage_test__';
      localStorage.setItem(x, x);
      localStorage.removeItem(x);
      return true;
    }
    catch (e) {
      return false;
    }
  }

}
