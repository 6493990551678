import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './shared/general-shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'history',
    loadChildren: () => import('./history/history.module').then(m => m.HistoryModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'calendar',
    loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'consultation/:id',
    loadChildren: () => import('./consultation/consultation.module').then(m => m.ConsultationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'practice/:practiceID/consultation/:consultationID',
    loadChildren: () => import('./practice-consultation/practice-consultation.module').then(m => m.PracticeConsultationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'rota',
    loadChildren: () => import('./rota/rota.module').then(m => m.RotaModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
