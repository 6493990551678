import { Injectable } from '@angular/core';
import { ApiService } from '../../general-shared/services/api.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ConsultationInterface } from '../models/interfaces/consultation.interface';
import { WorkflowStateChangeEnum } from '../models/enums/workflow-state-change.enum';
import { ConsultationStatusType } from '../models/types/consultation-status.type';

@Injectable({
  providedIn: 'root'
})
export class ConsultationsService {

  constructor(
    private readonly apiService: ApiService
  ) { }

  public checkIfInConsultation(currentUser: any): Observable<any> {
    return this.apiService.getData(`${environment.apiPrefix}/v1/consultations/staff/${currentUser?.id}/prefetch`);
  }

  public getConsultationById(id: number): Observable<any> {
    return this.apiService.getData(`${environment.apiPrefix}/v1/consultations/${id}`);
  }

  updateConsultation(consultation: ConsultationInterface, previousState?: ConsultationStatusType) {
    let endpoint = '';
    let dataPackage = {};
    dataPackage = consultation;
    switch (consultation.consultation_status.status) {
      case 'in-review':
        endpoint = 'review-case';
        break;
      case 'in-progress':
        endpoint = previousState === 'pending' ? 'pickup' : 'begin';
        break;
      case 'post-review':
        endpoint = 'review';
        break;
      case 'terminated':
        endpoint = 'terminate';
        dataPackage = null;
        dataPackage = {
          message_for_user: consultation.consultation_status.message_for_user ? consultation.consultation_status.message_for_user : '',
          status_info: consultation.status_info
        };
        break;
      case 'completed':
        endpoint = 'complete';
        break;
      case 'no-show':
        endpoint = 'no-show';
        break;
      case 'pending':
        endpoint = 'handover';
        break;
    }
    return this.apiService.patchData(`${environment.apiPrefix}/v1/consultations/${consultation.id}/${endpoint}`, dataPackage);
  }

  updatePracticeConsultationState(consultationId: number, state: WorkflowStateChangeEnum) {
    return this.apiService.patchData(`${environment.apiPrefix}/v1/consultations/${consultationId}/${state}`, {});
  }

  getSessionInformation(sessionId: number) {
    return this.apiService.getData(`${environment.apiPrefix}/v1/responses/${sessionId}`);
  }

  joinConsultationQueue(details) {
    return this.apiService.postData(`${environment.apiPrefix}/v1/consultations/practice/join-queue`, details);
  }
}
