import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
// Services
import { ApiService } from '../../shared/general-shared/services/api.service';
import { LocalStorageService } from '../../shared/general-shared/services/local-storage.service';
import { RegistrationInterface } from '../../shared/general-shared/models/interfaces/registration.interface';
/**
 * AuthService - Service for managing user authentication (as well as local user data)
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /**
   * constructor function
   * @param apiService - API interaction service
   */
  constructor(private apiService: ApiService,
              private localStorageService: LocalStorageService) { }
  /**
   * login function - Calls the login endpoint with package details needs to auth user
   * @param user - username string
   * @param pw - password string
   */
  login(user, pw): Observable<any> {
    const loginPackage = {
      grant_type: 'password',
      client_id: environment?.client_id,
      client_secret: environment?.client_secret,
      username: user,
      password: pw,
      scope: 'admin',
    };
    return this.apiService.postData(environment.authPrefix + '/v1/login', loginPackage);
  }
  /**
   * forgotPasswordReset function - Calls forgot password endpoint with email address which then sends a reset email.
   */
  forgotPasswordReset(emailAddress: string) {
    return this.apiService.postData(`${environment.authPrefix}/v1/tokens/users/${emailAddress}/reset?client=${environment.client_id}&secret=${environment.client_secret}`, {});
  }
  /**
   * registerAccount function - Calls register endpoint with package details required to create a new account.
   */
  registerAccount(account: RegistrationInterface) {
    return this.apiService.postData(`${environment.apiPrefix}/v1/users`, account);
  }
  /**
   * setUserLocal function - Sets currentUser in local storage from user param object
   * @param user - logged in user object
   */
  setUserLocal(user) {
    this.localStorageService.setData('currentUser', user);
  }
  /**
   * setUserToken function - Sets userToken in localStorage for authenticating API calls
   * @param token - JWT Token from Auth response
   */
  setUserToken(token) {
    localStorage.setItem('userToken', token);
  }
  /**
   * setUserPermissions function - Sets userPermissions in localStorage to determine feature access
   * @param permissions - Key Valued object contained permissions details
   */
  setUserPermissions(permissions) {
    this.localStorageService.setData('userPermissions', permissions);
  }
  /**
   * clearUserLocal function - Removes user details from localStorage (when logging user out)
   */
  clearUserLocal() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('userToken');
  }
}
