import { Pipe, PipeTransform } from '@angular/core';
import { ConsultationSelectedProductInterface } from '../../consultation-shared/models/interfaces/consultation-selected-product.interface';

/**
 * Custom Angular pipe to format a unit price.
 * @param item - The ConsultationSelectedProductInterface object containing price information.
 * @returns The formatted unit price as a string (e.g., '£10.00').
 */
@Pipe({
  name: 'productUnitPricePipe'
})
export class ProductUnitPricePipe implements PipeTransform {
  /**
   * Transforms the given ConsultationSelectedProductInterface into a formatted unit price string.
   * @param item - The ConsultationSelectedProductInterface object containing price information.
   * @returns The formatted unit price as a string (e.g., '£10.00').
   */
  transform(item: ConsultationSelectedProductInterface): string {
    if (!item) {
      return '£0.00';
    }

    let totalPence = 0;

    if (item.prescription && item.prescription.unit_price) {
      totalPence = item.prescription.unit_price;
    } else if (item.product && item.product.price && item.product.price.price) {
      totalPence = item.product.price.price;
    }

    const pounds = Math.floor(totalPence / 100);
    const pence = totalPence % 100;

    return `£${pounds}.${pence.toString().padStart(2, '0')}`;
  }
}
