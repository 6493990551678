import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';

export const listItemsAnimation = trigger('items', [
  transition(':enter', [
    style({ transform: 'scale(0.5)', opacity: 0 }),  // initial
    animate('0.25s ease',
    style({ transform: 'scale(1)', opacity: 1 }))  // final
  ]),
  transition(':leave', [
    style({ transform: 'scale(1)', opacity: 1, height: '*' }),
    animate('0.25s ease',
      style({
        transform: 'scale(0.5)', opacity: 0,
        height: '0px', margin: '0px'
      }))
  ])
]);

// @TODO: Investigate stagger animation not working.
export const listAnimation = trigger('list', [
  transition(':enter', [
    query('@items', [stagger(150, animateChild())], {optional: true})
  ])
]);
