import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorManagerService implements ErrorHandler {

  constructor(private injector: Injector, private router: Router, private GA: GoogleAnalyticsService) { }

  handleError(error: any) {
    console.error(error);
    this.GA.logException(error, true);
  }
}
