import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { startCase } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private messageService: MessageService) { }

  showAlert(message, type, title?: string, key?: string) {
    this.messageService.add({key, summary: title || startCase(type), detail: message, severity: type, life: 5000});
  }

  clear(key?: string) {
    this.messageService.clear(key);
  }
}
