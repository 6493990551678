<highcharts-chart
  *ngIf="chartOptions && points?.length > 0"
  [Highcharts]="Highcharts"
  [options]="chartOptions"
  [style.width.%]="100"
  [style.height.px]="chartHeight"
  [style.display]="'block'"
></highcharts-chart>

<section class="no-chart" *ngIf="!chartOptions || (!points || points.length === 0)">
  <div class="error-message-container">
    <mat-icon>timeline</mat-icon>
    <p>{{timelineError}}</p>
  </div>
</section>
