// Angular / RXJS
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { isString } from 'lodash';
import { Subscription } from 'rxjs';
// Services
import { ConfirmationService } from 'primeng/api';
import { LocalStorageService } from '../../services/local-storage.service';
import { AddressService } from '../../services/address.service';
import { AddressLookupService } from '../../../edd-shared/services/address-lookup.service';
/**
 * AddressSelectionComponent
 */
@Component({
  selector: 'app-address-selection',
  templateUrl: './address-selection.component.html',
  providers: [ConfirmationService]
})
export class AddressSelectionComponent implements OnInit {
  @Input() clientID: number; // Client User Id
  @Input() clientAddresses: Array<any>;
  @Input() selectedAddress: any;
  @Output() addressConfirmed: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateAddressList: EventEmitter<any> = new EventEmitter<any>();
  creatingAddress: boolean;
  manageClientAddresses: boolean;
  addressCreationForm: FormGroup;
  subscription = new Subscription();
  /**
   * @param confirmationService - To display confirmation messages (Prime NG)
   * @param storageService - To persist address selection to local storage
   * @param addressService - To manage user's existing addresses from API
   * @param addressLookupService - External API to search addresses by postcode
   */
  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly storageService: LocalStorageService,
    private readonly addressService: AddressService,
    private readonly addressLookupService: AddressLookupService
  ) { }
  /**
   * Angular Init function
   */
  ngOnInit(): void {}
  /**
   * confirmAddress function - Sets selected address in local storage and emit the selection through addressConfirmed EventEmitter.
   */
  confirmAddress() {
    this.storageService.setData('address', this.selectedAddress);
    this.addressConfirmed.emit(this.selectedAddress);
  }
  /**
   * manageAddresses function - sets manageClientAddresses to true (to enable managing client addresses)
   */
  manageAddresses() {
    this.manageClientAddresses = true;
  }
  /**
   * addAddress function - Set creating Address to true and create initial form for address creation.
   */
  addAddress() {
    this.manageClientAddresses = true;
    this.creatingAddress = true;
    this.addressCreationForm = new FormGroup({
      city: new FormControl(null, [Validators.required]),
      country: new FormControl(null, [Validators.required]),
      county: new FormControl(' '),
      default: new FormControl(null),
      house_number: new FormControl(null, [Validators.required]),
      postcode: new FormControl(null, [Validators.required]),
      street: new FormControl(null, [Validators.required]),
      user_id: new FormControl(this.clientID),
      postcodeLookup: new FormControl(''),
      searchResults: new FormControl(null),
      editingAddress: new FormControl(false),
      noSearchResults: new FormControl(null)
    });
  }
  /**
   * setAddress function - Set creation form with values from postcode search provided as an input.
   */
  setAddress(address) {
    this.addressCreationForm.patchValue({
      house_number: address.building_number || '',
      street: address.thoroughfare || '',
      county: address.county || '',
      postcode: address.postcode || '',
      country: address.country || '',
      city: address.town_or_city || '',
      editingAddress: true
    });
  }
  /**
   * editAddressManually function - Toggle the manual address edit form template.
   */
  editAddressManually() {
    this.addressCreationForm.patchValue({
      editingAddress: true
    });
  }
  /**
   * checkPostCode function - Lookup postcodes using API
   */
  checkPostCode(value: string | {postcode: string}) {
    const postcode = isString(value) ? value : value.postcode;
    const postCodeLookupControl = this.addressCreationForm.get('postcodeLookup');
    const noSearchResultsControl = this.addressCreationForm.get('noSearchResults');
    const searchResultsControl = this.addressCreationForm.get('searchResults');
    if (postcode.length > 2) {
      noSearchResultsControl.setValue(false);
      this.subscription.add(this.addressLookupService.getAddressByPostcode(postcode).subscribe((data: any) => {
        const addresses = data.addresses.map(x => {
          x.postcode = data.postcode;
          return x;
        });
        postCodeLookupControl.setValue(data);
        searchResultsControl.setValue(addresses);
        }, error => {
        postCodeLookupControl.patchValue({
          postcode
        });
        searchResultsControl.setValue([]);
        noSearchResultsControl.setValue(true);
      }));
    }
    else {
      postCodeLookupControl.patchValue({
        postcode
      });
      searchResultsControl.setValue([]);
      noSearchResultsControl.setValue(true);
    }
  }
  /**
   * saveNewAddress function - Save new address to the API
   */
  saveNewAddress() {
    const address = this.addressCreationForm.value;
    const payload = {
      city: address.city,
      country: address.country,
      county: address.county,
      default: address.default,
      house_number: address.house_number,
      postcode: address.postcode,
      street: address.street,
      user_id: address.user_id
    };
    this.subscription.add(this.addressService.createNewUserAddress(payload).pipe(
    ).subscribe((result) => {
      this.updateAddressList.emit();
      this.creatingAddress = false;
    }));
  }
  /**
   * displayConfirmDeleteAddress - Displays a confirmation popup (via Prime Confirmation Service)
   * @param event - Click event
   * @param address - address to delete
   * @param userId - user id for API call
   */
  displayConfirmDeleteAddress(event, address, userId: number) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteAddress(address, userId);
      }
    });
  }
  /**
   * deleteAddress function - Delete existing address from the API
   */
  deleteAddress(address, userId: number) {
    if (!address?.id) {
      return;
    }
    this.subscription.add(this.addressService.deleteUserAddress(address.id, this.clientID).pipe(
    ).subscribe((result) => {
      this.updateAddressList.emit();
      this.creatingAddress = false;
      if (this.selectedAddress.id === address.id) {
        this.selectedAddress = undefined;
      }
    }));
  }
  /**
   * cancelAddressCreation function - Go back to the listing view.
   */
  cancelAddressCreation() {
    this.addressCreationForm = undefined;
    this.creatingAddress = false;
    this.manageClientAddresses = false;
  }
  /**
   * cancelManageClientAddress function - Sets manageClientAddresses to false to hide address management
   */
  cancelManageClientAddress() {
    this.manageClientAddresses = false;
  }
}
