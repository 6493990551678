import { Pipe, PipeTransform } from '@angular/core';
import { filter, isArray, toLower, trim } from 'lodash';

@Pipe({
  name: 'filter',
  pure: false // Disable pure pipe to trigger change detection on every input change
})
export class FilterPipe implements PipeTransform {
  transform(list: Array<any>, searchTerm: string, prop?: string): Array<any> {
    if (!list || !isArray(list) || list.length === 0 || !searchTerm || searchTerm.length === 0) {
      return list;
    }

    const normalizedSearchTerm = toLower(trim(searchTerm));

    return filter(list, (item) => {
      if (typeof item === 'string') {
        const normalizedItem = toLower(trim(item));
        return normalizedItem.includes(normalizedSearchTerm);
      }

      if (item && prop && item.hasOwnProperty(prop) && typeof item[prop] === 'string') {
        const normalizedProp = toLower(trim(item[prop]));
        return normalizedProp.includes(normalizedSearchTerm);
      }

      return false;
    });
  }
}
