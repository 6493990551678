import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

import { format } from 'date-fns';

import { AgePipe } from '../pipes/age.pipe';
import { first } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class LabelPrinterService {

  // @ToDo: Going direct to httpclient as need text response vs JSON
  constructor(private http: HttpClient) { }
  /**
   * getLabelWarningMessage function - Returns default warning message
   */
  getLabelWarningMessage() {
    return 'Keep out of reach of children. For animal use only. Wear gloves';
  }
  /**
   * getPrinters function - Calls labelPrinterPrefix URl to get list of Printers (XML response hence direct HTTP)
   */
  getPrinters() {
    return this.http.get(environment.labelPrinterPrefix + '/GetPrinters', { observe: 'body', responseType: 'text'});
  }
  /**
   * renderLabel function - Calls labelPrinterPrefix URl to get label preview image (base 64 response)
   * @param label - XML Label details
   */
  renderLabel(label) {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    return this.http.post(environment.labelPrinterPrefix + '/RenderLabel',
      'labelXml=' + encodeURIComponent(label), options);
  }
  /**
   * printLabel function - Calls labelPrinterPrefix URl to print label
   * @param printer - Name of printer selected
   * @param label - XML Label Details
   */
  printLabel(printer, label) {
    const labelBody = `printerName=${printer}&labelXml=${encodeURIComponent(label)}`;
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    return this.http.post(environment.labelPrinterPrefix + '/PrintLabel', labelBody, options);
  }
  /**
   * readyLabel function - Loops through label XML to set text fields
   * @param template - Label template (XML data)
   * @param data - Label details to add to template
   */
  readyLabel(template, data) {
    const parser = new DOMParser();
    const labelDetails = parser.parseFromString(template, 'application/xml');
    const textObjectCheck = labelDetails.getElementsByTagName('TextObject');
    // Loop through Text Objects
    Array.from(textObjectCheck).forEach(item => {
      const nodeNameParent = item.getElementsByTagName('Name');
      const nodeName = nodeNameParent[0].innerHTML;
      const textFieldParent = item.getElementsByTagName('Text');
      // @ToDo: This will need a more flexible config (might have different templates)
      // Match Template Text Field Name
      switch (nodeName) {
        // Practice Address
        case 'ITextObject0':
          textFieldParent[0].innerHTML = data.practice_address.line1 + ', ' +
            (data.practice_address.line2 ? data.practice_address.line2 + ',\n' : ',\n') +
            data.practice_address.city + ',' +
            (data.practice_address.county ? data.practice_address.county + ', ' : '') +
            data.practice_address.postcode;
          break;
        // Staff Name
        case 'ITextObject1':
          textFieldParent[0].innerHTML = data.staff_name;
          break;
        // User Name
        case 'ITextObject2':
          textFieldParent[0].innerHTML = data.customer_name;
          break;
        // Pet Details
        case 'ITextObject3':
          // @ToDo: May need refinement/pipe transforms
          textFieldParent[0].innerHTML = data.pet_info;
          break;
        // User Address
        case 'ITextObject4':
          // @ToDo: This is temporary as address doesn't exist on user model
          if (data.address) {
            textFieldParent[0].innerHTML = data.address;
          }
          break;
        // Product Name
        case 'ITextObject5':
          textFieldParent[0].innerHTML = data.product_info.replace(/(.{42})/g, '$1\n');
          break;
        // Product Instructions
        case 'ITextObject6':
          // @ToDo: This parser might need to more accurate (hyphens + empty spaces handled)
          textFieldParent[0].innerHTML = data.owner_instructions.replace(/(.{42})/g, '$1\n');
          break;
        // Date
        case 'ITextObject7':
          const todayDate = new Date();
          textFieldParent[0].innerHTML =  'Date:\n' + format(todayDate, 'dd/MM/yy');
          break;
        // Warning Message
        case 'ITextObject8':
          textFieldParent[0].innerHTML = data.special_instructions ?
            data.special_instructions + ' - ' + this.getLabelWarningMessage() : this.getLabelWarningMessage();
          break;
      }
    });
    const serializer = new XMLSerializer();
    const serialisedLabel = serializer.serializeToString(labelDetails);
    // Had to add replacements for closing colour tags and DYMOThickness (removing the closed tag)
    return serialisedLabel.replace(/<([^> ]+)( [^>]+)?\/>/g, '<$1$2></$1>')
      .replace(/(><\/DYMOThickness>)/g, '/>');
  }
  /**
   * parsePrinterDetails function - Loops through XML Array to return list of printers
   * @param data - XML list of printers
   */
  parsePrinterDetails(data: string): Array<string> {
    return Array.from(
      new DOMParser()
        .parseFromString(data, 'application/xml')
        .getElementsByTagName('LabelWriterPrinter')
    ).map(printer => {
      return first(printer.getElementsByTagName('Name')).innerHTML;
    });
  }
}
