<section class="client-basket-holder" [style.--basket-header-footer]="totalHeight + 'px'">
  <header class="basket-header" #Header>
    <span>
      <i class="pi pi-shopping-cart" style="font-size: 2rem"></i>
      <h1>Basket</h1>
    </span>
    <button pButton pRipple (click)="close.emit()" type="button" icon="pi pi-times" class="close-button p-button-rounded"></button>
  </header>
  <p-tabView [(activeIndex)]="activeTabIndex" (onChange)="onTabChange()" *ngIf="!editingAddress">
    <p-tabPanel [header]="'Pending' + ((pendingBasketProducts | async)?.length ? ' (' + (pendingBasketProducts | async)?.length + ')' : '')">
      <p-scrollPanel>
        <ng-container *ngFor="let product of pendingBasketProducts | async as products; let i = index; trackBy: trackBy;">
          <app-client-basket-item
            [item]="product"
            [practiceID]="practiceID"
            [userID]="clientID"
            [address]="selectedAddress"
            [selectedPrinter]="selectedPrinter"
            [practiceDetails]="practiceDetails"
            (itemChanges)="handlePendingProductChange(products, $event)"
            (removeItem)="removeProductFromPendingBasket(product, i)"
            (confirmItem)="confirmBasketItem($event)"
          ></app-client-basket-item>
        </ng-container>
      </p-scrollPanel>
    </p-tabPanel>
    <p-tabPanel [header]="'Confirmed' + ((confirmedBasket | async)?.items?.length ? ' (' + (confirmedBasket | async)?.items?.length + ')' : '')">
      <p-scrollPanel>
        <ng-container *ngFor="let item of (confirmedBasket | async)?.items">
          <app-client-basket-item
            [item]="item"
            [checkout]="checkoutBasket"
            [practiceID]="practiceID"
            [userID]="clientID"
            [selectedPrinter]="selectedPrinter"
            [practiceDetails]="practiceDetails"
            (removeItem)="removeProductFromConfirmedBasket(item)"
            (editItem)="editBasketItem(item)"
          ></app-client-basket-item>
        </ng-container>
        <div class="empty-container" *ngIf="(confirmedBasket | async)?.items?.length === 0">
          <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28" viewBox="0 0 27 28" fill="none">
            <g id="Group">
              <g id="Layer_90">
                <path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M26.8334 23.3332V4.6665C26.8334 2.45736 25.0426 0.666504 22.8334 0.666504H4.16675C1.95761 0.666504 0.166748 2.45736 0.166748 4.6665V23.3332C0.166748 25.5423 1.95761 27.3332 4.16675 27.3332H22.8334C25.0426 27.3332 26.8334 25.5423 26.8334 23.3332ZM12.159 7.33317C12.159 7.68869 12.301 8.02948 12.5534 8.27984C12.9349 8.66445 13.5111 8.78005 14.0114 8.57233C14.5117 8.36461 14.8366 7.87486 14.8334 7.33317C14.8366 6.79148 14.5117 6.30173 14.0114 6.09401C13.5111 5.88629 12.9349 6.00189 12.5534 6.3865C12.301 6.63686 12.159 6.97765 12.159 7.33317ZM24.1667 4.6665C24.1667 3.93012 23.5698 3.33317 22.8334 3.33317H4.16675C3.43037 3.33317 2.83341 3.93012 2.83341 4.6665V23.3332C2.83341 24.0695 3.43037 24.6665 4.16675 24.6665H22.8334C23.5698 24.6665 24.1667 24.0695 24.1667 23.3332V4.6665ZM14.8334 19.3332H15.0201C15.7565 19.3332 16.3534 19.9301 16.3534 20.6665C16.3534 21.4029 15.7565 21.9998 15.0201 21.9998H11.9801C11.2437 21.9998 10.6467 21.4029 10.6467 20.6665C10.6467 19.9301 11.2437 19.3332 11.9801 19.3332H12.1667V12.6665C11.4304 12.6665 10.8334 12.0695 10.8334 11.3332C10.8334 10.5968 11.4304 9.99984 12.1667 9.99984H13.5001C14.2365 9.99984 14.8334 10.5968 14.8334 11.3332V19.3332Z" fill="#EB6B0D"/>
              </g>
            </g>
          </svg>
          <span>Confirm pending products to continue</span>
        </div>
      </p-scrollPanel>
    </p-tabPanel>
  </p-tabView>
  <article
    class="footer"
    #Footer
    *ngIf="!editingAddress">
    <article
      *ngIf="checkoutBasket &&
      (terminalStatus === 'processing-payment' ||
      terminalStatus === 'payment-successful' ||
      terminalStatus === 'present-card' ||
      terminalStatus === 'payment-cancelled' ||
      terminalStatus === 'idle')"
      class="checkout-manager">
      <div>
        <label class="card-reader-label">Card Reader</label>
      </div>
      <p-dropdown
        optionLabel="id"
        appendTo="body"
        class="full-width"
        [(ngModel)]="selectedTerminal"
        [options]="availableTerminals"
        [disabled]="terminalStatus === 'processing-payment' || terminalStatus === 'payment-successful'"></p-dropdown>
    </article>
    <article
      *ngIf="availablePrinters && activeTabIndex === 1"
      class="checkout-manager">
      <div>
        <label>Label Printer</label>
      </div>
      <p-dropdown
        *ngIf="availablePrinters.length > 0"
        appendTo="body"
        class="full-width"
        placeholder="Select a printer"
        [(ngModel)]="selectedPrinter"
        [options]="availablePrinters"></p-dropdown>
      <div *ngIf="availablePrinters.length === 0" class="printer-retry">
        <p-button
          pTooltip="Refresh Printers"
          icon="pi pi-refresh"
          styleClass="p-button-rounded p-button-outlined"
          (onClick)="getPrinters(true)"></p-button>
        <small class="p-error">No Printers Found</small>
      </div>
    </article>
    <section
      class="total-holder"
      [class.processing]="terminalStatus !== 'idle' && terminalStatus !== 'payment-successful' && terminalStatus !== 'payment-cancelled'"
      [class.complete]="terminalStatus === 'payment-successful'"
      *ngIf="activeTabIndex === 1">
      <span>
        <span class="payment-icon" *ngIf="terminalStatus !== 'idle' && terminalStatus !== 'payment-successful' && terminalStatus !== 'payment-cancelled'">
          <i class="pi pi-refresh pi-spin loading-icon"></i>
        </span>
        <span class="payment-icon" *ngIf="terminalStatus === 'payment-successful'">
          <i class="pi pi-check-circle loading-icon"></i>
        </span>
        <span *ngIf="!terminalStatus || terminalStatus === 'idle' || terminalStatus === 'payment-cancelled'">&nbsp;Total</span>
        <span *ngIf="terminalStatus && terminalStatus !== 'idle' && terminalStatus !== 'payment-cancelled'">{{terminalStatus | paymentLabel}}</span>
      </span>
      <span>{{(confirmedBasket | async).total | joiiCurrency}}</span>
    </section>
    <section class="total-holder" *ngIf="activeTabIndex === 0">
      <span>&nbsp;Total</span>
      <span><span class="approx-label">Approx.</span> {{((pendingBasketProducts | async) | pendingBasketTotal)}}</span>
    </section>
    <section
      class="actions-holder"
      [class.inactive]="activeTabIndex === 0"
      [class.active]="activeTabIndex === 1">
      <div class="address-holder">
        <svg class="address-icon" width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Group">
            <g id="Layer_25">
              <path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M24.6668 9.32L26.8802 11.3333C27.4318 11.8197 27.4855 12.6608 27.0002 13.2133C26.5138 13.7649 25.6727 13.8186 25.1202 13.3333L24.6668 12.9333V24.3333C24.6668 26.5425 22.876 28.3333 20.6668 28.3333H16.6668C15.9304 28.3333 15.3335 27.7364 15.3335 27V20.3333C15.3391 19.986 15.1989 19.6523 14.9468 19.4133C14.6996 19.154 14.3584 19.005 14.0002 19C13.2638 19 12.6668 19.597 12.6668 20.3333V27C12.6668 27.7364 12.0699 28.3333 11.3335 28.3333H7.33349C5.12435 28.3333 3.33349 26.5425 3.33349 24.3333V12.9333L2.88016 13.3333C2.32427 13.7811 1.51438 13.7116 1.04284 13.1758C0.571297 12.6399 0.60538 11.8278 1.12016 11.3333L3.33349 9.32L4.46683 8.33333L13.1202 0.666668C13.6235 0.224473 14.3768 0.224473 14.8802 0.666668L23.5335 8.33333L24.6668 9.32ZM20.6668 25.6667C21.4032 25.6667 22.0002 25.0697 22.0002 24.3333V10.56L14.0002 3.45333L6.00016 10.56V24.3333C6.00016 25.0697 6.59711 25.6667 7.33349 25.6667H10.0002V20.3333C10.0002 18.1242 11.791 16.3333 14.0002 16.3333C16.2093 16.3333 18.0002 18.1242 18.0002 20.3333V25.6667H20.6668Z" fill="#231F20"/>
            </g>
          </g>
        </svg>
        <div class="address-content">
          <div class="address-header">
            <h1>Address</h1>
            <p-button
              [pTooltip]="(confirmedBasket | async)?.items?.length > 0 ? 'Address Locked' : 'Change Address'"
              [icon]="(confirmedBasket | async)?.items?.length > 0 ? 'pi pi-lock' : 'pi pi-pencil'"
              [disabled]="(confirmedBasket | async)?.items?.length > 0"
              styleClass="p-button-rounded p-button-outlined"
              (onClick)="editAddress()"></p-button>
          </div>
          <span *ngIf="selectedAddress">
            {{selectedAddress.house_number}} {{selectedAddress.street}}, {{selectedAddress.city}}, {{selectedAddress.postcode}}
          </span>
        </div>
      </div>
      <div class="button-container" *ngIf="activeTabIndex === 1 && !checkoutBasket">
        <p-button
          class="checkout"
          label="PROCEED TO CHECKOUT"
          (onClick)="displayCheckout()"></p-button>
<!--        @TODO: Enable when print all functionality is more reliable and local printer testing can be completed.-->
<!--        <p-button-->
<!--          *ngIf="(!printingAllLabels && failedPrintAllLabels.length === 0) || (printingAllLabels && printAllCount >= 0)"-->
<!--          [label]="printingAllLabels ? 'PRINTING (' + printAllCount + ')' : 'PRINT ALL'"-->
<!--          styleClass="p-button-outlined"-->
<!--          [disabled]="printingAllLabels || (confirmedBasket | async)?.items?.length === 0 || !selectedPrinter"-->
<!--          (onClick)="setupPrintAllLabels()"></p-button>-->
<!--        <p-button-->
<!--          *ngIf="!printingAllLabels && failedPrintAllLabels.length > 0"-->
<!--          label="Retry Print All"-->
<!--          styleClass="p-button-outlined p-button-danger"-->
<!--          [disabled]="printingAllLabels || !selectedPrinter"-->
<!--          (onClick)="retryPrintAll()"></p-button>-->
      </div>
      <div class="button-container" *ngIf="activeTabIndex === 1 && checkoutBasket && terminalStatus !== 'payment-successful'">
        <p-button
          class="checkout"
          [pTooltip]="'Send Payment To Card Reader'"
          [label]="terminalStatus !== 'idle' ? 'Restart Payment' : 'Start Payment'"
          (onClick)="startPayment()"></p-button>
        <p-button
          *ngIf="paymentIntent.status !== 'succeeded' && terminalStatus !== 'idle'"
          class="checkout"
          [pTooltip]="'Check Payment Status'"
          label="Check Status"
          (onClick)="updatePaymentStatus()"></p-button>
        <p-button
          label="Cancel"
          [pTooltip]="'Cancel Card Reader Payment'"
          styleClass="p-button-danger"
          (onClick)="cancelPayment()"></p-button>
      </div>
      <div class="button-container" *ngIf="activeTabIndex === 1 && checkoutBasket && terminalStatus === 'payment-successful'">
        <p-button
          class="checkout"
          label="Close and reset basket"
          (onClick)="finaliseBasket()"></p-button>
      </div>
    </section>
  </article>
  <article *ngIf="editingAddress" class="address-manager">
    <h1>Address Manager</h1>
    <app-address-selection
      [selectedAddress]="selectedAddress"
      [clientAddresses]="availableAddresses"
      [clientID]="clientID"
      (addressConfirmed)="handleAddressChange($event)"
      (updateAddressList)="getUserAddresses()"></app-address-selection>
  </article>
</section>
