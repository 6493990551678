import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Observable, Subject, Subscription } from 'rxjs';
import { UsersService } from '../../services/users.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { debounceTime, tap } from 'rxjs/operators';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { format } from 'date-fns';

@Component({
  selector: 'app-user-order-history',
  templateUrl: './user-order-history.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UserOrderHistoryComponent implements OnInit, AfterViewInit {
  @Input() userId: number;
  @Input() fullName: string;
  public orders: Array<any>;
  public loading: boolean;
  public displayedColumns: Array<string>;
  public productDisplayedColumns: Array<string>;
  public orderDataSource: MatTableDataSource<any>;
  public productsDataSource: MatTableDataSource<any>;
  searchSubject: Subject<string> = new Subject<string>();
  searchSubscription: Subscription;
  orderHistoryPagePaginationConfig: any;
  expandedElement: any;
  filterStartDate: any;
  filterEndDate: any;
  @ViewChild('ordersPaginator', {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  subscriptions: Subscription = new Subscription();

  constructor(
    private userService: UsersService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit() {
    this.initOrderHistory();
  }

  ngAfterViewInit() {
    if (this.orderDataSource) {
      this.orderDataSource.paginator = this.paginator;
      this.orderDataSource.sort = this.sort;
    }
  }

  initOrderHistory() {
    this.loading = true;
    this.orderHistoryPagePaginationConfig = {
      total: 0,
      limit: 5,
      page: 1,
      descending: false,
      order: 'id',
      pet: '',
      sortDirection: 'desc',
      searchTerm: ''
    };
    this.updateTableData();
  }

  getUserOrders(userId: number, searchTerm = '', pageNumber: number, pageSize: number, startDate: string, endDate: string): Observable<any> {
    return this.userService.getUserOrders(userId, pageNumber, pageSize, searchTerm, startDate, endDate);
  }

  initOrdersTable() {
    this.setTableData(this.orders);
  }

  setDisplayedColumns() {
    this.displayedColumns = [
      'order_id',
      'shipped',
      'total_items',
      'order_total',
      'shipping_cost',
      'shipping_refunded'
    ];

    this.productDisplayedColumns = [
      'product_id',
      'product_name',
      'quantity',
      'subscription',
      'price_paid',
      'refunded',
      'image',
    ];
  }

  setTableData(orders: Array<any>) {
    this.orderDataSource = new MatTableDataSource<any>(orders);
  }

  applyFilter(filterValue?: any) {
    if (!this.searchSubscription) {
      this.searchSubscription = new Subscription();
      this.searchSubscription.add(this.searchSubject.asObservable().pipe(
       debounceTime(1000),
       // tap((term) => {
       //   if (term !== this.orderHistoryPagePaginationConfig.searchTerm) {
       //     this.orderHistoryPagePaginationConfig.searchTerm = term;
       //     this.orderHistoryPagePaginationConfig.page = 1;
       //   }
       // })
     ).subscribe(() => {
       this.updateTableData();
     }));
    }
    this.searchSubject.next(filterValue?.target?.value);
  }

  expandRow(data) {
    if (this.expandedElement === data) {
      this.expandedElement = null;
    } else {
      this.expandedElement = data;
      this.setProductsDataSource(this.expandedElement.items);
    }
    return this.expandedElement;
  }

  setProductsDataSource(products: any[]) {
    this.productsDataSource = new MatTableDataSource<any>(products);
  }

  onPage(pageEvent: PageEvent) {
    this.orderHistoryPagePaginationConfig.page = pageEvent?.pageIndex + 1;
    this.orderHistoryPagePaginationConfig.limit = pageEvent.pageSize;
    this.loading = true;
    this.updateTableData();
  }

  updateTableData() {
    this.loading = true;
    this.subscriptions.add(
      this.getUserOrders(
        this.userId,
        this.orderHistoryPagePaginationConfig.searchTerm,
        this.orderHistoryPagePaginationConfig.page,
        this.orderHistoryPagePaginationConfig.limit,
        this.filterStartDate ? format(this.filterStartDate, 'yyyy-MM-dd') : null,
        this.filterEndDate ? format(this.filterEndDate, 'yyyy-MM-dd') : null,
      ).subscribe((orders) => {
        this.loading = false;
        this.orders = orders?.results;
        this.orderHistoryPagePaginationConfig.total = orders?.total;
        this.setDisplayedColumns();
        this.initOrdersTable();
      }, (data) => {
        this.errorHandlerService.handleError(data?.error);
        this.loading = false;
      })
    );
  }

  clearDateFilter() {
    this.loading = true;
    this.filterStartDate = null;
    this.filterEndDate = null;
    this.applyFilter();
  }
}
