import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'JoiiJsonParse'
})
export class JoiiJsonParsePipe implements PipeTransform {

  transform(value: string): unknown {
    return JSON.parse(value);
  }

}
