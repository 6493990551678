import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const requiresToken = this.routeRequiresToken(request.url);
    if (requiresToken) {
      const token = this.getUserToken();
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(request);
  }

  getUserToken() {
    return localStorage.getItem('userToken');
  }

  routeRequiresToken(url: string) {
    let requiresToken = true;
    // If login route
    if (url.includes('login')) {
      requiresToken = false;
    }
    if (!url.includes(environment.apiPrefix) && !url.includes(environment.mediaPrefix)) {
      requiresToken = false;
    }
    return requiresToken;
  }
}
