import { Component, OnInit, OnDestroy, ErrorHandler } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { combineLatest, forkJoin, merge, Observable, of, Subscription, throwError } from 'rxjs';

import { AlertService } from '../../services/alert.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { PetService } from '../../../pet-shared/services/pet.service';
import { PracticeService } from '../../services/practice.service';
import { UsersService } from '../../services/users.service';
import { chain, cloneDeep, filter, find, findIndex, first, get, isArray, isNil, isString, omitBy, random, round } from 'lodash';
import { catchError, debounceTime, map, mergeMap, tap } from 'rxjs/operators';
import { UserInterface } from '../../models/interfaces/user.interface';
import { PetInterface } from '../../../pet-shared/models/interfaces/pet.interface';
import { NotesService } from '../../services/notes.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-client-registration',
  templateUrl: './client-registration.component.html'
})
export class ClientRegistrationComponent implements OnInit, OnDestroy {

  savingDetails: boolean;
  failedJobs: Array<{pet: PetInterface, user: UserInterface, error: any}> = [];
  practiceLoadingProblem: boolean;
  clientRegistrationForm: FormGroup;
  availableSpecies: Array<string> = ['cat', 'dog'];
  availableSexes: Array<string> = ['male', 'female'];
  availablePractices: Array<any> = [];
  allPetBreeds: {cat: Array<any>, dog: Array<any>};
  subscriptions: Subscription = new Subscription();

  constructor(private alertService: AlertService,
              private localStorageService: LocalStorageService,
              private petService: PetService,
              private practiceService: PracticeService,
              private usersService: UsersService,
              private notesService: NotesService,
              private errorHandlerService: ErrorHandlerService,
              private messageService: MessageService
              ) { }

  ngOnInit(): void {
    this.getAvailablePractices();
    this.getAvailableBreeds();
    this.setupForm();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getAvailableBreeds() {
    this.subscriptions.add(this.petService.getPetBreeds().subscribe(data => {
      this.allPetBreeds = data;
    }, data => {
      this.alertService.showAlert(data.error.cause ? data.error.cause : 'Failed to get pet breeds', 'error');
    }));
  }

  getAvailablePractices() {
    this.subscriptions.add(this.practiceService.getAllPractices().subscribe(data => {
      this.practiceLoadingProblem = false;
      this.availablePractices = data;
      if (this.clientRegistrationForm) {
        const localUser = this.localStorageService.getData('currentUser');
        if (localUser.practice_id) {
          this.clientRegistrationForm.controls.user.get('practice')
            .setValue(this.availablePractices.find(x => x.id === localUser.practice_id));
        }
      }
    }, data => {
      this.practiceLoadingProblem = true;
      this.alertService.showAlert(data.error.cause ? data.error.cause : 'Failed to get practices', 'error');
    }));
  }

  setupForm() {
    const petForm: FormGroup = new FormGroup({
      name: new FormControl('', Validators.required),
      species: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      breeds: new FormControl([], [this.validateBreeds]),
      age: new FormControl(0),
      age_years: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      age_months: new FormControl(null, Validators.required),
      age_weeks: new FormControl(null),
      weight: new FormControl(null),
      neutered: new FormControl(false),
      available_breeds: new FormControl([]),
      note: new FormControl(null),
      microchip_number: new FormControl(null)
    });
    this.clientRegistrationForm = new FormGroup({
      user: new FormGroup({
        name: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.required, Validators.email]),
        phone: new FormControl(''),
        post_code: new FormControl('',      [
          Validators.pattern(
            /^[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]? ?[0-9][A-Za-z]{2}$/ // UK postcode pattern
          ),
        ]),
        practice: new FormControl(null, Validators.required)
      }),
      pets: new FormArray([petForm])
    });
  }

  saveForm() {
    this.savingDetails = true;
    this.failedJobs = [];

    const formDetails = {
      user: this.extractUserDetails(),
      pets: this.extractPetDetails(),
    };

    this.subscriptions.add(
      this.usersService.createPracticeClient(formDetails, formDetails.user.practice_id).pipe(
        tap(() => this.alertService.showAlert('Successfully created client account', 'success')),
        mergeMap((response) => this.saveNotes(response.user, response.pets)),
      ).subscribe(
        () => this.handleSaveSuccess(),
        (error) => this.handleSaveError(error)
      )
    );
  }

  handleSaveSuccess() {
    if (this.failedJobs.length === 0) {
      this.clientRegistrationForm.enable();
      this.clientRegistrationForm.reset();
      this.setupForm();
    } else {
      this.disableCompletedModels();
    }
    this.savingDetails = false;
  }

  handleSaveError(error: any) {
    this.errorHandlerService.handleError(error);
    this.savingDetails = false;
  }

  retryNotesSave() {
    if (!this.failedJobs || this.failedJobs.length === 0) {
      return;
    }

    const pets = this.extractPetsFromFailedJobs();
    const user = this.extractUserFromFailedJobs();

    this.savingDetails = true;
    this.failedJobs = [];

    this.subscriptions.add(
      this.saveNotes(user, pets).subscribe(() => this.handleSaveSuccess())
    );
  }

  saveNotes(user: UserInterface, pets: Array<PetInterface>) {
    if (!isArray(pets) || !user) {
      return of(null);
    }
    const noteRequests = pets.map((pet) => {
      const matchedPetForm = this.findMatchingPetForm(pet);
      const note = get(matchedPetForm, 'note', '');
      return this.shouldSaveNote(note) ? this.saveNote(user, pet, note) : of(null);
    });

    return combineLatest(noteRequests);
  }

  saveNote(user: UserInterface, pet: PetInterface, content: string): Observable<any> {
    const notePayload = this.createNotePayload(user, pet, content);

    return this.notesService.createNote(notePayload).pipe(
      catchError((error) => this.handleNoteCreationError(user, pet, error))
    );
  }

  disableCompletedModels() {
    // Disable user form
    this.clientRegistrationForm.get('user').disable();
    // Disable pet forms
    const pets = this.clientRegistrationForm.get('pets') as FormArray;
    pets.controls.forEach((petForm: FormGroup) => {
      const petValue = petForm.value;
      const notesControl = petForm.get('note');
      petForm.disable();
      if (findIndex(this.failedJobs, job => job.pet.name.toLowerCase() === petValue.name.toLowerCase()) !== -1) {
        notesControl.enable();
      }
    });
  }

  extractUserDetails(): any {
    const userFormValue = this.clientRegistrationForm.value.user;
    return {
      name: get(userFormValue, 'name', ''),
      email: get(userFormValue, 'email', ''),
      phone: get(userFormValue, 'phone', ''),
      post_code: get(userFormValue, 'post_code', ''),
      practice_id: get(userFormValue, 'practice.id', ''),
    };
  }

  extractPetDetails(): Array<any> {
    return this.clientRegistrationForm.value.pets.map((pet) => ({
      name: get(pet, 'name', ''),
      species: get(pet, 'species', ''),
      gender: get(pet, 'gender', ''),
      breeds: get(pet, 'breeds', ''),
      age: this.calculatePetAgeWeeks(get(pet, 'age_years', 0), get(pet, 'age_months', 0)),
      weight: get(pet, 'weight', ''),
      neutered: get(pet, 'neutered', false),
      microchip_number: get(pet, 'microchip_number', null)
    }));
  }

  extractPetsFromFailedJobs(): Array<PetInterface> {
    return this.failedJobs.map((job) => job.pet);
  }

  extractUserFromFailedJobs(): UserInterface {
    return first(this.failedJobs).user;
  }

  calculatePetAgeWeeks(years: number, months: number) {
    const totalMonths = years * 12 + months;
    return round(totalMonths * 4.345);
  }

  handleSpeciesChange(change, pet) {
    switch (change.value) {
      case 'dog':
        pet.controls.available_breeds.setValue(this.allPetBreeds.dog);
        break;
      case 'cat':
        pet.controls.available_breeds.setValue(this.allPetBreeds.cat);
        break;
      default:
        pet.controls.available_breeds.setValue([]);
    }
    pet.controls.breeds.reset();
  }

  addPet() {
    const newPetForm: FormGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      species: new FormControl(null, Validators.required),
      gender: new FormControl(null, Validators.required),
      breeds: new FormControl([], [this.validateBreeds]),
      age: new FormControl(0),
      age_years: new FormControl(null, [Validators.required]),
      age_months: new FormControl(null, Validators.required),
      age_weeks: new FormControl(null),
      weight: new FormControl(null),
      neutered: new FormControl(false),
      available_breeds: new FormControl([]),
      note: new FormControl([]),
      microchip_number: new FormControl(null)
    });
    this.pets.push(newPetForm);
  }

  removePet(petIndex) {
    this.pets.removeAt(petIndex);
  }

  get pets(): FormArray {
    return this.clientRegistrationForm.controls.pets as FormArray;
  }

  // Custom validator for breeds
  validateBreeds(control: AbstractControl): ValidationErrors | null {
    const breedsArray = control.value as string[];
    if (isNil(breedsArray) || breedsArray.length === 0) {
      return { noBreeds: true };
    }
    return null;
  }

  failedJob(pet: PetInterface) {
    return find(this.failedJobs, job => job.pet.name.toLowerCase() === pet?.name?.toLowerCase());
  }

  createNotePayload(user: UserInterface, pet: PetInterface, content: string): any {
    return {
      user_id: get(user, 'id', ''),
      pet_id: get(pet, 'id', ''),
      content,
    };
  }

  findMatchingPetForm(pet: PetInterface) {
    const petForms = this.clientRegistrationForm.getRawValue().pets || [];
    return petForms.find((x) => x.name.toLowerCase() === pet.name.toLowerCase()) || {};
  }

  shouldSaveNote(note: string): boolean {
    return isString(note) && note.trim().length > 0;
  }

   handleNoteCreationError(user: UserInterface, pet: PetInterface, error: any): Observable<any> {
    this.errorHandlerService.handleError(get(error, 'error', ''), true);

    const failedJob = { pet: cloneDeep(pet), user: cloneDeep(user), error: get(error, 'error', '') };
    this.failedJobs = [...this.failedJobs, failedJob];

    return of(null);
  }

}
