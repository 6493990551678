import { Pipe, PipeTransform } from '@angular/core';
import { AppointmentStatusEnum } from '../../calendar-shared/models/enums/appointment-status.enum';
@Pipe({
  name: 'appointmentStatus'
})
export class AppointmentStatusPipe implements PipeTransform {

  transform(value: AppointmentStatusEnum): string {
    switch (value) {
      case AppointmentStatusEnum.completed: return 'Completed';
      case AppointmentStatusEnum.pending: return 'Pending';
      case AppointmentStatusEnum.missed: return 'Missed';
      case AppointmentStatusEnum.unconfirmed: return 'Unconfirmed';
      case AppointmentStatusEnum.confirmed: return 'Confirmed';
      case AppointmentStatusEnum.denied: return 'Denied';
      case AppointmentStatusEnum.initiated: return 'Initiated';
      case AppointmentStatusEnum.joinedTriage: return 'Joined Triage';
      case AppointmentStatusEnum.rescheduled: return 'Rescheduled';
      case AppointmentStatusEnum.cancelled: return 'Cancelled';
    }
  }

}
