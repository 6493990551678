<button mat-button [matMenuTriggerFor]="menu"><mat-icon>{{currentIcon}}</mat-icon> Theme</button>

<mat-menu #menu="matMenu">
  <button mat-menu-item class="{{currentTheme === 'light-theme' ? 'active' : ''}}" (click)="changeTheme('light-theme')">
    <mat-icon>light_mode</mat-icon>
    <span>Light Mode</span>
  </button>
  <button mat-menu-item class="{{currentTheme === 'dark-theme' ? 'active' : ''}}" (click)="changeTheme('dark-theme')">
    <mat-icon>dark_mode</mat-icon>
    <span>Dark Mode</span>
  </button>
  <button mat-menu-item class="{{currentTheme === 'os-theme' ? 'active' : ''}}" (click)="changeTheme('os-theme')">
    <mat-icon>contrast</mat-icon>
    <span>Automatic</span>
  </button>
</mat-menu>
