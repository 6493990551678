import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { chain, filter, find, isNil, keyBy, omitBy, round } from 'lodash';
// Services
import { AddressService } from '../../services/address.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { UsersService } from '../../services/users.service';
import { PetService } from '../../../pet-shared/services/pet.service';
import { PracticeService } from '../../services/practice.service';
import { AlertService } from '../../services/alert.service';
// Interfaces
import { PracticeInterface } from '../../models/interfaces/practice.interface';
import { PetInterface } from '../../../pet-shared/models/interfaces/pet.interface';
/**
 * Client Details Component - For displaying and editing client details (contact info + pets)
 */
@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  providers: [ ConfirmationService ]
})
export class ClientDetailsComponent implements OnInit {

  @Input() clientDetails: any;
  readOnly: boolean;
  showAddresses: boolean;
  showPets: boolean;
  editingPhone: boolean;
  editingFullName: boolean;
  updatingPhone: boolean;
  updatingAddress: boolean;
  updatingFullName: boolean;
  editingAddress: any;
  addressIndex: number;
  addressForm: FormGroup;
  availablePetSpecies: Array<string> = ['cat', 'dog'];
  availablePetGenders: Array<string> = ['male', 'female'];
  availablePetBreeds: { cat: Array<any>, dog: Array<any> };
  availablePractices: Array<PracticeInterface>;
  activePetBreeds: Array<any>;
  editingPet: any;
  petIndex: number;
  petForm: FormGroup;
  phoneNumberForm: FormGroup;
  fullNameForm: FormGroup;
  subscriptions: Subscription = new Subscription();
  /**
   * @param addressService - Service for managing client addresses
   * @param confirmationService - Service for interacting with the Prime Confirmation Popup
   * @param errorHandlerService - Service for handling errors
   * @param usersService - Service for interacting with user details (in this case phone number setting)
   * @param petService - Service for interacting with pet details
   * @param practiceService - Service for getting practice details
   * @param alertService - Service for displaying alerts
   */
  constructor(private addressService: AddressService,
              private confirmationService: ConfirmationService,
              private errorHandlerService: ErrorHandlerService,
              private usersService: UsersService,
              private petService: PetService,
              private practiceService: PracticeService,
              private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.getPetBreeds();
    this.getPractices();
  }
  /**
   * getPetBreeds function - Gets available Pet Breeds from API (set availablePetBreeds with cat/dog breeds)
   */
  getPetBreeds() {
    this.subscriptions.add(this.petService.getPetBreeds().subscribe(data => {
      this.availablePetBreeds = data;
    }, data => {
      this.errorHandlerService.handleError(data?.error ? data?.error : 'Problem Getting Pet Breeds');
    }));
  }
  /**
   * getPractices function - Gets all practices to set availablePractices
   */
  getPractices() {
    this.subscriptions.add(
      this.practiceService.getAllPractices().subscribe(result => {
        this.availablePractices = result;
      }, data => {
        this.errorHandlerService.handleError(data?.error ? data?.error : 'Problem Getting Available Practices');
      })
    );
  }
  /**
   * handlePetSpecies function - Sets activePetBreeds based on param (either dog/cat) -
   * flattens array to name strings (as API only returns name)
   * @param event - Event details
   * @param reset - Whether reset is needed
   */
  handlePetSpecies(event, reset = false) {
    const petBreeds = [];
    if (event.value === 'dog') {
      this.availablePetBreeds.dog.forEach(item => {
        petBreeds.push(item);
      });
    } else {
      this.availablePetBreeds.cat.forEach(item => {
        petBreeds.push(item);
      });
    }
    this.activePetBreeds = petBreeds;
    if (reset) {
      this.petForm.controls.breeds.reset();
    }
  }
  /**
   * toggleAddresses function - Sets showAddresses to its opposite
   */
  toggleAddresses() {
    this.showAddresses = !this.showAddresses;
  }
  /**
   * togglePets function - Sets showPets to its opposite
   */
  togglePets() {
    this.showPets = !this.showPets;
  }
  /**
   * stopEditingPhone function - Sets phoneNumberForm (to null) and editingPhone (to false) to stop editing form in UI
   */
  stopEditingPhone() {
    this.phoneNumberForm = null;
    this.editingPhone = false;
  }
  /**
   * stopEditingFullName function - Sets fulNameForm (to null) and editingFullName (to false) to stop editing form in UI
   */
  stopEditingFullName() {
    this.fullNameForm = null;
    this.editingFullName = false;
  }
  /**
   * editPhone function - Creates phoneNumberForm and sets editingPhone to true to display form in UI
   */
  editPhone() {
    this.phoneNumberForm = new FormGroup({
      phone: new FormControl(this.clientDetails.profile.phone, Validators.required)
    });
    this.editingPhone = true;
  }
  /**
   * editFullName function - Creates fullNameForm and sets editingFullName to true to display form in UI
   */
  editFullName() {
    this.fullNameForm = new FormGroup({
      full_name: new FormControl(this.clientDetails.full_name, Validators.required)
    });
    this.editingFullName = true;
  }
  /**
   * editAddress function - Sets editingAddress flag on address input then sets addressIndex and Editing Address
   * before calling setupAddressForm with address param
   * @param address - Client Address
   * @param index - Index from within an addresses array
   */
  editAddress(address, index) {
    // Reset other addresses if editing a different address
    if (index !== this.addressIndex) {
      this.editingAddress = null;
      this.addressForm = null;
      this.clientDetails.addresses.forEach(item => {
        item.editingAddress = false;
      });
    }
    if (!address.editingAddress) {
      address.editingAddress = false;
    }
    address.editingAddress = !address.editingAddress;
    if (address.editingAddress) {
      this.addressIndex = index;
      this.editingAddress = address;
      this.setupAddressForm(address);
    } else {
      this.addressIndex = null;
      this.editingAddress = null;
      this.addressForm = null;
    }
  }
  /**
   * editPetDetails function - Sets editingPet flag on pet and sets petIndex for reference before calling
   * setupPetForm with pet param
   * @param pet - Pet Details
   * @param index - Index of pets within pets array
   */
  editPetDetails(pet, index) {
    // Reset other pets if editing a different pet
    if (index !== this.petIndex) {
      this.editingPet = null;
      this.petForm = null;
      this.clientDetails.pets.forEach(item => {
        item.editingDetails = false;
      });
      this.clientDetails.pets = filter(this.clientDetails.pets, x => !isNil(x?.id));
    }
    if (!pet.editingDetails) {
      pet.editingDetails = false;
    }
    pet.editingDetails = !pet.editingDetails;
    if (pet.editingDetails) {
      this.petIndex = index;
      this.editingPet = pet;
      this.setupPetForm(pet);
    } else {
      this.petIndex = null;
      this.editingPet = null;
      this.petForm = null;
      // Remove new pet
      this.clientDetails.pets = filter(this.clientDetails.pets, x => !isNil(x?.id));
    }
  }
  /**
   * setupPetForm function - Creates petForm as a new FormGroup
   * @param pet - Pet Details
   */
  setupPetForm(pet) {
    const parsedAge = this.formatPetAge(pet);
    const practice = find(this.availablePractices, x => {
      if (!pet.pet_practice || !pet.pet_practice.id) {
        return false;
      }
      return x.id === pet.pet_practice.id;
    });
    this.petForm = new FormGroup({
      id: new FormControl(pet.id ? pet.id : null),
      name: new FormControl({value: pet.name ? pet.name : '', disabled: pet.insurance?.length > 0}, Validators.required),
      species: new FormControl({value: pet.species ? pet.species : null, disabled: pet.insurance?.length > 0}, Validators.required),
      gender: new FormControl({value: pet.gender ? pet.gender : '', disabled: pet.insurance?.length > 0}, Validators.required),
      age: new FormControl(pet.age ? pet.age : 0),
      age_years: new FormControl({value: parsedAge.years ? parsedAge.years : 0, disabled: pet.insurance?.length > 0}, Validators.required),
      age_months: new FormControl({value: parsedAge.month ? parsedAge.month : 0, disabled: pet.insurance?.length > 0}, Validators.required),
      weight: new FormControl(pet.weight ? pet.weight : 0),
      breeds: new FormControl({value: pet.breeds ? pet.breeds : [], disabled: pet.insurance?.length > 0}, Validators.required),
      neutered: new FormControl(pet.neutered),
      practice: new FormControl(practice ? practice : null),
      microchip_number: new FormControl(pet.microchip_number ? pet.microchip_number : null)
    });
    this.handlePetSpecies({value: this.petForm.value.species});
  }
  /**
   * updatePetAge function - Sets age control in petForm by calculating year/months inputs
   * @param event - Input event (not used)
   * @param type - year/month options
   */
  updatePetAge(event, type: 'year' | 'month') {
    const years = type === 'year' ? event.value : this.petForm.value.age_years;
    const months = type === 'month' ? event.value : this.petForm.value.age_months;
    this.petForm.controls.age.setValue((years * 12) + months);
  }
  /**
   * formatPetAge function - Returns pet age as years/months
   * @param pet - Pet details
   */
  formatPetAge(pet) {
    // Age in months
    const petAge = Math.floor(pet.age / 12);
    const remainder = pet.age % 12;
    const monthCheck = Math.floor(remainder);
    return {
      years: petAge,
      month: monthCheck
    };
  }
  /**
   * Calculate a pet's age in weeks based on years and months.
   *
   * @param years - The number of years.
   * @param months - The number of months.
   * @returns weeks - The pet's age in weeks (rounded to the nearest whole number).
   */
  calculatePetAgeInWeeks(years: number, months: number): number {
    const totalMonths = years * 12 + months;
    return round(totalMonths * 4.345); // Assuming an average of 4.33 weeks a month.
  }
  /**
   * setupAddressForm function - Creates addressForm as a new FormGroup
   * @param address - Client Address
   */
  setupAddressForm(address) {
    this.addressForm = new FormGroup({
      id: new FormControl(address.id),
      house_number: new FormControl(address.house_number),
      street: new FormControl(address.street, Validators.required),
      city: new FormControl(address.city, Validators.required),
      county: new FormControl(address.county),
      postcode: new FormControl(address.postcode, Validators.required),
      country: new FormControl(address.country, Validators.required),
      user_id: new  FormControl(this.clientDetails.id),
      default: new FormControl(address.default)
    });
  }
  /**
   * savePhoneNumber function - Calls usersService.setClientPhoneNumber with phoneNumberForm details
   * and clientID to set clients phone number
   */
  savePhoneNumber() {
    if (!this.updatingPhone) {
      this.updatingPhone = true;
      this.subscriptions.add(this.usersService.setClientPhoneNumber(this.phoneNumberForm.value,
        this.clientDetails.id).subscribe(data => {
          this.clientDetails.profile.phone = this.phoneNumberForm.value.phone;
          this.updatingPhone = false;
          this.stopEditingPhone();
      }, data => {
        this.updatingPhone = false;
        this.errorHandlerService.handleError(data?.error ? data?.error : 'Problem Updating Client Phone Number');
      }));
    }
  }
  /**
   * saveAddress function - Calls either addressService.updateAddress or addressService.createNewUserAddress
   * (depending on whether an address has id)
   */
  saveAddress() {
    if (!this.updatingAddress) {
      this.updatingAddress = true;
      if (this.addressForm.value.id) {
        this.subscriptions.add(this.addressService.updateAddress(this.addressForm.value).subscribe(data => {
          this.updatingAddress = false;
          this.clientDetails.addresses.splice(this.addressIndex, 1, data.address);
          this.editingAddress.editingAddress = false;
          this.editingAddress = null;
        }, data => {
          this.updatingAddress = false;
          this.errorHandlerService.handleError(data?.error ? data?.error : 'Problem Updating Client Address');
        }));
      } else {
        this.subscriptions.add(this.addressService.createNewUserAddress(this.addressForm.value).subscribe(data => {
          this.updatingAddress = false;
          this.clientDetails.addresses.splice(this.addressIndex, 1, data.address);
          this.editingAddress.editingAddress = false;
          this.editingAddress = null;
        }, data => {
          this.updatingAddress = false;
          this.errorHandlerService.handleError(data?.error ? data?.error : 'Problem Creating Client Address');
        }));
      }
    }
  }
  /**
   * saveFullName function - Calls usersService.updateClientFullName to update clients full_name
   */
  saveFullName() {
    if (!this.updatingFullName) {
      this.updatingFullName = true;
      this.subscriptions.add(this.usersService.updateClientFullName(this.fullNameForm.value,
        this.clientDetails.id).subscribe(data => {
        this.clientDetails.full_name = this.fullNameForm.value.full_name;
        this.updatingFullName = false;
        this.editingFullName = false;
      }, data => {
        this.updatingFullName = false;
        this.errorHandlerService.handleError(data?.error ? data?.error : 'Problem Updating Client Full Name');
      }));
    }
  }
  /**
   * addAddress function - Pushes a blank address to clientDetails.addresses and sets addressIndex
   * and editingAddress before calling setupAddressForm
   */
  addAddress() {
    if (!this.editingAddress) {
      const newAddress = {
        house_number: null,
        street: null,
        city: null,
        county: null,
        postcode: null,
        country: null,
        user_id: this.clientDetails.id,
        default: false,
        editingAddress: true
      };
      if (!this.clientDetails.addresses) {
        this.clientDetails.addresses = [];
      }
      this.clientDetails.addresses.push(newAddress);
      this.addressIndex = this.clientDetails.addresses.length - 1;
      this.editingAddress = newAddress;
      this.setupAddressForm(newAddress);
    }
  }
  /**
   * addPet function - Pushes a blank pet to clientDetails.pets and sets petIndex
   * and editingPet before calling setupPetForm
   */
  addPet() {
    if (!this.editingPet) {
      const newPet = {
        id: null,
        name: '',
        species: null,
        gender: null,
        age: null,
        breeds: [],
        neutered: null,
        editingDetails: true,
        microchip_number: null
      };
      if (!this.clientDetails.pets) {
        this.clientDetails.pets = [];
      }
      this.clientDetails.pets.push(newPet);
      this.petIndex = this.clientDetails.pets.length - 1;
      this.editingPet = newPet;
      this.setupPetForm(newPet);
    }
  }
  /**
   * savePet function - Save new pet to existing client
   */
  savePet(): void {
    const petDetails = this.petForm.value;
    const practiceId = petDetails.practice && petDetails.practice.hasOwnProperty('id') ? petDetails.practice.id : null;
    const payload = {
      user: {
        id: this.clientDetails.id
      },
      pets: [
        omitBy({
          id: petDetails.id || null,
          breeds: petDetails.breeds,
          age: this.calculatePetAgeInWeeks(petDetails.age_years, petDetails.age_months),
          gender: petDetails.gender,
          name: petDetails.name,
          species: petDetails.species,
          weight: petDetails.weight,
          neutered: petDetails.neutered || false,
          microchip_number: petDetails.microchip_number
        }, isNil)
      ]
    };

    const request = isNil(petDetails.id) ?
      this.usersService.addClientPet(payload, practiceId) :
      this.usersService.updateClientPet(payload, practiceId);

    this.subscriptions.add(
      request.subscribe((result) => {
        this.alertService.showAlert(`Pet ${petDetails.name} saved successfully!`, 'success');
        this.editingPet = false;
        this.handlePetUpdate(result);
      })
    );
  }
  handlePetUpdate(result: { pets: PetInterface[]}) {
    // Convert this.clientDetails.pets to an object with 'id' as the key
    const clientPetsObject = keyBy(this.clientDetails.pets.filter(item => !isNil(item.id)), 'id');
    // Convert filteredResultPets to an object with 'id' as the key
    const resultPetsObject = keyBy(result.pets, 'id');
    // Merge the two objects, giving priority to resultPetsObject
    const mergedPetsObject = {...clientPetsObject, ...resultPetsObject};
    // Convert the merged object back to an array
    this.clientDetails.pets = chain(mergedPetsObject)
      .values()
      .value();
  }
  /**
   * confirmDelete function - Either opens a confirmation popup or splices from the clientDetails.addresses array
   * (depending if an address has an id)
   * @param event - Click Event
   * @param address - Client Address
   * @param index - Index of clientDetails.addresses
   */
  confirmDelete(event: any, address, index: number) {
    this.addressIndex = index;
    if (address.id) {
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Are you sure that you want to delete this address?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.deleteAddress(address);
        }
      });
    } else {
      this.clientDetails.addresses.splice(index, 1);
      this.editingAddress = null;
      this.addressIndex = null;
    }
  }
  /**
   * deleteAddress function - Calls addressService.deleteUserAddress and splices on success
   * @param address - Client Address
   */
  deleteAddress(address) {
    this.subscriptions.add(this.addressService.deleteUserAddress(address.id, this.clientDetails.id).subscribe(data => {
      this.clientDetails.addresses.splice(this.addressIndex, 1);
    }, data => {
      this.errorHandlerService.handleError(data?.error ? data?.error : 'Problem Updating Client Address');
    }));
  }

}
