// Angular
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
// Modules
import { GeneralSharedModule } from './shared/general-shared/general-shared.module';
import { AppRoutingModule } from './app-routing.module';
//  Components
import { AppComponent } from './app.component';
// Services
import { ErrorManagerService } from './shared/general-shared/services/error-manager.service';
// Interceptors
import { TokenInterceptor } from './auth/interceptors/token.interceptor';
import { InvalidRequestInterceptor } from './auth/interceptors/invalid-request.interceptor';
// Environment
import { environment } from '../environments/environment';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    GeneralSharedModule,
    ToastModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: InvalidRequestInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorManagerService },
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
