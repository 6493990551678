import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

import { ApiService } from '../../general-shared/services/api.service';
import { PatientNoteInterface } from '../models/interfaces/patient-note.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatientNotesService {

  constructor(private apiService: ApiService) { }

  getPetPatientNotes(petID) {
    return this.apiService.getData(environment.apiPrefix + '/v1/patient-notes/pets/' + petID);
  }

  getConsultationPatientNote(consultationId: number) {
    return this.apiService.getData(environment.apiPrefix + '/v2/patient-notes/consultations/' + consultationId);
  }

  createPatientNotes(patientNotes: PatientNoteInterface) {
    return this.apiService.postData(`${environment.apiPrefix}/v1/patient-notes`, patientNotes);
  }

  createPatientNotesV2(patientNotes: PatientNoteInterface) {
    return this.apiService.postData(`${environment.apiPrefix}/v2/patient-notes`, patientNotes);
  }

  updatePatientNotes(patientNoteID: number, patientNotes: PatientNoteInterface) {
    return this.apiService.patchData(`${environment.apiPrefix}/v1/patient-notes/${patientNoteID}`, patientNotes);
  }


  sendPatientNote(patientNoteID: number): Observable<any> {
    return this.apiService.patchData(`${environment.apiPrefix}/v1/patient-notes/${patientNoteID}/send`, null);
  }

  createSession(consultationId: number) {
    return this.apiService.putData(
      `${environment.apiPrefix}/v1/consultations/${consultationId}/session`,
      null
    );
  }

  closeSession(consultationId: number, sessionId: number) {
    return this.apiService.deleteData(
      `${environment.apiPrefix}/v1/consultations/${consultationId}/session/${sessionId}`
    );
  }
}
