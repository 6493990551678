import {Component, Input, OnInit} from '@angular/core';
import {SpeciesType} from '../../../pet-shared/models/types/species.type';
import { InsuranceEnum } from '../../../pet-shared/models/enums/insurance.enum';

@Component({
  selector: 'app-pet-profile-image',
  templateUrl: './pet-profile-image.component.html'
})
export class PetProfileImageComponent implements OnInit {

  @Input() public src;
  @Input() public species: SpeciesType;
  @Input() public insurance: InsuranceEnum;
  @Input() public isQueue = true;
  @Input() public maxSize: string; // Value including px

  insuranceEnum = InsuranceEnum;

  constructor() { }

  ngOnInit() {
  }

}
