import {
  Component,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MaintenanceInterface } from '../../models/interfaces/maintenance.interface';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { LocalStorageService } from '../../services/local-storage.service';
import { MessagingService } from '../../services/messaging.service';
import { MessageInterface } from '../../models/interfaces/message.interface';
import { MessageTypeEnum } from '../../models/enums/message-type.enum';
import { MessageStatusTypeEnum } from '../../models/enums/message-status-type.enum';
import { format } from 'date-fns';
import { isPlainObject } from 'lodash';

@Component({
  selector: 'app-maintenance-holder',
  templateUrl: './maintenance-holder.component.html'
})
export class MaintenanceHolderComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('Maintenance', {static: true}) maintenanceDialog: TemplateRef<any>;
  @Input() maintenance: MaintenanceInterface;
  @Input() isActiveConsultation: boolean;
  @Output() maintenanceDialogActive: EventEmitter<boolean> = new EventEmitter<boolean>();
  maintenanceDialogRef: MatDialogRef<any>;
  maintenanceAnnouncement: MessageInterface;
  private subscription: Subscription = new Subscription();

  constructor(
    private readonly dialog: MatDialog,
    private readonly localStorageService: LocalStorageService,
    private readonly messagingService: MessagingService
  ) { }

  ngOnInit(): void {
    if (this.maintenance) {
      this.handleMaintenance();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.maintenance && !changes.maintenance.isFirstChange()) {
      this.handleMaintenance();
    }
  }

  ngOnDestroy() {
    this.messagingService.clearMessage(this.maintenanceAnnouncement, true);
  }

  handleMaintenance() {
    if (this.isActiveConsultation) {
      this.handleActiveConsult();
      return false;
    }
    switch (this.maintenance.status) {
      case 'scheduled' : {
        if (this.maintenanceDialogRef) {
          this.dismissDialog();
        }
        if (this.maintenanceAnnouncement) {
            this.maintenanceAnnouncement.message =
              `The PMS will enter Maintenance on ${format(new Date(this.maintenance.start), `do MMMM yyyy 'at' p`)}`;
            return false;
          }
        this.maintenanceAnnouncement = {
            messageType: MessageTypeEnum.banner,
            statusType: MessageStatusTypeEnum.announcement,
            message: `The PMS will enter Maintenance on ${format(new Date(this.maintenance.start), `do MMMM yyyy 'at' p`)}`
          };
        this.messagingService.addNoticeMessage(this.maintenanceAnnouncement);
        break;
      }
      case 'pending' : {
        const dismissed = this.localStorageService.getData('maintenance-pending-dismissed');
        const updating = isPlainObject(this.maintenanceAnnouncement);
        if (updating) {
          this.maintenanceAnnouncement.message =
            `The PMS will enter Maintenance at ${format(new Date(this.maintenance.start), `p`)}.`;
        }
        if (!updating) {
          this.maintenanceAnnouncement = {
            messageType: MessageTypeEnum.banner,
            statusType: MessageStatusTypeEnum.announcement,
            message: `The PMS will enter Maintenance at ${format(new Date(this.maintenance.start), `p`)}.`
          };
          this.messagingService.addNoticeMessage(this.maintenanceAnnouncement);
        }
        if (!this.maintenanceDialogRef && !dismissed) {
          this.maintenanceDialogRef = this.dialog.open(
            this.maintenanceDialog,
            {
              disableClose: false,
              minWidth: 'calc(464px + 1rem)',
              minHeight: 'calc(286px + 1rem)'
            }
          );
          this.maintenanceDialogActive.emit(true);
          this.localStorageService.setData('maintenance-pending-dismissed', false);
          this.clearDialogReferenceOnClose(this.maintenanceDialogRef);
        }
        break;
      }
      case 'imminent' : {
        if (!this.maintenanceDialogRef) {
          this.maintenanceDialogRef = this.dialog.open(
            this.maintenanceDialog,
            {
              disableClose: true,
              minWidth: 'calc(464px + 1rem)',
              minHeight: 'calc(286px + 1rem)'
            }
          );
          this.maintenanceDialogActive.emit(true);
          this.clearDialogReferenceOnClose(this.maintenanceDialogRef);
        }
        break;
      }
      case 'active' : {
        if (!this.maintenanceDialogRef) {
          this.maintenanceDialogRef = this.dialog.open(
            this.maintenanceDialog,
            {
              disableClose: true,
              minWidth: 'calc(464px + 1rem)',
              minHeight: 'calc(286px + 1rem)'
            }
          );
          this.maintenanceDialogActive.emit(true);
          this.clearDialogReferenceOnClose(this.maintenanceDialogRef);
        }
        break;
      }
      case 'none' : {
        if (this.maintenanceDialogRef) {
          this.dismissDialog();
        }
        this.localStorageService.removeData('maintenance-pending-dismissed');
        this.maintenanceDialogActive.emit(false);
        if (this.maintenanceAnnouncement) {
          this.messagingService.clearMessage(this.maintenanceAnnouncement);
        }
        window.location.reload();
      }
    }
  }

  handleActiveConsult() {
    this.dismissDialog();
    this.maintenanceDialogActive.emit(false);
    const updating = isPlainObject(this.maintenanceAnnouncement);
    switch (this.maintenance.status) {
      case 'scheduled' : {
        if (updating) {
          this.maintenanceAnnouncement.message =
            `The PMS will enter Maintenance on ${format(new Date(this.maintenance.start), `do MMMM yyyy 'at' p`)}.`;
          return false;
        }
        this.maintenanceAnnouncement = {
          messageType: MessageTypeEnum.banner,
          statusType: MessageStatusTypeEnum.announcement,
          message: `The PMS will enter Maintenance on ${format(new Date(this.maintenance.start), `do MMMM yyyy 'at' p`)}.`
        };
        break;
      }
      case 'pending' : {
        if (updating) {
          this.maintenanceAnnouncement.message =
            `The PMS will enter Maintenance at ${format(new Date(this.maintenance.start), `p`)}.`;
          return false;
        }
        this.maintenanceAnnouncement = {
          messageType: MessageTypeEnum.banner,
          statusType: MessageStatusTypeEnum.announcement,
          message: `The PMS will enter Maintenance at ${format(new Date(this.maintenance.start), `p`)}.`
        };
        break;
      }
      case 'imminent' : {
        if (updating) {
          this.maintenanceAnnouncement.message =
            `The PMS will be entering Maintenance shortly. Please finish your consultation as normal. You will be unable to pick up new calls until Maintenance is over.`;
          return false;
        }
        this.maintenanceAnnouncement = {
          messageType: MessageTypeEnum.banner,
          statusType: MessageStatusTypeEnum.announcement,
          message: `The PMS will be entering Maintenance shortly. Please finish your consultation as normal. You will be unable to pick up new calls until Maintenance is over.`
        };
        break;
      }
      case 'active' : {
        if (updating) {
          this.maintenanceAnnouncement.message =
            `The PMS will be entering Maintenance shortly. Please finish your consultation as normal. You will be unable to pick up new calls until Maintenance is over.`;
          return false;
        }
        this.maintenanceAnnouncement = {
          messageType: MessageTypeEnum.banner,
          statusType: MessageStatusTypeEnum.announcement,
          message: `The PMS will be entering Maintenance shortly. Please finish your consultation as normal. You will be unable to pick up new calls until Maintenance is over.`
        };
        break;
      }

      case 'none' : {
        if (updating) {
          this.messagingService.clearMessage(this.maintenanceAnnouncement, true);
          this.maintenanceAnnouncement = null;
          this.messagingService.addMessage({
            messageType: MessageTypeEnum.toast,
            statusType: MessageStatusTypeEnum.info,
            message: `Maintenance has ended. Please refresh when possible.`,
            duration: 5000
          });
        }
        break;
      }
    }
    this.messagingService.addNoticeMessage(this.maintenanceAnnouncement);
  }

  clearDialogReferenceOnClose(reference: MatDialogRef<any, any>) {
    this.subscription.add(
      reference.afterClosed().subscribe(() => {
        this.maintenanceDialogActive.emit(false);
        this.maintenanceDialogRef = null;
        if (this.maintenance.status === 'pending') {
          this.localStorageService.setData('maintenance-pending-dismissed', true);
        }
      })
    );
  }

  dismissDialog() {
    this.dialog.closeAll();
    this.maintenanceDialogRef = null;
  }

}
