import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { PaymentIntentConfigurationInterface } from '../models/interfaces/payment-intent-configuration.interface';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { PaymentIntentInterface } from '../models/interfaces/payment-intent.interface';
import { PaymentConfigurationInterface } from '../models/interfaces/payment-configuration.interface';
import { PaymentStatusInterface } from '../models/interfaces/payment-status.interface';
import { PaymentTerminalsInterface } from '../models/interfaces/payment-terminals.interface';
/**
 * Payments service
 * Handle interactions with Stripe POS device
 */
@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  /**
   * constructor function
   * @param apiService - Handle interactions with API payment endpoints
   */
  constructor(
    private readonly apiService: ApiService
  ) { }
  /**
   * createNewPaymentIntent function - Create new Stripe payment intent on the API.
   * @param paymentIntentConfiguration - details about the transaction such as the payment methods, the amount to collect and the currency.
   */
  public createNewPaymentIntent(paymentIntentConfiguration: PaymentIntentConfigurationInterface): Observable<PaymentIntentInterface> {
    return this.apiService.postData(
      `${environment.apiPrefix}/v1/orders/payments/intent`, paymentIntentConfiguration
    ) as Observable<PaymentIntentInterface>;
  }
  /**
   * getPaymentIntent function - Check the status of a payment intent. This will return status: succeeded if a payment has been captured.
   * This should be used to manually check the websocket event has not been emitted.
   * @param intentId - The payment intent for the current transaction
   */
  getPaymentIntent(intentId: string): Observable<PaymentIntentInterface> {
    return this.apiService.getData(`${environment.apiPrefix}/v1/orders/payments/intent/${intentId}`);
  }
  /**
   * startPayment function - Create new Stripe payment intent on the API.
   * @param paymentConfiguration - details on the transaction, card reader, amount, payment intent, user making the payment & a description
   * to display on stripe dashboards.
   */
 public startPayment(paymentConfiguration: PaymentConfigurationInterface): Observable<PaymentStatusInterface> {
    return this.apiService.postData(
      `${environment.apiPrefix}/v1/orders/payments/practice/confirm`, paymentConfiguration
    ) as Observable<PaymentStatusInterface>;
 }
  /**
   * cancelTerminalPayment function - Cancel payment for a terminal id on the API.
   * This can only be completed if the reader is not processing or finished the payment.
   * @param readerId - The id of the card reader processing a payment.
   */
 public cancelTerminalPayment(readerId: string) {
   return this.apiService.deleteData(
     `${environment.apiPrefix}/v1/orders/payments/cancel/${readerId}`
   );
 }
  /**
   * getTerminals function -  Return a list of active POS Terminals for a practice ID from the API
   * @param practiceId - The practice to get terminals for
   */
 public getTerminals(practiceId: number): Observable<PaymentTerminalsInterface> {
   return this.apiService.getData(
     `${environment.apiPrefix}/v1/practices/${practiceId}/terminals`
   );
 }
}
