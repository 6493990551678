import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MedicationService {

  constructor(
    private readonly apiService: ApiService
  ) { }

  getMedicationById(ID: number) {
    return this.apiService.getData(`${environment.apiPrefix}/v1/patient-notes/medication/${ID}/recommended`);
  }
}
