import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-gallery',
  templateUrl: './simple-gallery.component.html'
})
export class SimpleGalleryComponent implements OnInit {

  @Input() images: Array<string>;

  selectedIndex = 0;

  constructor() { }

  ngOnInit(): void {
  }

  onNext() {
    // If last image go to the first image.
    if (this.selectedIndex === this.images.length - 1) {
      this.selectedIndex = 0;
    } else {
      this.selectedIndex++;
    }
  }

  onPrevious() {
    // If first image go to the last image.
    if (this.selectedIndex === 0) {
      this.selectedIndex = this.images.length - 1;
    } else {
      this.selectedIndex--;
    }
  }

}
