import { Pipe, PipeTransform } from '@angular/core';
import { sumBy, round } from 'lodash';
import { BasketService } from '../services/basket.service';

/**
 * Angular Pipe for calculating the total of items in a basket in pounds and pence.
 */
@Pipe({
  name: 'pendingBasketTotal',
  pure: false
})
export class PendingBasketTotalPipe implements PipeTransform {
  /**
   * Transforms an array of items into the total based on the specific logic.
   * @param items The array of items to calculate the total for.
   * @returns The total of items in pounds and pence (formatted as "£X.YY").
   */
  constructor(private readonly basketService: BasketService) {}
  transform(items: any[]): string {
    if (!items) {
      return '£0.00';
    }
    const totalPence = round(sumBy(items, item => this.basketService.calculateTotalForItemInPence(item)), 0);
    const pounds = Math.floor(totalPence / 100);
    const pence = totalPence % 100;

    return `£${pounds}.${pence.toString().padStart(2, '0')}`;
  }
}
