<header class="search-bar">
  <form (ngSubmit)="searchItems()">
    <div *ngIf="searchOptions" class="options-holder">
      <div *ngIf="hasPreOptions" class="search-options-pre">
        <div *ngFor="let option of searchOptions">
          <mat-form-field
            appearance="legacy"
            floatLabel="never"
            *ngIf="option.type === 'select' && option.location === 'pre'">
            <mat-select [(ngModel)]="preOptionDetails" name="search-option">
              <mat-option
                *ngFor="let type of option.options"
                [value]="type">{{type}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="hasPostOptions" class="search-options-post {{searchTerm || (postOptionDetails.startDate && postOptionDetails.endDate) ? 'clear-btn' : ''}}">
        <div *ngFor="let option of searchOptions">
          <mat-form-field
            appearance="legacy"
            floatLabel="never"
            *ngIf="option.type === 'date-range' && option.location === 'post' && postOptionDetails">
            <mat-date-range-input [rangePicker]="picker" [comparisonStart]="postOptionDetails.startDate" [comparisonEnd]="postOptionDetails.endDate">
              <input matStartDate placeholder="Start date" [(ngModel)]="postOptionDetails.startDate" name="start-date">
              <input matEndDate placeholder="End date" [(ngModel)]="postOptionDetails.endDate" name="end-date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
      </div>
    </div>
    <mat-form-field
      appearance="legacy"
      floatLabel="never"
      [class.has-pre-options]="hasPreOptions">
      <input name="search" matInput [(ngModel)]="searchTerm" [placeholder]="'Search ' + sectionLabel">
      <span class="clear-btn" *ngIf="searchTerm || (postOptionDetails?.startDate && postOptionDetails?.endDate)" (click)="clearSearch()"><mat-icon>clear</mat-icon></span>
    </mat-form-field>
    <button mat-flat-button color="primary" type="submit" [disabled]="!searchTerm && (!postOptionDetails?.startDate && !postOptionDetails?.endDate)">Search</button>
  </form>
</header>
