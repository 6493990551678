<div class="image-container">
  <img
    *ngIf="src"
    [src]="src"
    class="pet-image"
    onError="src='https://storage.googleapis.com/assets.vet-ai.com/0a17d9f8-e7cf-4198-b7be-8c214b660272.png'"
    [style.--pet-profile-max-size]="maxSize ? maxSize : 'unset'"
    >
  <img
    *ngIf="!src && species === 'dog'"
    class="pet-image"
    src="https://storage.googleapis.com/assets.vet-ai.com/f26b39db-0830-4909-a793-25aff9cd4670.png"
    [style.--pet-profile-max-size]="maxSize ? maxSize : 'unset'"
  >
  <img
    *ngIf="!src && species === 'cat'"
    class="pet-image"
    src="https://storage.googleapis.com/assets.vet-ai.com/fcf24e68-8e5f-40ad-9220-6854c323e08a.png"
    [style.--pet-profile-max-size]="maxSize ? maxSize : 'unset'"
  >
  <ng-container *ngIf="insurance === insuranceEnum.afi">
    <img src="../../../../../assets/logos/afi_logo.svg" class="insurance afi {{isQueue ? 'queue-img' : ''}}" matTooltip="Animal Friends Insurance">
  </ng-container>
  <ng-container *ngIf="insurance === insuranceEnum.asda">
    <img src="../../../../../assets/logos/ASDA.svg" class="insurance asda {{isQueue ? 'queue-img' : ''}}" matTooltip="ASDA Insurance">
  </ng-container>
  <ng-container *ngIf="insurance === insuranceEnum.purelypets">
    <img src="../../../../../assets/logos/Purely1.png" class="insurance purely-pets {{isQueue ? 'queue-img' : ''}}" matTooltip="Purely Pets Insurance">
  </ng-container>
  <ng-container *ngIf="insurance === insuranceEnum.waggel">
    <img src="../../../../../assets/logos/Waggel.svg" class="insurance waggel {{isQueue ? 'queue-img' : ''}}" matTooltip="Waggel Insurance">
  </ng-container>
  <div class="overlayed">
    <ng-content>

    </ng-content>
  </div>
</div>
