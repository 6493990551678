<section *ngIf="!manageClientAddresses">
  <div *ngFor="let address of clientAddresses" class="field-checkbox text-left full-width">
    <p-radioButton
      [value]="address"
      [inputId]="address.viewValue"
      [(ngModel)]="selectedAddress"
      [ngModelOptions]="{standalone: true}"></p-radioButton>
    <label [for]="address.viewValue" class="ml-2">&nbsp;{{address.viewValue}}</label>
  </div>
  <div class="empty-container" *ngIf="clientAddresses?.length === 0">
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28" viewBox="0 0 27 28" fill="none">
      <g id="Group">
        <g id="Layer_90">
          <path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M26.8334 23.3332V4.6665C26.8334 2.45736 25.0426 0.666504 22.8334 0.666504H4.16675C1.95761 0.666504 0.166748 2.45736 0.166748 4.6665V23.3332C0.166748 25.5423 1.95761 27.3332 4.16675 27.3332H22.8334C25.0426 27.3332 26.8334 25.5423 26.8334 23.3332ZM12.159 7.33317C12.159 7.68869 12.301 8.02948 12.5534 8.27984C12.9349 8.66445 13.5111 8.78005 14.0114 8.57233C14.5117 8.36461 14.8366 7.87486 14.8334 7.33317C14.8366 6.79148 14.5117 6.30173 14.0114 6.09401C13.5111 5.88629 12.9349 6.00189 12.5534 6.3865C12.301 6.63686 12.159 6.97765 12.159 7.33317ZM24.1667 4.6665C24.1667 3.93012 23.5698 3.33317 22.8334 3.33317H4.16675C3.43037 3.33317 2.83341 3.93012 2.83341 4.6665V23.3332C2.83341 24.0695 3.43037 24.6665 4.16675 24.6665H22.8334C23.5698 24.6665 24.1667 24.0695 24.1667 23.3332V4.6665ZM14.8334 19.3332H15.0201C15.7565 19.3332 16.3534 19.9301 16.3534 20.6665C16.3534 21.4029 15.7565 21.9998 15.0201 21.9998H11.9801C11.2437 21.9998 10.6467 21.4029 10.6467 20.6665C10.6467 19.9301 11.2437 19.3332 11.9801 19.3332H12.1667V12.6665C11.4304 12.6665 10.8334 12.0695 10.8334 11.3332C10.8334 10.5968 11.4304 9.99984 12.1667 9.99984H13.5001C14.2365 9.99984 14.8334 10.5968 14.8334 11.3332V19.3332Z" fill="#EB6B0D"/>
        </g>
      </g>
    </svg>
    <span>We couldn't find any addresses! Please go ahead and create a new one to proceed.</span>
  </div>
  <div class="actions-container">
    <button
      *ngIf="clientAddresses?.length > 0"
      pButton
      pRipple
      type="button"
      class="p-button-secondary"
      [loading]="!clientAddresses"
      [label]="'Manage Addresses'"
      data-joii-consultation-address-manage-btn
      (click)="manageAddresses()"></button>
    <button
      *ngIf="clientAddresses?.length === 0"
      pButton
      pRipple
      type="button"
      class="p-button-secondary"
      [loading]="!clientAddresses"
      [label]="'Add Address'"
      data-joii-consultation-address-manage-btn
      (click)="addAddress()"></button>
    <button
      pButton
      pRipple
      type="button"
      [loading]="!clientAddresses"
      [disabled]="!selectedAddress || !clientAddresses"
      label="Confirm Address"
      data-joii-consultation-address-confirm-btn
      (click)="confirmAddress()"></button>
  </div>
</section>
<section class="address-selection-dialog-container" *ngIf="manageClientAddresses">
  <div class="address-selection-items" *ngIf="!creatingAddress">
    <ul>
      <li *ngFor="let address of clientAddresses">
        <span>
          {{address?.house_number}} {{address?.street | titlecase}}, {{address?.city | titlecase}}, {{address?.postcode | uppercase}}
        </span>
        <span>
          <p-confirmPopup></p-confirmPopup>
          <i
            matTooltip="Delete Address"
            class="pi pi-trash"
            (click)="displayConfirmDeleteAddress($event, address, clientID)"></i>
        </span>
      </li>
    </ul>
    <div class="empty-container margin-b-md" *ngIf="clientAddresses?.length === 0">
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28" viewBox="0 0 27 28" fill="none">
        <g id="Group">
          <g id="Layer_90">
            <path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M26.8334 23.3332V4.6665C26.8334 2.45736 25.0426 0.666504 22.8334 0.666504H4.16675C1.95761 0.666504 0.166748 2.45736 0.166748 4.6665V23.3332C0.166748 25.5423 1.95761 27.3332 4.16675 27.3332H22.8334C25.0426 27.3332 26.8334 25.5423 26.8334 23.3332ZM12.159 7.33317C12.159 7.68869 12.301 8.02948 12.5534 8.27984C12.9349 8.66445 13.5111 8.78005 14.0114 8.57233C14.5117 8.36461 14.8366 7.87486 14.8334 7.33317C14.8366 6.79148 14.5117 6.30173 14.0114 6.09401C13.5111 5.88629 12.9349 6.00189 12.5534 6.3865C12.301 6.63686 12.159 6.97765 12.159 7.33317ZM24.1667 4.6665C24.1667 3.93012 23.5698 3.33317 22.8334 3.33317H4.16675C3.43037 3.33317 2.83341 3.93012 2.83341 4.6665V23.3332C2.83341 24.0695 3.43037 24.6665 4.16675 24.6665H22.8334C23.5698 24.6665 24.1667 24.0695 24.1667 23.3332V4.6665ZM14.8334 19.3332H15.0201C15.7565 19.3332 16.3534 19.9301 16.3534 20.6665C16.3534 21.4029 15.7565 21.9998 15.0201 21.9998H11.9801C11.2437 21.9998 10.6467 21.4029 10.6467 20.6665C10.6467 19.9301 11.2437 19.3332 11.9801 19.3332H12.1667V12.6665C11.4304 12.6665 10.8334 12.0695 10.8334 11.3332C10.8334 10.5968 11.4304 9.99984 12.1667 9.99984H13.5001C14.2365 9.99984 14.8334 10.5968 14.8334 11.3332V19.3332Z" fill="#EB6B0D"/>
          </g>
        </g>
      </svg>
      <span>We couldn't find any addresses! Please go ahead and create a new one to proceed.</span>
    </div>
    <div class="actions">
      <button
        pButton
        pRipple
        class="p-button-secondary"
        label="Back"
        (click)="cancelManageClientAddress()"
      ></button>
      <button
        pButton
        pRipple
        (click)="addAddress()"
        label="Add Address"
      ></button>
    </div>
  </div>
  <form
    [formGroup]="addressCreationForm"
    (ngSubmit)="saveNewAddress()"
    *ngIf="creatingAddress && addressCreationForm">
    <div class="postcode-search">
      <p-autoComplete
        formControlName="postcodeLookup"
        [suggestions]="addressCreationForm.value.searchResults || []"
        [virtualScroll]="true"
        [itemSize]="58"
        [delay]="500"
        [completeOnFocus]="true"
        placeholder="Search postcode"
        field="postcode"
        appendTo="body"
        (completeMethod)="checkPostCode(addressCreationForm.value.postcodeLookup?.postcode || addressCreationForm.value.postcodeLookup)"
        (onSelect)="setAddress($event)"
        class="user-search">
        <ng-template let-address pTemplate="item">
          <div class="user-item">
            <ng-container *ngFor="let part of address.formatted_address">
              <span *ngIf="part && part?.length > 0">{{part}}&nbsp;</span>
            </ng-container>
          </div>
        </ng-template>
      </p-autoComplete>
      <button type="button" label="Enter manually" pButton (click)="editAddressManually()"></button>
    </div>
    <div *ngIf="addressCreationForm.value.editingAddress">
      <div class="address-group-inline">
        <label>House Number*</label>
        <div class="full-width">
          <input
            pInputText
            autocomplete="off"
            class="form-control"
            formControlName="house_number">
        </div>
        <p *ngIf="addressCreationForm.controls.house_number.hasError('required') &&
        addressCreationForm.controls.house_number.touched" class="joii-text-danger">House number is required</p>
      </div>
      <div class="address-group-inline">
        <label>Street*</label>
        <div class="full-width">
          <input
            pInputText
            autocomplete="off"
            class="form-control"
            formControlName="street">
        </div>
        <p *ngIf="addressCreationForm.controls.street.hasError('required') &&
        addressCreationForm.controls.street.touched" class="joii-text-danger">Street is required</p>
      </div>
      <div class="address-group-inline">
        <label>Town / City*</label>
        <div class="full-width">
          <input
            pInputText
            autocomplete="off"
            class="form-control"
            formControlName="city">
        </div>
        <p *ngIf="addressCreationForm.controls.city.hasError('required') &&
        addressCreationForm.controls.city.touched" class="joii-text-danger">Town / City is required</p>
      </div>
      <div class="address-group-inline">
        <label>County</label>
        <div class="full-width">
          <input
            pInputText
            autocomplete="off"
            class="form-control"
            formControlName="county">
        </div>
      </div>
      <div class="address-group-inline">
        <label>Country*</label>
        <div class="full-width">
          <input
            pInputText
            autocomplete="off"
            class="form-control"
            formControlName="country">
        </div>
        <p *ngIf="addressCreationForm.controls.country.hasError('required') &&
        addressCreationForm.controls.country.touched" class="joii-text-danger">Country is required</p>
      </div>
      <div class="address-group-inline">
        <label>Postcode*</label>
        <div class="full-width">
          <input
            pInputText
            autocomplete="off"
            class="form-control"
            formControlName="postcode">
        </div>
        <p *ngIf="addressCreationForm.controls.postcode.hasError('required') &&
        addressCreationForm.controls.postcode.touched" class="joii-text-danger">Postcode is required</p>
      </div>
      <div class="address-group-inline">
        <label>Set as Default</label>
        <div class="full-width">
          <p-checkbox formControlName="default" [binary]="true"></p-checkbox>
        </div>
      </div>
    </div>
    <div class="actions">
      <button
        pButton
        pRipple
        type="button"
        class="p-button-secondary"
        (click)="cancelAddressCreation()"
        label="Back"
      ></button>
      <button
        pButton
        pRipple
        [disabled]="!addressCreationForm.valid"
        type="submit"
        label="Save Address"
      ></button>
    </div>
  </form>
</section>

