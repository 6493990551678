import { Pipe, PipeTransform } from '@angular/core';
import { AppointmentStatusEnum } from '../../calendar-shared/models/enums/appointment-status.enum';
import { ConsultationStatusType } from '../../consultation-shared/models/types/consultation-status.type';

@Pipe({
  name: 'consultationStatus'
})
export class ConsultationStatusPipe implements PipeTransform {

  transform(value: ConsultationStatusType): string {
    switch (value) {
      case 'completed' : return 'Completed';
      case 'in-review' : return 'In Review';
      case 'in-progress' : return 'In Progress';
      case 'post-review' : return 'Post Review';
      case 'terminated' : return 'Terminated';
      case 'waiting' : return 'Checked In';
      case 'pending' : return 'Handover';
    }
  }

}
