import { Component, Input, OnInit } from '@angular/core';
import { TerminalStatusEnum } from '../../models/enums/terminal-status.enum';

@Component({
  selector: 'app-payment-processing-state',
  templateUrl: './payment-processing-state.component.html'
})
export class PaymentProcessingStateComponent implements OnInit {

  @Input() status: string;
  terminalStatus = TerminalStatusEnum;

  constructor() { }

  ngOnInit(): void {
  }

}
