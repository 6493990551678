import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, merge, Subscription } from 'rxjs';
import { ErrorHandlerService } from './error-handler.service';
import { MaintenanceInterface } from '../models/interfaces/maintenance.interface';
import { isNumber } from 'lodash';
import { MessagingService } from './messaging.service';
import { MessageTypeEnum } from '../models/enums/message-type.enum';
import { MessageStatusTypeEnum } from '../models/enums/message-status-type.enum';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketsService {

  private subject: WebSocketSubject<any>;
  private callMessages: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private statusMessages: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private staffStatusMessages: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private queueMessages: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private practiceQueueMessages: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private maintenanceMessages: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private hillsMessages: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private practiceAppointments: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private paymentMessages: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private subscription: Subscription;
  private errorCount: number;
  private queueType: string;

  constructor(
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly messagingService: MessagingService,
    private readonly loggingService: LoggingService
  ) {
  }

  disconnect() {
    this.subscription.unsubscribe();
    this.subscription = undefined;
    this.subject.complete();
    this.subject = undefined;
  }

  reconnect() {
    this.subscription?.unsubscribe();
    this.subscription = undefined;
    this.subject?.complete();
    this.startMessages();
  }

  startMessages() {
    // Call this function before subscribing to message feeds to ensure the websocket connection is active.
    if (!this.subscription) {
      this.connectWebsocket();
      this.subscription = new Subscription();
      this.subscription.add(this.getWebsocket().subscribe((data: any) => {
        this.errorCount = 0;
        switch (data.type) {
          case 'queue':
            this.queueMessages.next(data);
            break;
          case 'practice-queue':
            this.practiceQueueMessages.next(data);
            break;
          case 'staff-status':
            this.staffStatusMessages.next(data);
            break;
          case 'status':
            this.statusMessages.next(data);
            break;
          case 'call-status':
            this.callMessages.next(data);
            break;
          case 'system-maintenance':
            this.maintenanceMessages.next(data);
            break;
          case 'hills-reco':
            this.hillsMessages.next(data);
            break;
          case 'appointments':
            this.practiceAppointments.next(data);
            break;
          case 'payment-intent':
            this.paymentMessages.next(data);
        }
        // @ToDo: This might need to be more nuanced? Not sure what flag though
        // this.isLoading = false;
      }, data => {
        if (data.type === 'close') {
          this.reconnect();
        } else {
          // @ToDo: This should throw a persistent error as means we are not streaming queue socket
          this.errorCount = isNumber(this.errorCount) ? this.errorCount + 1 : 0;
          this.errorHandlerService.handleError(data);
          if (this.errorCount <= 3) {
            this.loggingService.logAnalyticsError('Websocket connection error. Attempting to reconnect');
            this.loggingService.logAnalyticsError(data);
            this.reconnect();
            return false;
          } else {
            this.loggingService.logAnalyticsError('Websocket connection closed with error');
            this.loggingService.logAnalyticsError(data);
            this.messagingService.addNoticeMessage({
              message: 'Disconnected from the queue. Please check your connection and refresh to reconnect.',
              messageType: MessageTypeEnum.banner,
              statusType: MessageStatusTypeEnum.error,
            });
          }
        }
      }));
    }
  }

  getQueueMessages() {
    return this.queueMessages.asObservable();
  }

  getPracticeQueueMessages() {
    return this.practiceQueueMessages.asObservable();
  }

  getStatusMessages() {
    return this.statusMessages.asObservable();
  }

  getCallMessages() {
    return this.callMessages.asObservable();
  }

  getMaintenanceMessages() {
    return this.maintenanceMessages.asObservable();
  }

  getHillsRecoMessages() {
    return this.hillsMessages.asObservable();
  }

  getPaymentMessages() {
    return this.paymentMessages.asObservable();
  }

  getAllMessages() {
      return merge(
        this.callMessages.asObservable(),
        this.statusMessages.asObservable(),
        this.queueMessages.asObservable(),
        this.staffStatusMessages.asObservable(),
      );
  }

  getAllPracticeMessages() {
    // @ToDo: May not need all of these for practices (review once consultations work started)
    return merge(
      this.callMessages.asObservable(),
      this.statusMessages.asObservable(),
      this.practiceQueueMessages.asObservable(),
      this.staffStatusMessages.asObservable(),
      this.practiceAppointments.asObservable(),
    );
  }

  clearCallMessages() {
    this.callMessages.complete();
    this.callMessages = new BehaviorSubject<any>(null);
    this.statusMessages.complete();
    this.statusMessages = new BehaviorSubject<any>(null);
  }

  clearAllMessages() {
    this.callMessages.complete();
    this.callMessages = new BehaviorSubject<any>(null);
    this.statusMessages.complete();
    this.statusMessages = new BehaviorSubject<any>(null);
    this.queueMessages.complete();
    this.queueMessages = new BehaviorSubject<any>(null);
    this.staffStatusMessages.complete();
    this.staffStatusMessages = new BehaviorSubject<any>(null);
    this.maintenanceMessages.complete();
    this.maintenanceMessages = new BehaviorSubject<any>(null);
  }

  clearHillsMessages() {
    this.hillsMessages.complete();
    this.hillsMessages = new BehaviorSubject<any>(null);
  }

  private connectWebsocket() {
    this.subject = webSocket(`${environment.messagesPrefix}/v1/ws`);
    this.authenticateUser();
  }

  private authenticateUser() {
    const tokenLocal = localStorage.getItem('userToken');
    const userDetails = JSON.parse(localStorage.getItem('currentUser'));
    const inPracticeCheck = localStorage.getItem('inPractice');
    if (userDetails?.practice_id && inPracticeCheck) {
      if (JSON.parse(inPracticeCheck)) {
        this.queueType = 'pms-practice';
      } else {
        this.queueType = 'pms';
      }
    } else {
      this.queueType = 'pms';
    }
    this.subject.next({token: tokenLocal, type: this.queueType});
  }

  private getWebsocket() {
    return this.subject.asObservable();
  }

  sendQueueToggle() {
    const tokenLocal = localStorage.getItem('userToken');
    if (this.queueType === 'pms') {
      this.queueType = 'pms-practice';
    } else {
      this.queueType = 'pms';
    }
    this.subject.next({token: tokenLocal, type: this.queueType});
  }

  sendMaintenanceMessage(maintenance: MaintenanceInterface) {
    if (!maintenance) {
      return false;
    }
    const message = {
      payload: {
        status: maintenance.status,
        message: maintenance.message,
        start: maintenance.start,
        duration: maintenance.duration,
        end: maintenance.end
      }
    };
    this.maintenanceMessages.next(message);
  }
}
