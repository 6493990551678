import { Pipe, PipeTransform } from '@angular/core';
import { TerminalStatusEnum } from '../models/enums/terminal-status.enum';

@Pipe({
  name: 'paymentLabel',
  pure: true
})
export class PaymentLabelPipe implements PipeTransform {

  transform(value: TerminalStatusEnum): string {
    return this.getTerminalStatusLabel(value);
  }

  getTerminalStatusLabel(terminalStatus: TerminalStatusEnum): string {
    switch (terminalStatus) {
      case TerminalStatusEnum.connectingReader: {
        return 'Connecting';
      }
      case TerminalStatusEnum.presentCard:
      case TerminalStatusEnum.processingPayment: {
        return 'Awaiting Payment';
      }
      case TerminalStatusEnum.paymentSuccessful: {
        return 'Paid';
      }
      case TerminalStatusEnum.paymentCancelled: {
        return 'Cancelled';
      }
      default: {
        return '';
      }
    }
  }

}
