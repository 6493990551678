<!-- Display Charity Logos-->
<ng-container [ngSwitch]="charity">
  <ng-container *ngSwitchCase="'helpful-hounds'">
    <ng-container *ngTemplateOutlet="HelpfulHounds"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'our-special-friends'">
    <ng-container *ngTemplateOutlet="OurSpecialFriends"></ng-container>
  </ng-container>
</ng-container>

<ng-template #HelpfulHounds>
  <p-overlayPanel #helpfulHoundsOverlayPanel styleClass="charity-panel helpfulhounds-panel">
    <ng-template pTemplate>
      <div>
        <img src="../../../../../assets/logos/helpful-hounds-charity.png" class="helpfulhounds">
      </div>
    </ng-template>
  </p-overlayPanel>
  <p-tag
    styleClass="tag helpfulhounds"
    (mouseenter)="helpfulHoundsOverlayPanel.show($event)"
    (mouseout)="helpfulHoundsOverlayPanel.hide()"
  >Helpful Hounds</p-tag>
</ng-template>


<ng-template #OurSpecialFriends>
  <p-overlayPanel #ourSpecialFriendsOverlayPanel styleClass="charity-panel our-special-friends-panel">
    <ng-template pTemplate>
      <img src="../../../../../assets/logos/our-special-friends.png">
    </ng-template>
  </p-overlayPanel>
  <p-tag
    styleClass="tag our-special-friends"
    (mouseenter)="ourSpecialFriendsOverlayPanel.show($event)"
    (mouseout)="ourSpecialFriendsOverlayPanel.hide()"
  >Our Special Friends</p-tag>
</ng-template>
