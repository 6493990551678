import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ElementRef
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { every, has, isObject, isString } from 'lodash';
import { Subscription } from 'rxjs/internal/Subscription';
import { Observable } from 'rxjs';
import { OverlayPanel } from 'primeng/overlaypanel';
// Interfaces
import {
  ConsultationSelectedProductInterface
} from '../../../consultation-shared/models/interfaces/consultation-selected-product.interface';
import { BasketItemInterface } from '../../models/interfaces/basket-item.interface';
import { BatchNumberInterface } from '../../models/interfaces/batch-number.interface';
// Services
import { ErrorHandlerService } from '../../services/error-handler.service';
import { ProductsService } from '../../services/products.service';
import { LabelPrinterService } from '../../services/label-printer.service';
// Label Template
import { prescriptionLabel } from '../../../../../assets/printer-labels/prescription-label';
import { MessageService } from 'primeng/api';
/**
 * ClientBasketItemComponent - Component for displaying basket items
 */
@Component({
  selector: 'app-client-basket-item',
  templateUrl: './client-basket-item.component.html'
})
export class ClientBasketItemComponent implements OnInit, OnDestroy {

  @Input() set item(value: ConsultationSelectedProductInterface | BasketItemInterface) {
    if (this.isPendingItem(value)) {
      this.setupPendingForm(value);
      this.basketType = 'pending';
    }
    if (this.isConfirmedItem(value)) {
      this.setupConfirmedForm(value);
      this.basketType = 'confirmed';
    }
  }
  @Input() practiceID: number;
  @Input() practiceDetails: any;
  @Input() userID: number;
  @Input() address: any;
  @Input() checkout: boolean;
  @Input() selectedPrinter: any;
  @Output() editItem: EventEmitter<number> = new EventEmitter<number>();
  @Output() removeItem: EventEmitter<number> = new EventEmitter();
  @Output() confirmItem: EventEmitter<ConsultationSelectedProductInterface> = new EventEmitter();
  @Output() itemChanges: EventEmitter<{quantity?: number, batchNumbers?: Array<BatchNumberInterface>}> = new EventEmitter();

  @ViewChild('labelPreviewOverlay') labelPreviewOverlay: OverlayPanel;
  @ViewChild('labelPreviewTrigger') labelPreviewTrigger: ElementRef;

  basketItemForm: FormGroup;

  pendingSubscription = new Subscription();
  confirmedSubscription = new Subscription();
  generalSubscription = new Subscription();

  batchNumbers: Observable<Array<any>>;
  basketType: string;

  tempLabelImage: any;
  labelPreviewTimeOut: any;
  /**
   * constructor function
   * @param errorHandlerService - Service for handling API call errors
   * @param productsService - Service to get product info
   * @param labelPrinterService - Service for printing labels
   */
  constructor(
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly productsService: ProductsService,
    private readonly labelPrinterService: LabelPrinterService,
    private readonly messageService: MessageService
  ) { }
  /**
   * ngOnInit function - Sets batchNumbers (if inputs found)
   */
  ngOnInit(): void {
    if (this.basketItemForm && this.basketItemForm.value.id && this.practiceID) {
      this.batchNumbers = this.productsService.getBatchNumbers(this.practiceID, this.basketItemForm.value.doc_id);
    }
  }
  /**
   * ngOnDestroy function - Clears subscriptions/timeout on destroy
   */
  ngOnDestroy() {
    this.pendingSubscription.unsubscribe();
    this.confirmedSubscription.unsubscribe();
    this.generalSubscription.unsubscribe();
    clearTimeout(this.labelPreviewTimeOut);
  }
  /**
   * setupPendingForm function - Creates basketItemForm with product details (from param)
   * @param value - Selected Product
   */
  setupPendingForm(value: ConsultationSelectedProductInterface) {
    let quantityMinCheck = 1;
    let quantityPattern = '[0-9]+';
    if (value.product.unit === 'ml') {
      quantityMinCheck = 0.001;
      quantityPattern = '';
    }
    this.basketItemForm = new FormGroup({
      id: new FormControl(value.id),
      doc_id: new FormControl(value.product.doc_id),
      name: new FormControl(value.product.name),
      price: new FormControl(value.product.price),
      prescription: new FormControl(value?.prescription),
      quantity: new FormControl(value.quantity, [
        Validators.required,
        Validators.min(quantityMinCheck),
        Validators.pattern(quantityPattern)
      ]),
      unit: new FormControl(value.product.unit),
      product: new FormControl(value.product),
      batchNumbers: new FormControl(value.batchNumbers || []),
      isPOM: new FormControl(isString(value.product.legal_category) && value.product.legal_category?.toLowerCase()?.includes('pom')),
    });
    this.setFormChangeHandlers();
  }
  /**
   * setupConfirmedForm function - Creates basketItemForm with basket item details (from param)
   * @param value - Basket item
   */
  setupConfirmedForm(value: BasketItemInterface) {
    this.basketItemForm = new FormGroup({
      id: new FormControl(value.id),
      medication_id: new FormControl(value.source.id),
      name: new FormControl(value.name),
      price: new FormControl(value.price),
      quantity: new FormControl(value.quantity),
      unit: new FormControl(value.unit),
      batchNumbers: new FormControl(value.batches || []),
      isPOM: new FormControl(isString(value.unit) && value.unit?.length > 0)
    });
  }
  /**
   * isPendingItem function - Checks if product/quantity key/values exist on product (from param)
   * @param value - Selected Product
   */
  isPendingItem(value: any): value is ConsultationSelectedProductInterface {
    const requiredKeys = ['product', 'quantity'];
    // Check if value is an object and contains all the required keys.
    return isObject(value) && every(requiredKeys, key => has(value, key));
  }
  /**
   * isConfirmedItem function - Checks if id/name/quantity/price key/values exist on product (from param)
   * @param value - Selected Product
   */
  isConfirmedItem(value: any): value is BasketItemInterface {
    const requiredKeys = ['id', 'name', 'quantity', 'price'];
    // Check if value is an object and contains all the required keys.
    return isObject(value) && every(requiredKeys, key => has(value, key));
  }
  /**
   * removeBasketItem function - Should call removeItem.emit (with basketForm value ID)
   */
  removeBasketItem(): void {
    this.removeItem.emit(this.basketItemForm?.value?.id);
  }
  /**
   * editBasketItem function - Calls editItem.emit with basketID param
   * @param basketId - Basket ID
   */
  editBasketItem(basketId: number): void {
    this.editItem.emit(basketId);
  }
  /**
   * confirmBasketItem function - Calls confirmItem.emit (with basketItemForm value)
   */
  confirmBasketItem(): void {
    this.confirmItem.emit(this.basketItemForm?.value);
  }
  /**
   * setFormChangeHandlers function -
   */
  setFormChangeHandlers() {
    if (this.pendingSubscription) {
      this.pendingSubscription?.unsubscribe();
      this.pendingSubscription = new Subscription();
      this.pendingSubscription.add(this.basketItemForm.valueChanges.subscribe(result => {
        this.itemChanges.emit({type: 'Pending', ...this.basketItemForm.value});
      }));
    }
  }
  /**
   * getPrescriptionLabels function - Calls productsService.getPrescriptionLabels (with params)
   * @param userID - User ID
   * @param practiceID - Practice ID
   * @param dispensed - Whether medication has been dispensed
   * @param medicationID - Medication ID
   */
  getPrescriptionLabels(userID, practiceID, dispensed, medicationID) {
    return this.productsService.getPrescriptionLabels(userID, practiceID, dispensed, medicationID);
  }
  /**
   * previewLabel function - Calls getPrescriptionLabels and if successful ready label and calls getLabelPreview
   * @param event - Click event
   * @param item - Basket item
   */
  previewLabel(event, item) {
    this.generalSubscription.add(
      this.getPrescriptionLabels(this.userID, this.practiceID, false, item.medication_id).subscribe((data: any) => {
      if (data && data.length > 0) {
        data[0].practice_address = this.practiceDetails.address;
        const labelPreview = this.labelPrinterService.readyLabel(prescriptionLabel, data[0]);
        this.getLabelPreview(event, labelPreview);
      } else {
        this.messageService.add({key: 'sidebar', summary: 'Error', severity: 'error',
          detail: 'No prescription labels found'});
      }
    }, data => {
        this.messageService.add({key: 'sidebar', summary: 'Error', severity: 'error',
          detail: data?.message || 'There was an error getting prescription labels'});
    }));
  }
  /**
   * getLabelPreview function - Calls labelPrinterService.renderLabel and if successful sets tempLabelImage and toggles overlay
   * @param event - Click event
   * @param label - Parsed label
   */
  getLabelPreview(event, label) {
    this.generalSubscription.add(this.labelPrinterService.renderLabel(label).subscribe(data => {
      this.tempLabelImage = `data:image/png;base64, ${data}`;
      // @ToDo: Not so keen on using timeout but does fix the display issue
      this.labelPreviewTimeOut = setTimeout(() => {
        this.labelPreviewOverlay.toggle(event, this.labelPreviewTrigger.nativeElement);
      }, 200);
    }, data => {
      this.messageService.add({key: 'sidebar', summary: 'Error', severity: 'error', sticky: true,
        detail: 'There was an error getting prescription labels preview'});
    }));
  }
  /**
   * printLabel function - Calls getPrescriptionLabels and if successful calls labelPrinterService.printLabel
   * @param item - Label item
   */
  printLabel(item) {
    this.generalSubscription.add(
      this.getPrescriptionLabels(this.userID, this.practiceID, false, item.medication_id).subscribe((data: any) => {
        data[0].practice_address = this.practiceDetails.address;
        const labelDetails = this.labelPrinterService.readyLabel(prescriptionLabel, data[0]);
        this.generalSubscription.add(this.labelPrinterService.printLabel(this.selectedPrinter, labelDetails).subscribe(printData => {
          // @ToDo: Should show a success toast ideally
          console.log('Label printed');
        }, printData => {
          this.messageService.add({key: 'sidebar', summary: 'Error', severity: 'error', sticky: true,
            detail: 'There was an error printing prescription label'});
        }));
      }, data => {
        this.messageService.add({key: 'sidebar', summary: 'Error', severity: 'error', sticky: true,
          detail: data?.message || 'There was an error getting prescription labels'});
    }));
  }

}
