import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
// Third party
import { isBoolean } from 'lodash';
// Services
import { LocalStorageService } from '../../services/local-storage.service';
import { RingerService } from '../../services/ringer.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html'
})
export class StatusComponent implements OnInit {

  @Input() public set status(status: any) {
    this.loggedInUserStatus = status;
    this.isOnline = status?.status === 'online';
  }
  @Output() public onLoggedInStatusChange: EventEmitter<any> = new EventEmitter();

  public loggedInUserStatus: any;
  public isOnline: boolean;
  public isRingEnabled = false;

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly ringerService: RingerService
    ) { }

  ngOnInit() {
    this.setRingStatus();
  }

  updateStaffStatus(status: any, isOnline: boolean) {
    this.onLoggedInStatusChange.emit(isOnline ? 'online' : 'away');
    this.setRingerStatus(isOnline);
  }

  onRingStatusChange() {
    this.isRingEnabled = !this.isRingEnabled;
    this.ringerService.setRingerEnabledStatus(this.isRingEnabled);
    this.localStorageService.setData('ringerStatus', this.isRingEnabled);
  }

  setRingStatus() {
    const localRingerStatus = JSON.parse(this.localStorageService.getData('ringerStatus'));
    this.isRingEnabled = isBoolean(localRingerStatus) ? localRingerStatus : true;
    this.ringerService.setRingerEnabledStatus(this.isRingEnabled);
    this.localStorageService.setData('ringerStatus', this.isRingEnabled);
  }

  setRingerStatus(status: boolean) {
    this.isRingEnabled = status;
    this.ringerService.setRingerEnabledStatus(this.isRingEnabled);
    this.localStorageService.setData('ringerStatus', this.isRingEnabled);
  }
}
