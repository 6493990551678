import { Injectable } from '@angular/core';
import {AlertService} from './alert.service';
import { isString } from 'lodash';
import { ErrorInterface, RemedyInterface } from '../models/interfaces/error.interface';
import { GoogleAnalyticsService } from './google-analytics.service';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(
    private readonly alertService: AlertService,
    private readonly messageService: MessageService,
    private readonly GA: GoogleAnalyticsService
  ) { }

  handleError(error: ErrorInterface | string, sticky = false): void {
    const message = this.getErrorMessage(error);
    this.messageService.add({summary: 'Error', detail: message, severity: 'error', sticky});
    this.GA.logException(message, false);
    console.error(message);
  }

  hasCause(error: ErrorInterface): boolean {
    return error && error.hasOwnProperty('cause') && isString(error.cause);
  }

  hasRemedies(error: ErrorInterface): boolean {
    return error && error.hasOwnProperty('remedy');
  }
  hasUserMessage(error: ErrorInterface) {
    return error && error.hasOwnProperty('user_message') && !error.user_message?.toLowerCase().includes('jg-');
  }

  getErrorMessage(error: ErrorInterface | string): string {
    const defaultMessage = 'Error with API request, no cause given.';
    let message = '';

    if (typeof error === 'string') {
      message = error as string;
    } else {
      error = error as ErrorInterface;

      if (this.hasUserMessage(error)) {
        message = error.user_message;
      }

      if (this.hasCause(error) && !this.hasUserMessage(error)) {
        message = `${error.cause}`;
      }
      // Display remedies at the end of the alert in a comma separated list.
      if (this.hasRemedies(error) && !this.hasUserMessage(error)) {
        if (isString(error.remedy)) {
          message = `${message}, ${error.remedy}`;
        } else {
          for (const key in error.remedy as RemedyInterface) {
            if (key && isString(error.remedy[key])) {
              message = `${this.hasCause(error) ? message + ', ' : ''}${key} ${error.remedy[key]}`;
            }
          }
        }
      }
    }

    // If message is not set use the default error message.
    if (!message || message.length === 0) {
      message = defaultMessage;
    }
    return message;
  }
}
