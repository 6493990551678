import { Pipe, PipeTransform } from '@angular/core';
import { isString } from 'lodash';
import { InsuranceEnum } from '../../pet-shared/models/enums/insurance.enum';

@Pipe({
  name: 'insuranceFormat'
})
export class InsuranceFormatPipe implements PipeTransform {

  transform(value: InsuranceEnum): string {
    if (!isString(value)) {
      return '';
    }
    switch (value) {
      case InsuranceEnum.vetAI :
        return 'Not covered by partner insurance';
      case InsuranceEnum.afi :
        return 'Animal Friends Insurance';
      case InsuranceEnum.asda :
        return 'ASDA Insurance';
      case InsuranceEnum.purelypets :
        return 'Purely Pets Insurance';
      case InsuranceEnum.waggel :
        return 'Waggel Insurance';
      default :
        return '';
    }
  }

}
