import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Pipe({
  name: 'pomProductCheck'
})
export class PomProductCheckPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    const pomLabels = environment.pomProductLegalCategories;
    return pomLabels.includes(value);
  }

}
