import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
// Services
import { ApiService } from './api.service';
// Interfaces
import { PracticeInterface } from '../models/interfaces/practice.interface';
import {environment} from '../../../../environments/environment';
import {AppointmentTypeInterface} from '../../calendar-shared/models/interfaces/appointment-type.interface';
/**
 * PracticeService - Service for handling practice details
 */

const idSearchData: Array<PracticeInterface> = [
  {
    id: 3,
    name: 'Yarmouth Joii'
  },
  {
    id: 4,
    name: 'Yarmouth Joii'
  }
];

const locationSearchData: Array<PracticeInterface> = [
  {
    id: 3,
    name: 'Yarmouth Joii 1'
  },
  {
    id: 4,
    name: 'Yarmouth Joii 2'
  }
];

const emptySearchData: Array<PracticeInterface> = [];

@Injectable({
  providedIn: 'root'
})
export class PracticeService {
  /**
   * constructor function
   * @param apiService - Service for API interactions
   */
  constructor(private apiService: ApiService) {}
  /**
   * getPracticeById function - Gets practice details by Practice ID
   * @param id - Practice ID
   */
  public getPracticeById(id: number): Observable<PracticeInterface> {
    return this.apiService.getData(`${environment.apiPrefix}/v1/practices/${id}`);
    // return of(idSearchData);
  }
  /**
   * getPracticeByFilter function - Gets practice details by Practice Name
   * @param name - Practice Name
   */
  public getPracticeByFilter(name: string): Observable<Array<PracticeInterface>> {
    // return this.apiService.getData(`${environment.apiPrefix}/v1/practice/find?filter=${name}`);
    return of(locationSearchData);
  }
  /**
   * getPracticeByPetId function - Gets practice details by Pet ID
   * @param petId - Pet ID
   */
  public getPracticeByPetId(petId: number): Observable<PracticeInterface> {
    return this.apiService.getData(`${environment.apiPrefix}/v1/practices/pet/${petId}`);
    // return this.apiService.getData(`${environment.apiPrefix}/v1/practice/find?pet_id=${petId}`);
    // return of(idSearchData);
  }
  /**
   * getPracticeByLocation function - Gets practice details by Practice Location
   * @param location - Practice Location
   */
  public getPracticeByLocation(location: string): Observable<Array<PracticeInterface>> {
    // return this.apiService.getData(`${environment.apiPrefix}/v1/practice/find?location=${location}`);
    return of(locationSearchData);
  }
  /**
   * getPracticeByPostCode function - Gets practice details by Practice Post Code
   * @param postCode - Practice Post Code
   */
  public getPracticeByPostCode(postCode: string): Observable<Array<PracticeInterface>> {
    return this.apiService.getData(`${environment.apiPrefix}/v1/practices/find?post_code=${postCode}`);
  }
  /**
   * getPracticeByPostCode function - Gets practice details by Practice Post Code
   * @param postCode - Practice Post Code
   */
  public getPracticeAppointmentTypes(id: number): Observable<Array<any>> {
    // return this.apiService.getData(`${environment.apiPrefix}/v1/practice/${id}/appointments/types/tree`);
    return of([]);
  }

  public getAllPractices() {
    return this.apiService.getData(`${environment.apiPrefix}/v1/practices`);
  }

  public assignToPractice(petId: number, practiceId: number) {
    return this.apiService.postData(`${environment.apiPrefix}/v1/pets/${petId}/practices/${practiceId}`, {});
  }
}
