import { animate, style, transition, trigger } from '@angular/animations';

export const messageItemsAnimation = trigger('message-items', [
  transition(':enter', [
    style({ transform: 'scale(0.5)', opacity: 0 }),  // initial
    animate('0.5s ease',
      style({ transform: 'scale(1)', opacity: 1 })
    )  // final
  ]),
  transition(':leave', [
    animate('.5s ease',
      style({
        transform: 'scale(0.1)', opacity: 0,
      })
    )
  ])
]);
