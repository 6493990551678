import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onlineStatusFormat',
  pure: true
})
export class OnlineStatusFormatPipe implements PipeTransform {

  transform(status: 'online' | 'offline' | 'away' | 'in-consult'): string {
    switch (status) {
      case 'online':
        return 'Online';
      case 'offline':
        return 'Offline';
      case 'away':
        return 'Away';
      case 'in-consult':
        return 'In Consultation';
    }
  }

}
