import { Pipe, PipeTransform } from '@angular/core';
import {formatInTimeZone} from 'date-fns-tz';
import { isString, isDate } from 'lodash';

@Pipe({
  name: 'timezoneFormat'
})
export class TimezoneFormatPipe implements PipeTransform {

  transform(date: string | Date, format: string, timezone = 'Europe/London'): string {
    if (!isString(date) && !isDate(date)) {
      return '';
    }
    return formatInTimeZone(date, timezone, format);
  }

}
