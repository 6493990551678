import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
// Services
import { ApiService } from './api.service';
import { isNumber, isString } from 'lodash';
import { UserProfileInterface } from '../models/interfaces/user-profile.interface';
import { UserInterface } from '../models/interfaces/user.interface';
/**
 * UsersService - Service for handling user data with API
 */
@Injectable({
  providedIn: 'root'
})
export class UsersService {
  // Initial Vars
  baseUrl = `${environment.apiPrefix}/v1`;
  /**
   * constructor function
   * @param apiService - Service for API interactions
   */
  constructor(private apiService: ApiService) { }
  /**
   * getUserPets function - Gets all pets associated with a particular user
   * @param id - User ID
   */
  getUserPets(id): Observable<any> {
    return this.apiService.getData(`${this.baseUrl}/pets/users/${id}`);
  }
  /**
   * getUserById function - Gets a users details by user ID
   * @param id - User ID
   */
  getUserById(id: number): Observable<UserInterface> {
    return this.apiService.getData(`${this.baseUrl}/users/fetch/${id}`);
  }

  getUserOrders(userId: number, pageNumber?: number, pageSize?: number, searchTerm?: string, startDate?: string, endDate?: string) {
    const url = `${this.baseUrl}/orders/users/${userId}`;

    const params = {} as any;
    if (isNumber(pageNumber)) {
      params.page = pageNumber;
    }
    if (isNumber(pageSize)) {
      params.limit = pageSize;
    }
    if (isString(searchTerm)) {
      params.name = searchTerm;
    }
    if (isString(startDate)) {
      params.start = startDate;
    }
    if (isString(endDate)) {
      params.end = endDate;
    }
    return this.apiService.getData(url, params);
  }

  createPracticeClient(userDetails: any, practiceId: string): Observable<any> {
    return this.apiService.postData(`${this.baseUrl}/providers/practices/${practiceId}/register`, userDetails);
  }

  setClientPhoneNumber(phoneNumberDetails: any, userID: string) {
    return this.apiService.patchData(`${this.baseUrl}/users/${userID}/profile/phone`, phoneNumberDetails);
  }

  addClientPet(details, practiceId?: number): Observable<any> {
    return this.apiService.postData(`${this.baseUrl}/providers${practiceId ? '/practices/' + practiceId : ''}/add`, details);
  }

  updateClientPet(details, practiceId?: number): Observable<any> {
    return this.apiService.postData(`${this.baseUrl}/providers${practiceId ? '/practices/' + practiceId : ''}/update`, details);
  }

  updateClientFullName(details, clientID): Observable<any> {
    return this.apiService.patchData(`${this.baseUrl}/users/${clientID}/name`, details);
  }
}
