import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2, HostListener } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { LocalStorageService } from './local-storage.service';
import { ThemeType } from '../models/types/theme.type';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService implements OnDestroy{
  private subscription: Subscription;
  private currentTheme: ThemeType = 'os-theme';
  private renderer: Renderer2;
  private allowEventListen: boolean;
  // @ToDo: Not a massive fan of this, but is the way primeNG exampled it (thoughts?)
  private themeLink: HTMLLinkElement = this.document.getElementById('app-theme') as HTMLLinkElement;

  constructor(
    private readonly rendererFactory2: RendererFactory2,
    private readonly localStorage: LocalStorageService,
    private mediaMatcher: MediaMatcher,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = this.rendererFactory2.createRenderer(null, null);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initThemeUpdates() {
    const theme = this.localStorage.getData('theme') || this.currentTheme;
    this.setTheme(theme);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = new Subscription();
    this.subscription.add(this.localStorage.getPersistedDataChanges().subscribe(changes => {
      if (changes && changes?.theme && (this.currentTheme !== changes?.theme)) {
        this.setTheme(changes.theme);
      }
    }));
  }

  setTheme(theme: ThemeType) {
    if (theme !== this.currentTheme) {
      this.renderer.removeClass(this.document.body, this.currentTheme);
      this.renderer.addClass(this.document.body, theme);
      this.currentTheme = theme;
    } else {
      // Not sure why I needed to add this for initial set (maybe change to localStorage setup)
      this.renderer.addClass(this.document.body, theme);
      this.currentTheme = theme;
    }
    if (this.themeLink && this.currentTheme) {
      switch (theme) {
        case 'os-theme':
          if (this.mediaMatcher.matchMedia('(prefers-color-scheme: dark)').matches) {
            this.setPrimeTheme(this.themeLink, 'dark');
          } else {
            this.setPrimeTheme(this.themeLink, 'light');
          }
          this.allowEventListen = true;
          this.mediaMatcher.matchMedia('(prefers-color-scheme: dark)').addEventListener('change',
            (change) => this.handleMediaQueryEvent(change));
          break;
        case 'dark-theme':
          this.setPrimeTheme(this.themeLink, 'dark');
          this.removeMediaEvent();
          break;
        case 'light-theme':
          this.setPrimeTheme(this.themeLink, 'light');
          this.removeMediaEvent();
          break;
      }
    }
  }

  handleMediaQueryEvent(change) {
    if (this.allowEventListen) {
      if (change.matches) {
        this.setPrimeTheme(this.themeLink, 'dark');
      } else {
        this.setPrimeTheme(this.themeLink, 'light');
      }
    }
  }

  removeMediaEvent() {
    // @ToDo: This feels a bit hacky, the removeEventListener is not working
    //  correctly so decided to use a flag to block the change
    this.allowEventListen = false;
    // this.mediaMatcher.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', null);
  }

  setPrimeTheme(themeLink: HTMLLinkElement, type: string) {
    switch (type) {
      case 'dark':
        themeLink.href = 'prime-dark-theme.css';
        break;
      case 'light':
        themeLink.href = 'prime-light-theme.css';
        break;
    }
  }

}
