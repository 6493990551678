import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

import { ApiService } from '../../general-shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class AddressLookupService {

  constructor(private apiService: ApiService) { }

  getAddressByPostcode(postcode): Observable<any> {
    return this.apiService.getData(environment.addressApiPrefix + postcode + '?expand=true&api-key=' + environment.addressApiToken);
  }
}
