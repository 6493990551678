import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { PatientNoteInterface } from '../models/interfaces/patient-note.interface';
import { ConsultationInterface } from '../models/interfaces/consultation.interface';
import { PatientNotesService } from './patient-notes.service';
import { take, takeUntil } from 'rxjs/operators';
import { first } from 'lodash';
import { ErrorHandlerService } from '../../general-shared/services/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class PatientNoteManagerService implements OnDestroy {

  patientNote: BehaviorSubject<PatientNoteInterface> = new BehaviorSubject<PatientNoteInterface>(null);
  destroy = new Subject();

  constructor(
    private readonly patientNotesService: PatientNotesService,
    private readonly errorHandlerService: ErrorHandlerService
  ) { }

  ngOnDestroy() {
  }

  getPatientNote() {
    return this.patientNote.asObservable();
  }

  setInitialPatientNote(consultation: ConsultationInterface, sessionId: number) {
    this.patientNote.next({
      user_id: consultation.user.id,
      consultation_id: consultation.id,
      pet_id: +consultation.pet.id,
      staff_id: +consultation.staff_id,
      response_session_id: +consultation.response_session_id,
      outcomes: [],
      treatment_plan: {
        additional_instructions: ' ',
        owner_summary: `Hi ${consultation?.user.full_name.split(" ")[0]},\r\n\r\nThank you for your call, it's been lovely chatting with you. Here is the treatment plan for ${consultation?.pet.name}.\r\n\r\n`,
        problems: [],
        tests_recommended: [],
        resources: []
      },
      session_id: sessionId
    });
  }
  updatePatientNoteFromAPI(consultationID: number): void {
    this.patientNotesService.getConsultationPatientNote(consultationID).pipe(
      take(1),
      takeUntil(this.destroy)
    ).subscribe(result => {
      this.patientNote.next(first(result.patient_notes));
    }, data => {
      this.errorHandlerService.handleError(data?.error);
    });
  }
  updatePatientNoteDetails(detail, type) {
    let updateValue;
    switch (type) {
      case 'problems':
        updateValue = detail;
        break;
      case 'treatmentPlanSummary':
        break;
    }
    this.patientNote.next({
      ...this.patientNote.value,
      updateValue
    });
    console.log(this.patientNote);
  }
}
