import { Pipe, PipeTransform } from '@angular/core';
import {
  addMinutes,
  differenceInSeconds,
  intervalToDuration,
  isAfter
} from 'date-fns';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'maintenanceCountdown'
})
export class MaintenanceCountdownPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) {
  }

  transform(clock: Date, start: string, duration: number): unknown {
    const startDateTime = new Date(start);
    const endDateTime = addMinutes(new Date(startDateTime), duration);

    // Maintenance has overrun.
    if (isAfter(clock, endDateTime)) {
      return '00 : 00 : 00 : 00';
    }

    const seconds = Math.floor(Math.abs(differenceInSeconds(clock, endDateTime)));

    const durationMapping = intervalToDuration(
      {start: 0, end: seconds * 1000}
    );

    const daysFormatted = durationMapping.days > 0 ? this.decimalPipe.transform(durationMapping.days, '2.0-0') : '00';
    const hoursFormatted = durationMapping.hours > 0 ? this.decimalPipe.transform(durationMapping.hours, '2.0-0') : '00';
    const minutesFormatted = durationMapping.minutes > 0 ? this.decimalPipe.transform(durationMapping.minutes, '2.0-0') : '00';
    const secondsFormatted = durationMapping.seconds > 0 ? this.decimalPipe.transform(durationMapping.seconds, '2.0-0') : '00';

    return `${daysFormatted} : ${hoursFormatted} : ${minutesFormatted} : ${secondsFormatted}`;
  }

}
