<main [class.blur-app]="requiresUpdate || maintenanceDialogActive">
  <app-main-header *ngIf="!isLogin" [versionNumber]="versionNumber"></app-main-header>
  <div>
    <router-outlet></router-outlet>
  </div>
</main>

<app-maintenance-holder
  [maintenance]="maintenance"
  [isActiveConsultation]="isActiveConsultation"
  (maintenanceDialogActive)="onMaintenanceDialogChange($event)">
</app-maintenance-holder>


<ng-template #VersionUpdate>
  <app-version-update-dialog></app-version-update-dialog>
</ng-template>

<p-toast position="bottom-right" [baseZIndex]="100000"></p-toast>
