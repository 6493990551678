import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { PomLabelInterface } from '../models/interfaces/pom-label.interface';
import { isNull, omitBy } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private apiService: ApiService) { }

  getProducts(consultationID, species, order, descending, trialCode) {
    return this.apiService.getData(`${environment.apiPrefix}/v1/products/tree?consultation=${consultationID}&products=true&species=${species}`);
  }

  getLabTests() {
    return this.apiService.getData(`${environment.apiPrefix}/v1/tests`);
  }

  getStockLevels() {
    return this.apiService.getData(`${environment.apiPrefix}/v1/products/stock/levels`);
  }

  getBatchNumbers(practiceId: number, docId: string) {
    return this.apiService.getData(`${environment.apiPrefix}/v1/practices/${practiceId}/stock/${docId}/batches`);
  }

  getPrescriptionLabels(userId: number, practiceId: number, dispensed: boolean, medication?: number): Observable<Array<PomLabelInterface>> {
    const params = omitBy({
      user: userId.toString(),
      practice: practiceId.toString(),
      dispensed: dispensed.toString(),
      medication: medication?.toString() || null
    }, isNull);
    return this.apiService.getData(`${environment.apiPrefix}/v1/prescriptions`, params);
  }

  getPracticeStock(practiceId: number, species: string): Observable<any> {
    return this.apiService.getData(`${environment.apiPrefix}/v1/products/practices/${practiceId.toString()}/stock?species=${species}`);
  }
}
