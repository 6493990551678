import { Pipe, PipeTransform } from '@angular/core';
import { isFinite } from 'lodash';

@Pipe({
  name: 'joiiCurrency'
})
export class JoiiCurrencyPipe implements PipeTransform {

  transform(value: number): string {
    if (!isFinite(value)) {
      return 'NAN';
    }
    return '£' + (value / 100).toFixed(2);
  }

}
