import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
// Third party
import { interval, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { isBoolean } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class RingerService {
  ringtone: HTMLAudioElement;
  ringerEnabledStatus = true;
  ringerSubscription: Subscription = new Subscription();
  stopRinger: Subject<void> = new Subject();
  ringing = false;
  inConsult = false;
  inPractice = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private storageService: LocalStorageService
  ) {
  }

  initialiseRingerStatus() {
    const status = this.storageService.getData('ringerStatus');
    this.ringerEnabledStatus = isBoolean(status) ? status : true;
  }

  setRingerEnabledStatus(enabled: boolean) {
    this.ringerEnabledStatus = enabled;
  }

  setConsultationStatus(inConsult: boolean) {
    this.inConsult = inConsult;
    if (inConsult) {
      this.stopRinger.next();
    }
  }

  setPracticeStatus(inPractice: boolean) {
    this.inPractice = inPractice;
    if (inPractice) {
      this.stopRinger.next();
    }
  }

  startRinger() {
    if (!this.ringing && this.ringerEnabledStatus && !this.inConsult && !this.inPractice) {
      // start ringing subscription
      this.cancelRinger();
      this.ringing = true;
      this.playRingtone();
      this.ringerSubscription.add(interval(10000).pipe(
        takeUntil(this.stopRinger)
      ).subscribe(() => {
        // tslint:disable-next-line:no-console
        this.playRingtone();
      }));
    }
  }

  cancelRinger() {
    this.stopRinger.next();
    this.ringing = false;
  }

  playRingtone() {
    if (!this.ringtone || !this.ringtone?.src) {
      this.ringtone = document.createElement('audio');
      this.ringtone.autoplay = true;
      this.ringtone.src = '../../../assets/ringtones/default.mp3';
    }
    this.ringtone?.play().then(() => {
      console.log('Ringtone played');
    }).catch((error) => {
      console.error(error);
    });
  }
}
