import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LocalStorageService } from '../../shared/general-shared/services/local-storage.service';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Http401AllowList } from '../models/http-401-alllow-list';

@Injectable()
export class InvalidRequestInterceptor implements HttpInterceptor {

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router
    ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.handleErrors(request, next);
  }

  handleErrors(request: HttpRequest<unknown>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (this.redirectRequired(err.status, err.url)) {
            this.clearUserToken();
            this.router.navigate(['./auth/login']);
            // Invalidate user session and redirect to login/home
          }
          // return the error back to the caller
          return throwError(err);
        }
      })
    );
  }

  clearUserToken() {
    this.localStorageService.clearStorageFromKeys(['userToken', 'currentUser']);
  }

  redirectRequired(status: number, requestUrl: string): boolean {
    if (status !== 401) {
      return false;
    }
    return Http401AllowList.findIndex(url => requestUrl?.includes(url)) === -1;
  }
}
