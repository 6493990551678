<section class="container-fluid" *ngIf="clientDetails">
  <!-- Client Details Starts -->
  <div class="row">
    <div class="col-12">
      <h3>Client Details</h3>
    </div>
    <div class="col-4">
      <div class="full_name_holder">
        <strong class="full_name_label">Full Name:&nbsp;</strong>
        <span *ngIf="clientDetails.full_name && !editingFullName">{{clientDetails.full_name}}</span>
        <span *ngIf="!clientDetails.full_name && !editingFullName">Not Set</span>
        <span *ngIf="editingFullName && fullNameForm">
          <form [formGroup]="fullNameForm" (ngSubmit)="saveFullName()">
            <input
              pInputText
              type="text"
              formControlName="full_name"
              class="margin-b-sm full-width"
              [disabled]="updatingFullName">
            <button
              type="submit"
              mat-flat-button
              class="success-btn full-width"
              [disabled]="updatingFullName || fullNameForm.invalid">Save</button>
            <button
              color="warn"
              type="button"
              mat-flat-button
              class="full-width"
              [disabled]="updatingFullName"
              (click)="stopEditingFullName()">Cancel</button>
          </form>
        </span>
        <span *ngIf="!editingFullName" class="edit-btn" (click)="editFullName()"><mat-icon>edit</mat-icon></span>
      </div>
      <div>
        <strong>Pronouns:&nbsp;</strong>
        <span *ngIf="clientDetails.profile.pronouns">{{clientDetails.profile.pronouns}}</span>
        <span *ngIf="!clientDetails.profile.pronouns">Not Set</span>
      </div>
      <div>
        <div><strong>Bio:&nbsp;</strong></div>
        <small *ngIf="clientDetails.profile.bio">{{clientDetails.profile.bio}}</small>
        <span *ngIf="!clientDetails.profile.bio">Not Set</span>
      </div>
    </div>
    <div class="col-4">
      <div *ngIf="clientDetails.id">
        <strong>Client ID:&nbsp;</strong>
        <span>{{clientDetails.id}}</span>
      </div>
      <div>
        <strong>Email:&nbsp;</strong>
        <span *ngIf="clientDetails.email">{{clientDetails.email}}</span>
        <span *ngIf="!clientDetails.email">Not Set</span>
      </div>
      <div class="phone_number_holder">
        <strong class="phone_label">Phone Number:&nbsp;</strong>
        <span *ngIf="!editingPhone">
          <span *ngIf="clientDetails.profile.phone">{{clientDetails.profile.phone}}</span>
          <span *ngIf="!clientDetails.profile.phone">Not Set</span>
        </span>
        <span *ngIf="editingPhone && phoneNumberForm">
          <form [formGroup]="phoneNumberForm" (ngSubmit)="savePhoneNumber()">
            <input
              pInputText
              type="text"
              formControlName="phone"
              class="margin-b-sm full-width"
              [disabled]="updatingPhone">
            <button
              mat-flat-button
              class="success-btn full-width"
              [disabled]="updatingPhone">Save</button>
            <button
              color="warn"
              type="button"
              mat-flat-button
              class="full-width"
              [disabled]="updatingPhone"
              (click)="stopEditingPhone()">Cancel</button>
          </form>
        </span>
        <span *ngIf="!editingPhone" class="edit-btn" (click)="editPhone()"><mat-icon>edit</mat-icon></span>
      </div>
      <div>
        <strong>Date of Birth:&nbsp;</strong>
        <span *ngIf="clientDetails.profile.date_of_birth">{{clientDetails.profile.date_of_birth | date: 'mediumDate'}}</span>
        <span *ngIf="!clientDetails.profile.date_of_birth">Not Set</span>
      </div>
      <div>
        <div><strong>Avatar:</strong></div>
        <div class="client-avatar" *ngIf="clientDetails">
          <span *ngIf="clientDetails.profile.image">
            <img [src]="clientDetails.profile.image" [alt]="clientDetails.full_name" />
          </span>
          <mat-icon matTooltip="No Avatar Set" *ngIf="!clientDetails.profile.image">account_circle</mat-icon>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div>
        <strong>Account Created:&nbsp;</strong>
        <span *ngIf="clientDetails.created">{{clientDetails.created | date: 'mediumDate'}}</span>
        <span *ngIf="!clientDetails.created">Not Set</span>
      </div>
      <div>
        <strong>Providers:&nbsp;</strong>
        <span *ngIf="clientDetails.providers">
          <span *ngFor="let provider of clientDetails.providers; let i = index;">
            <span>{{provider | titlecase}}</span>
            <span *ngIf="i !== clientDetails.providers.length - 1">,&nbsp;</span>
          </span>
        </span>
        <span *ngIf="!clientDetails.providers">None</span>
      </div>
      <div class="inline-icon">
        <strong>Notifications:&nbsp;</strong>
        <span *ngIf="clientDetails.created">
          <mat-icon class="joii-text-success">done</mat-icon>
        </span>
        <span *ngIf="!clientDetails.created">
          <mat-icon class="joii-text-danger">close</mat-icon>
        </span>
      </div>
    </div>
  </div>
  <!-- Client Details Ends -->
  <hr />
  <!-- Client Addresses Starts -->
  <div class="row">
    <div class="col-12">
      <h3
        *ngIf="clientDetails.addresses"
        (click)="toggleAddresses()"
        class="expand-trigger">
        Addresses <ng-container *ngIf="clientDetails.addresses.length > 0">(x{{clientDetails.addresses.length}})</ng-container>
        <span class="expand-toggle">
          <mat-icon *ngIf="showAddresses">expand_more</mat-icon>
          <mat-icon *ngIf="!showAddresses">expand_less</mat-icon>
        </span>
      </h3>
    </div>
    <div *ngIf="clientDetails.addresses && showAddresses" class="row">
      <div *ngFor="let address of clientDetails.addresses; let i = index;" class="col-4">
        <section class="address-details">
          <div class="btns-holder" *ngIf="!readOnly && address.id">
            <button mat-icon-button (click)="editAddress(address, i)">
              <mat-icon *ngIf="!address.editingAddress" matTooltip="Edit Address">edit</mat-icon>
              <mat-icon *ngIf="address.editingAddress" matTooltip="Cancel Editing">close</mat-icon>
            </button>
            <button mat-icon-button (click)="confirmDelete($event, address, i)">
              <mat-icon class="joii-text-danger" matTooltip="Delete Address">delete</mat-icon>
            </button>
            <p-confirmPopup></p-confirmPopup>
          </div>
          <article class="address-preview" *ngIf="!address.editingAddress">
            <div>
              <strong>House No:&nbsp;</strong>
              <span *ngIf="address.house_number">{{address.house_number}}</span>
            </div>
            <div>
              <strong>Street:&nbsp;</strong>
              <span *ngIf="address.street">{{address.street}}</span>
            </div>
            <div>
              <strong>City:&nbsp;</strong>
              <span *ngIf="address.city">{{address.city}}</span>
            </div>
            <div>
              <strong>County:&nbsp;</strong>
              <span *ngIf="address.county">{{address.county}}</span>
            </div>
            <div>
              <strong>Postcode:&nbsp;</strong>
              <span *ngIf="address.postcode">{{address.postcode}}</span>
            </div>
            <div>
              <strong>Country:&nbsp;</strong>
              <span *ngIf="address.country">{{address.country}}</span>
            </div>
          </article>
          <article *ngIf="address.editingAddress && addressForm" class="address-form">
            <form [formGroup]="addressForm" (ngSubmit)="saveAddress()">
              <div>
                <label><strong>House No:</strong></label>
                <input pInputText formControlName="house_number" type="text" class="p-inputtext-sm" />
              </div>
              <div>
                <label><strong>Street:</strong></label>
                <input pInputText formControlName="street" type="text" class="p-inputtext-sm" />
              </div>
              <div>
                <label><strong>City:</strong></label>
                <input pInputText formControlName="city" type="text" class="p-inputtext-sm" />
              </div>
              <div>
                <label><strong>County:</strong></label>
                <input pInputText formControlName="county" type="text" class="p-inputtext-sm" />
              </div>
              <div>
                <label><strong>Postcode:</strong></label>
                <input pInputText formControlName="postcode" type="text" class="p-inputtext-sm" />
              </div>
              <div>
                <label><strong>Country:</strong></label>
                <input pInputText formControlName="country" type="text" class="p-inputtext-sm" />
              </div>
              <button
                mat-flat-button
                class="success-btn full-width"
                type="submit"
                [disabled]="updatingAddress || addressForm.invalid">
                {{addressForm.value.id ? 'Save' : 'Add'}}
              </button>
            </form>
          </article>
        </section>
      </div>
      <div *ngIf="clientDetails.addresses.length === 0" class="col-4">
        None Set
      </div>
      <div class="col-4">
        <button
          mat-flat-button
          color="primary"
          [disabled]="editingAddress"
          (click)="addAddress()">Add Address</button>
      </div>
    </div>
  </div>
  <!-- Client Addresses Ends -->
  <hr />
  <!-- Client Pets Starts -->
  <div class="row">
    <div class="col-12">
      <h3
        *ngIf="clientDetails.pets"
        (click)="togglePets()"
        class="expand-trigger">
        Pets <ng-container *ngIf="clientDetails.pets.length > 0">(x{{clientDetails.pets.length}})</ng-container>
        <span class="expand-toggle">
          <mat-icon *ngIf="showPets">expand_more</mat-icon>
          <mat-icon *ngIf="!showPets">expand_less</mat-icon>
        </span>
      </h3>
    </div>
    <div *ngIf="clientDetails.pets && showPets" class="row">
      <div *ngFor="let pet of clientDetails.pets; let i = index;" class="col-6">
        <div class="pet-profile">
          <div class="btns-holder" *ngIf="!readOnly && pet.id">
            <button mat-icon-button (click)="editPetDetails(pet, i)">
              <mat-icon *ngIf="!pet.editingDetails" matTooltip="Edit Pet Details">edit</mat-icon>
              <mat-icon *ngIf="pet.editingDetails" matTooltip="Cancel Editing">close</mat-icon>
            </button>
          </div>
          <article *ngIf="!pet.editingDetails">
            <div class="pet-avatar-holder">
              <app-pet-profile-image
                [src]="pet.cropped_profile_image"
                [species]="pet.species"
                [insurance]="pet.insurance"></app-pet-profile-image>
            </div>
            <div class="pet-details-holder">
              <p><strong>Pet ID: </strong> {{pet.id}}</p>
              <p><strong>Name: </strong> {{pet.name}}</p>
              <p><strong>Species: </strong> {{pet.species | titlecase}}</p>
              <p><strong>Breed(s): </strong>
                <span *ngFor="let breed of pet.breeds; let i = index">
                {{breed}}<span *ngIf="i !== pet.breeds.length -1">,&nbsp;</span>
              </span>
                <span *ngIf="pet.breeds.length === 0">
                None Set
              </span>
              </p>
              <p><strong>Gender: </strong> {{pet.gender | titlecase}}</p>
              <p><strong>Age: </strong> <span *ngIf="pet.age">{{pet.age | age}}</span><span *ngIf="!pet.age">Not Set</span></p>
              <p><strong>Weight: </strong> <span *ngIf="pet.weight">{{pet.weight | number}}kg</span><span *ngIf="!pet.weight">Not Set</span></p>
              <p>
                <strong>Microchip Number: </strong>
                <span *ngIf="pet.microchip_number">{{pet.microchip_number}}</span>
                <span *ngIf="!pet.microchip_number">Not Set</span>
              </p>
              <p><strong>Trials(s): </strong>
                <span *ngFor="let trial of pet.clinical_trials; let i = index">
                {{trial.code}}<span *ngIf="i !== pet.clinical_trials.length -1">,&nbsp;</span>
              </span>
                <span *ngIf="pet.clinical_trials.length === 0">
                None
              </span>
              </p>
              <p class="inline-icon">
                <strong>Neutered: </strong>
                <mat-icon *ngIf="pet.neutered">check</mat-icon>
                <mat-icon *ngIf="!pet.neutered">close</mat-icon>
              </p>
            </div>
          </article>
          <article *ngIf="pet.editingDetails" class="">
            <div *ngIf="petForm">
              <form [formGroup]="petForm" class="pet-details-form" (ngSubmit)="savePet()">
                <div>
                  <label><strong>Practice:</strong></label>
                  <p-dropdown
                    [options]="availablePractices"
                    [disabled]="!this.availablePractices?.length"
                    formControlName="practice"
                    optionLabel="name"
                    [autoDisplayFirst]="false"
                    ></p-dropdown>
                </div>
                <div>
                  <label><strong>Name:<sup>*</sup></strong></label>
                  <input
                    pInputText
                    formControlName="name"
                    type="text"
                    class="p-inputtext-sm" />
                </div>
                <div class="row">
                <div class="col-6">
                  <label><strong>Species:<sup>*</sup></strong></label>
                  <p-dropdown
                    [options]="availablePetSpecies"
                    formControlName="species"
                    [autoDisplayFirst]="false"
                    (onChange)="handlePetSpecies($event, true)"></p-dropdown>
                </div>
                  <div class="col-6">
                    <label><strong>Gender:<sup>*</sup></strong></label>
                    <p-dropdown
                      [options]="availablePetGenders"
                      formControlName="gender"
                      [autoDisplayFirst]="false"></p-dropdown>
                  </div>
                </div>
                <div>
                  <label><strong>Breeds:<sup>*</sup></strong></label>
                  <p-multiSelect
                    [disabled]="!petForm.value.species"
                    [options]="activePetBreeds"
                    [filter]="true"
                    [optionLabel]="'name'"
                    [optionValue]="'name'"
                    [showClear]="true"
                    [resetFilterOnHide]="true"
                    appendTo="body"
                    display="chip"
                    formControlName="breeds"></p-multiSelect>
                </div>
                <div class="row">
                <div class="col-6">
                  <label><strong>Age (years):</strong></label>
                  <p-inputNumber
                    formControlName="age_years"
                    class="p-inputtext-sm"
                    (onInput)="updatePetAge($event, 'year')"></p-inputNumber>
                </div>
                <div class="col-6">
                  <label><strong>Age (months):</strong></label>
                  <p-inputNumber
                    formControlName="age_months"
                    class="p-inputtext-sm"
                    (onInput)="updatePetAge($event, 'month')"></p-inputNumber>
                </div>
                </div>
                <div>
                  <label><strong>Weight (kg):</strong></label>
                  <p-inputNumber formControlName="weight" class="p-inputtext-sm"></p-inputNumber>
                </div>
                <div>
                  <label><strong>Microchip Number:</strong></label>
                  <input
                    pInputText
                    formControlName="microchip_number"
                    type="text"
                    class="p-inputtext-sm" />
                </div>
                <div>
                  <label class="checkbox-label"><strong>Neutered:</strong></label>
                  <p-checkbox formControlName="neutered" binary="true"></p-checkbox>
                </div>
                <label *ngIf="pet.insurance?.length > 0" class="warning">Some values are locked and must be changed with insurer.</label>
                <button
                  mat-flat-button
                  class="success-btn full-width"
                  type="submit"
                  [disabled]="petForm.invalid">
                  {{petForm.value.id ? 'Save' : 'Add'}}
                </button>
              </form>
            </div>
          </article>
        </div>
      </div>
      <div *ngIf="clientDetails.pets.length === 0" class="col-4">
        None Set
      </div>
      <div class="col-4">
        <button
          mat-flat-button
          color="primary"
          [disabled]="editingPet"
          (click)="addPet()">Add Pet</button>
      </div>
    </div>
  </div>
  <!-- Client Pets Ends -->
</section>
