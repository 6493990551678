import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
/**
 * TableSearchBarComponent - Component for searching table data
 */
@Component({
  selector: 'app-table-search-bar',
  templateUrl: './table-search-bar.component.html'
})
export class TableSearchBarComponent implements OnInit {
  // Input/Outputs
  @Input() sectionLabel: string;
  @Input() searchTerm: string;
  @Input() searchOptions: Array<any>;
  @Input() postOptionDetails: any;
  @Output() sendSearchDetails: EventEmitter<string> = new EventEmitter<string>();
  // Initial Vars
  hasPreOptions: boolean;
  hasPostOptions: boolean;
  preOptionDetails: any;
  /**
   * constructor function
   */
  constructor() { }
  /**
   * ngOnInit function - Angular init function (calls checkForOptions function)
   */
  ngOnInit(): void {
    this.checkForOptions();
  }
  /**
   * checkForOptions function - If has searchOptions loops through and checks item locations to set initial vars pre/post
   * @ToDo: This is a bit basic and will need to be more dynamic in future
   */
  checkForOptions() {
    if (this.searchOptions) {
      this.searchOptions.forEach(item => {
        if (item.location === 'pre') {
          this.hasPreOptions = true;
          if (item.options) {
            this.preOptionDetails = item.options[0];
          }
        }
        if (item.location === 'post') {
          this.hasPostOptions = true;
          if (!this.postOptionDetails) {
            this.postOptionDetails = {
              startDate: '',
              endDate: ''
            };
          }
        }
      });
    }
  }
  /**
   * searchItems function - Sets searchDetails to searchTerm by default but if searchOptions
   * exist calls getSearchOptions to set it before calling sendSearchDetails.emit
   */
  searchItems() {
    const searchDetails = this.getSearchOptions();
    this.sendSearchDetails.emit(searchDetails);
  }
  /**
   * getSearchOptions function - Checks pre/post details and returns searchDetails with appropriate configuration
   * @ToDo: Again this is too basic and will need to be more dynamic
   */
  getSearchOptions() {
    let searchDetails;
    if (!this.preOptionDetails && !this.postOptionDetails) {
      return this.searchTerm;
    }
    if (this.preOptionDetails) {
      searchDetails = {
        searchTerm: this.searchTerm,
        searchOption: this.preOptionDetails
      };
    }
    if (this.postOptionDetails) {
      searchDetails = {
        searchTerm: this.searchTerm,
        startDate: this.postOptionDetails.startDate,
        endDate: this.postOptionDetails.endDate
      };
    }
    if (this.preOptionDetails && this.postOptionDetails) {
      searchDetails = {
        searchTerm: this.searchTerm,
        searchOption: this.preOptionDetails,
        startDate: this.postOptionDetails.startDate,
        endDate: this.postOptionDetails.endDate
      };
    }
    return searchDetails;
  }
  /**
   * clearSearch function - Sets searchTerm (to empty string) and resets search options if they exist
   * @ToDo: This should be more dynamic as well
   */
  clearSearch() {
    this.searchTerm = '';
    if (this.searchOptions && this.preOptionDetails) {
      this.preOptionDetails = 'name';
    }
    if (this.searchOptions && this.postOptionDetails) {
      this.postOptionDetails.startDate = '';
      this.postOptionDetails.endDate = '';
    }
    this.searchItems();
  }

}
