import { Injectable } from '@angular/core';
import { ResolveEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isString } from 'lodash';
import { AnalyticsEventInterface } from '../models/interfaces/analytics-event.interface';
declare let gtag: any;
/**
 * GoogleAnalyticsService - Service for communicating pageViews/userData to Google Analytics
 */
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  // Initial Vars/Initial Config
  private routerSubscription: Subscription;
  /**
   * constructor function - Loads GA script and subscribes to route changes (if tracking enabled in config)
   * @param: (Service) router
   */
  constructor(private router: Router) {
    if (environment.tracking.enabled) {
      this.loadGoogleAnalytics(environment.tracking.googleAnalytics.trackingKey);
      this.routerSubscription = this.router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.logPageView(event.urlAfterRedirects);
        }
      });
    }
  }
  /**
   * loadGoogleAnalytics function - Loads the Google Analytics script into the head based on the trackingID param
   * @param: (String) trackingID
   */
  loadGoogleAnalytics(trackingID: string) {
    // Create script tag and request gTag script (with trackingID param)
    const gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${ trackingID }`);
    // Create the config script
    const gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function` +
      ` gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${ trackingID }\');`;
    // Append scripts to document
    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);
  }
  /**
   * setUserId function - Sets User Context for Google Analytics
   * @param: (String) userId
   */
  setUserId(userId: string) {
    if (environment.tracking.enabled) {
      if (gtag) {
        gtag('config', environment.tracking.googleAnalytics.trackingKey, {
          user_id: userId
        });
      }
    }
  }
  /**
   * clearUserId function - Clears User Context for Google Analytics
   */
  clearUserId() {
    if (environment.tracking.enabled) {
      if (gtag) {
        gtag('config', environment.tracking.googleAnalytics.trackingKey, {
          user_id: null
        });
      }
    }
  }
  /**
   * logPageView function - Calls gtag > config > page-details to track page view (with name and uri details)
   * @param: (String) name
   * @param: (String) uri
   */
  logPageView(name?: string, uri?: string) {
    if (environment.tracking.enabled && gtag) {
      gtag('config', environment.tracking.googleAnalytics.trackingKey, {
        page_title: uri,
        page_path: uri
      });
    }
  }
  logEvent(eventType: AnalyticsEventInterface): void {
    if (environment.tracking.enabled && gtag) {
      gtag('event', eventType.action, {
        event_category: eventType.category || 'Custom Event',
        event_label: eventType.label || eventType,
      });
    }
  }
  logException(error: any, fatal = false) {
    const description = isString(error) ? error : error?.stack ? error?.stack : error.message;
    if (environment.tracking.enabled && gtag) {
      gtag('event', 'exception', {
        description,
        fatal   // set to true if the error is fatal
      });
    }
  }
  /**
   * getActivatedComponent function - Determines which Component is active
   * @param: (ActivatedRouteSnapshot) snapshot
   */
  private getActivatedComponent(snapshot: any): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }
    return snapshot.component;
  }
}
