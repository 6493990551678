import { Injectable } from '@angular/core';
// Services
import { AlertService } from './alert.service';
// Enums
import { NotificationPermissionEnum } from '../models/enums/notification-permission.enum';

@Injectable({
  providedIn: 'root'
})
export class ChromeNotificationsService {

  constructor(private alertService: AlertService) {
  }

  requestPermissions() {
    if (!this.hasPermissions()) {
      alert('Please unblock notifications from within your chrome settings, if you need a hand with this, get in touch with one of the engineers and they can help you set this up.')
      Notification.requestPermission().then((permission) => {
        switch (permission) {
          case NotificationPermissionEnum.granted : {
            this.alertService.showAlert('PMS queue notifications are enabled.', 'success');
            break;
          }
          case NotificationPermissionEnum.denied : {
            this.alertService.showAlert('Denied permissions.', 'error');
            setTimeout(() => {
              this.alertService.showAlert('Please unblock notifications from within your chrome settings, if you need a hand with this, get in touch with one of the engineers and they can help you set this up.', 'error');
            }, 2000);
            break;
          }
        }
      }).catch(() => {
        this.alertService.showAlert('There was an issue setting notification permissions.', 'error');
        this.alertService.showAlert('Please unblock notifications from within your chrome settings, if you need a hand with this, get in touch with one of the engineers and they can help you set this up.', 'error')
      });
      return;
    }
    this.alertService.showAlert('PMS queue notifications are enabled.', 'success');
  }

  hasPermissions() {
    return Notification.permission === NotificationPermissionEnum.granted;
  }

  sendNotification(title: string, body: string) {
    if (!title || !body) {
      return;
    }
    if (this.hasPermissions()) {
      const notification = new Notification(title, { body, requireInteraction: false });
      notification.onshow = (() => {
        // tslint:disable-next-line:no-console
        console.info(`Notification sent: ${title} - ${body}`);
      });
    }
  }
}
