<div class="maintenance-container" *ngIf="maintenance">
  <ng-container *ngIf="maintenance.status === 'pending'">
    <ng-container *ngTemplateOutlet="Pending"></ng-container>
  </ng-container>
  <ng-container *ngIf="maintenance.status === 'imminent'">
    <ng-container *ngTemplateOutlet="Imminent"></ng-container>
  </ng-container>
  <ng-container *ngIf="maintenance.status === 'active'">
    <ng-container *ngTemplateOutlet="Active"></ng-container>
  </ng-container>
</div>

<ng-template #Pending>
  <img src="../../../../../assets/images/ic_onboarding_image_2.webp" height="150px" class="maintenance-img">
  <h4 class="title"><mat-icon>error_outline</mat-icon> The PMS will be entering maintenance soon !</h4>
  <p>Please continue to pick up calls. We will close the queue shortly before.</p>
  <div class="actions">
    <button class="download-btn" mat-flat-button (click)="onDismiss(this.maintenance?.status)" color="accent" mat-dialog-close><mat-icon>refresh</mat-icon>Dismiss</button>
  </div>
</ng-template>

<ng-template #Imminent>
  <img src="../../../../../assets/images/ic_onboarding_image_2.webp" height="150px" class="maintenance-img">
  <h4 class="title">We will be going into maintenance shortly.</h4>
  <p>Users not already in a call will be unable to use the PMS until after maintenance is completed. We will be live again in</p>
  <p class="countdown" *ngIf="clock && maintenance?.start">{{clock | maintenanceCountdown:maintenance.start:maintenance.duration}}</p>
  <p class="latest-update" *ngIf="maintenance?.message?.length > 0"><strong class="chalet">Latest update:</strong><br> {{maintenance?.message}}</p>
</ng-template>

<ng-template #Active>
  <img src="../../../../../assets/images/ic_onboarding_image_2.webp" height="150px" class="maintenance-img">
  <h4 class="title">We'll be right back !</h4>
  <p>The PMS is temporarily down for maintenance. We will be live again in</p>
  <p class="countdown" *ngIf="clock && maintenance?.start">{{clock | maintenanceCountdown:maintenance.start:maintenance.duration}}</p>
  <p class="latest-update" *ngIf="maintenance?.message?.length > 0"><strong>Latest update:</strong><br> {{maintenance?.message}}</p>
</ng-template>
