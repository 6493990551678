import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { interval, Observable, of, Subscription } from 'rxjs';
import { catchError, concatMap, mergeMap } from 'rxjs/operators';
import { find, first, uniq } from 'lodash';
// Services
import { AlertService } from '../../services/alert.service';
import { AddressService } from '../../services/address.service';
import { BasketService } from '../../services/basket.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { LabelPrinterService } from '../../services/label-printer.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { MedicationService } from '../../services/medication.service';
import { PatientNotesService } from '../../../consultation-shared/services/patient-notes.service';
import { PatientNoteManagerService } from '../../../consultation-shared/services/patient-note-manager.service';
import { PaymentsService } from '../../services/payments.service';
import { PracticeService } from '../../services/practice.service';
import { ProductsService } from '../../services/products.service';
import { WebsocketsService } from '../../services/websockets.service';
// Interfaces
import {
  ConsultationSelectedProductInterface
} from '../../../consultation-shared/models/interfaces/consultation-selected-product.interface';
import { PaymentTerminalsInterface } from '../../models/interfaces/payment-terminals.interface';
import { BasketInterface } from '../../models/interfaces/basket.interface';
import { BasketItemPayloadInterface } from '../../models/interfaces/basket-item-payload.interface';
import { PaymentConfigurationInterface } from '../../models/interfaces/payment-configuration.interface';
import { PaymentIntentInterface } from '../../models/interfaces/payment-intent.interface';
import { PaymentIntentConfigurationInterface } from '../../models/interfaces/payment-intent-configuration.interface';
import { PaymentStatusInterface } from '../../models/interfaces/payment-status.interface';
import { BasketItemInterface } from '../../models/interfaces/basket-item.interface';
import { MedicationInterface } from '../../../consultation-shared/models/interfaces/medication.interface';
// Enums
import { PaymentIntentStatusEnum } from '../../models/enums/payment-intent-status.enum';
import { TerminalStatusEnum } from '../../models/enums/terminal-status.enum';
// Label Template
import { prescriptionLabel } from '../../../../../assets/printer-labels/prescription-label';

/**
 * @component ClientBasketComponent
 * @description Angular component representing the client basket for managing
 *              selected products, addresses, and payments.
 */
@Component({
  selector: 'app-client-basket',
  templateUrl: './client-basket.component.html'
})
export class ClientBasketComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() clientID: number;
  @Input() practiceID: number;
  @Input() consultationID: number;

  pendingBasketProducts: Observable<Array<ConsultationSelectedProductInterface>>;
  confirmedBasket: Observable<BasketInterface>;
  activeTabIndex = 0;
  totalHeight = 0;
  editingAddress: boolean;
  checkoutBasket: boolean;
  selectedAddress: any;
  selectedTerminal: any;
  selectedPrinter: any;
  practiceDetails: any;
  printAllCount: number;
  printingAllLabels: boolean;
  printAllJobs: Array<any> = [];
  failedPrintAllLabels: Array<any> = [];
  terminalStatus: TerminalStatusEnum;
  paymentIntent: PaymentIntentInterface;
  paymentStatus: PaymentStatusInterface;
  availableAddresses: Array<any>;
  availableTerminals: Array<any>;
  availablePrinters: Array<any>;
  subscription: Subscription = new Subscription();
  paymentSubscription: Subscription;
  manualSubscription: Subscription;
  awaitingFinalisation: boolean;

  @ViewChild('Header') basketHeaderElement: ElementRef;
  @ViewChild('Footer') basketFooterElement: ElementRef;

  @Output() close = new EventEmitter();

  /**
   * @param addressService - The service for managing client addresses.
   * @param basketService - The service for managing the client basket.
   * @param errorHandlerService - The service for handling errors.
   * @param labelPrinterService - The service for printing labels.
   * @param paymentsService - The service for managing payments.
   * @param storageService - The service for local storage.
   * @param websocketsService - The service for handling websockets.
   * @param alertService - The service for displaying alerts.
   * @param practiceService - The service for retrieving practice details.
   * @param productsService - The service for managing products.
   * @param patientNoteService - The service for managing patient notes.
   * @param patientNoteManagerService - The service for managing patient notes.
   * @param medicationService - The service for managing medications.
   * @param cdRef - The change detector reference.
   * @param messageService - The service for displaying messages.
   */
  constructor(
    private readonly addressService: AddressService,
    private readonly basketService: BasketService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly labelPrinterService: LabelPrinterService,
    private readonly paymentsService: PaymentsService,
    private readonly storageService: LocalStorageService,
    private readonly websocketsService: WebsocketsService,
    private readonly alertService: AlertService,
    private readonly practiceService: PracticeService,
    private readonly productsService: ProductsService,
    private readonly patientNoteService: PatientNotesService,
    private readonly patientNoteManagerService: PatientNoteManagerService,
    private readonly medicationService: MedicationService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly messageService: MessageService
  ) {}
  /**
   * @description Lifecycle hook called after the component has been initialised.
   *              Initialises data and retrieves necessary information.
   */
  ngOnInit() {
    this.getAllTerminals();
    this.getPrinters(false);
    this.getUserAddresses();
    this.getPracticeDetails();
    this.pendingBasketProducts = this.basketService.pending();
    this.confirmedBasket = this.basketService.confirmed();
  }
  /**
   * @description Lifecycle hook called after the view has been initialised.
   *              Calls onTabChange set the initial tab view.
   */
  ngAfterViewInit() {
    this.onTabChange();
  }
  /**
   * @description Lifecycle hook called just before the component is destroyed.
   *              Unsubscribes from observables to prevent memory leaks.
   */
  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.paymentSubscription?.unsubscribe();
    this.manualSubscription?.unsubscribe();
    if (this.awaitingFinalisation) {
      this.basketService.setConfirmedBasket(this.clientID, this.practiceID);
      this.finaliseBasket();
    }
  }
  /**
   * @description Retrieves practice details based on the practice ID.
   */
  getPracticeDetails() {
    this.subscription.add(this.practiceService.getPracticeById(this.practiceID).subscribe(data => {
      this.practiceDetails = data;
    }, data => {
      this.messageService.add({key: 'sidebar', summary: 'Error', severity: 'error',
        detail: this.errorHandlerService.getErrorMessage(data?.error)});
    }));
  }
  /**
   * @description Retrieves all payment terminals for the specified practice.
   */
  getAllTerminals() {
    this.subscription.add(this.paymentsService.getTerminals(this.practiceID)
      .subscribe((data: PaymentTerminalsInterface) => {
      this.availableTerminals = data.terminals;
    }, (data) => {
      this.messageService.add({key: 'sidebar', summary: 'Error', severity: 'error',
        detail: this.errorHandlerService.getErrorMessage(data?.error)});
    }));
  }
  /**
   * @description Retrieves user addresses associated with the client.
   */
  getUserAddresses() {
    this.subscription.add(this.addressService.getUserAddresses(this.clientID).subscribe(data => {
      if (data.addresses.length > 0) {
        this.availableAddresses = data.addresses.map(x => {
          return {
            ...x,
            viewValue: `${x.house_number} ${x.street}, ${x.city}, ${x.postcode.toUpperCase()}`
          };
        });
        const cachedAddress = this.getCachedAddress();
        if (cachedAddress) {
          this.selectedAddress = cachedAddress;
        }
        if (!this.selectedAddress) {
          this.selectedAddress = this.getDefaultAddress();
        }
      } else {
        console.log('No address');
        this.availableAddresses = [];
        this.selectedAddress = undefined;
      }
    }, (data) => {
      this.errorHandlerService.handleError(data?.error || 'There was an error getting client addresses');
    }));
  }
  /**
   * @description Initiates the payment process for the client basket.
   */
  startPayment() {
    if (!this.selectedTerminal) {
      this.messageService.add({
        key: 'sidebar',
        summary: 'Error',
        severity: 'error',
        detail: 'POS device not selected'
      });
      return; // Do not proceed with payment if selectedTerminal is undefined
    }

    if (!this.paymentIntent) {
      this.messageService.add({
        key: 'sidebar',
        summary: 'Error',
        severity: 'error',
        detail: 'Payment intent not available'
      });
      return; // Do not proceed with payment if paymentIntent is undefined
    }

    this.terminalStatus = TerminalStatusEnum.connectingReader;

    const payment: PaymentConfigurationInterface = {
      amount: this.basketService.confirmedTotal(),
      description: 'PMS Manual Payment',
      payment_intent_id: this.paymentIntent.id,
      card_reader_id: this.selectedTerminal.id,
      payment_type: 'in-practice',
      user_id: this.clientID
    };

    this.paymentsService.startPayment(payment).subscribe(async (result) => {
      this.terminalStatus = TerminalStatusEnum.presentCard;
      this.paymentStatus = result;
      this.listenToPaymentEvents(this.paymentIntent);
    }, (data) => {
      this.terminalStatus = TerminalStatusEnum.idle;
      this.messageService.add({key: 'sidebar', summary: 'Error', severity: 'error',
        detail: this.errorHandlerService.getErrorMessage(data?.error)});
    });
  }
  /**
   * listenToPaymentEvents - Subscribe to payment status events from the websockets service. Process payment
   * messages and update the paymentIntent prop and UI loading status on completion.
   * @param originalIntent - The original payment intent to compare against the websocket messages
   */
  listenToPaymentEvents(originalIntent: PaymentIntentInterface): void {
    if (this.paymentSubscription) {
      this.paymentSubscription?.unsubscribe();
    }
    this.paymentSubscription = new Subscription();
    // Check for payment status via poll to get linear updates
    this.paymentSubscription.add(
      interval(3000).pipe(
        concatMap(x => this.paymentsService.getPaymentIntent(originalIntent.id)),
        catchError(error => {
          // Handle the error (if needed)
          return of(this.paymentIntent);
        })
      ).subscribe(result => {
        this.handlePaymentIntentUpdate(result);
      })
    );
    // Check for payment status via websockets.
    this.websocketsService.startMessages();
    this.paymentSubscription.add(
      this.websocketsService.getPaymentMessages().subscribe(result => {
        if (!result?.payload?.id) {
          return;
        }
        if (originalIntent.id === result?.payload.id) {
          this.handlePaymentIntentUpdate(result.payload);
          if (this.paymentIntent.status === 'succeeded') {
            this.manualSubscription?.unsubscribe();
          }
        }
      }, data => {
        this.errorHandlerService.handleError(
          data?.error || 'There was an error receiving payment updates. Please try again or contact support.'
        );
      })
    );
  }
  /**
   * @description Cancels the ongoing payment process.
   */
  cancelPayment() {
    if (!this.selectedTerminal) {
      this.messageService.add({
        key: 'sidebar',
        summary: 'Error',
        severity: 'error',
        detail: 'POS device not selected for cancellation'
      });
      return;
    }
    this.checkoutBasket = false;
    this.terminalStatus = TerminalStatusEnum.idle;
    this.subscription.add(this.paymentsService.cancelTerminalPayment(this.selectedTerminal.id).subscribe(result => {
      this.terminalStatus = TerminalStatusEnum.paymentCancelled;
      this.paymentStatus = undefined;
      this.manualSubscription?.unsubscribe();
      this.paymentSubscription?.unsubscribe();
    }, data => {
      this.messageService.add({key: 'sidebar', summary: 'Error', severity: 'error',
        detail: this.errorHandlerService.getErrorMessage(data?.error)});
    }));
  }


  /**
   * @description Update payment intent and update UI status of the payment.
   *              For manual updates, show an alert with the current status.
   * @param newIntent - The updated payment intent.
   * @param manualUpdate - Indicates whether the update is manual.
   */
  handlePaymentIntentUpdate(newIntent: PaymentIntentInterface, manualUpdate = false) {
    switch (newIntent.status) {
      case PaymentIntentStatusEnum.requires_payment_method: {
        this.terminalStatus = TerminalStatusEnum.presentCard;
        if (manualUpdate) {
          this.alertService.showAlert(`Current Status - Requires Payment Method`, 'info');
        }
        break;
      }
      case PaymentIntentStatusEnum.canceled: {
        this.terminalStatus = TerminalStatusEnum.idle;
        if (manualUpdate) {
          this.alertService.showAlert(`Current Status - Payment Cancelled - Please start a new payment`, 'info');
        }
        break;
      }
      case PaymentIntentStatusEnum.processing : {
        this.terminalStatus = TerminalStatusEnum.processingPayment;
        if (manualUpdate) {
          this.alertService.showAlert(`Current Status - Processing Payment`, 'info');
        }
        break;
      }
      case PaymentIntentStatusEnum.requires_action : {
        this.terminalStatus = TerminalStatusEnum.paymentRequiresAction;
        if (manualUpdate) {
          this.alertService.showAlert(`Current Status - Requires Action - ` +
              `Additional authentication required`, 'info');
        }
        break;
      }
      case PaymentIntentStatusEnum.succeeded : {
        this.terminalStatus = TerminalStatusEnum.paymentSuccessful;
        this.paymentSubscription.unsubscribe();
        if (this.paymentIntent && newIntent.status !== this.paymentIntent.status) {
          this.messageService.add({key: 'sidebar', summary: 'Success', severity: 'success', detail: 'Payment Successful!'});
          this.patientNoteManagerService.updatePatientNoteFromAPI(this.consultationID);
          this.awaitingFinalisation = true;
        }
        break;
      }
    }
    this.paymentIntent = this.storageService.setData('paymentIntent', newIntent as PaymentIntentInterface);
  }

  /**
   * @description Finalises the client basket by setting the confirmed basket and emitting a close event.
   */
  finaliseBasket() {
    this.close.emit({final: true});
  }
  /**
   * @description Handles the change of the selected address.
   * @param address - The new selected address.
   */
  handleAddressChange(address) {
    this.selectedAddress = this.storageService.setData('basketAddress', address);
    this.editingAddress = false;
  }

  /**
   * @description Sets the editingAddress flag to true, indicating the user is editing the address.
   */
  editAddress() {
    this.editingAddress = true;
  }

  /**
   * @description Retrieves the cached address from storage and maps it to availableAddresses.
   * @returns {any} - The cached address, or null if not found.
   */
  getCachedAddress(): any {
    const cachedAddress = this.storageService.getData('basketAddress');
    if (cachedAddress) {
      return find(this.availableAddresses, { id: cachedAddress.id });
    }
    return null;
  }

  /**
   * @description Retrieves the default address from availableAddresses or the first address if not found.
   * @returns {any} - The default address.
   */
  getDefaultAddress(): any {
    return this.availableAddresses.find((x) => x.default) || first(this.availableAddresses);
  }

  /**
   * @description Initiates the checkout process by creating a new payment intent.
   */
  displayCheckout() {
    const config: PaymentIntentConfigurationInterface = {
      amount: this.basketService.confirmedTotal(),
      practice_id: this.practiceID
    };
    this.subscription.add(
        this.paymentsService.createNewPaymentIntent(config).subscribe(result => {
          this.terminalStatus = TerminalStatusEnum.idle;
          this.checkoutBasket = true;
          this.paymentIntent = result;
        }, data => {
          this.messageService.add({key: 'sidebar', summary: 'Error', severity: 'error',
            detail: this.errorHandlerService.getErrorMessage(data?.error)});
          this.paymentIntent = undefined;
          this.checkoutBasket = false;
        })
    );
  }

  /**
   * @description Handles tab changes, updating UI and triggering change detection.
   */
  onTabChange() {
    setTimeout(() => {
      if (this.activeTabIndex === 0) {
        this.checkoutBasket = false;
        this.terminalStatus = TerminalStatusEnum.idle;
      }
      this.setContentHeight();
      this.cdRef.detectChanges(); // Trigger change detection
    }, this.activeTabIndex === 0 ? 0 : 200);
  }
  /**
   * @description Calculates and sets the total height based on header and footer heights.
   */
  setContentHeight() {
    // Get the header and footer heights
    const headerHeight = this.basketHeaderElement.nativeElement.offsetHeight;
    const footerHeight = this.basketFooterElement.nativeElement.offsetHeight;
    this.totalHeight = headerHeight + footerHeight;
  }

  /**
   * @description Removes a product from the pending basket.
   * @param product - The product to be removed.
   * @param index - The index of the product in the basket.
   */
  removeProductFromPendingBasket(product, index): void {
    this.basketService.removeItemFromClientBasket(product, index);
  }

  /**
   * @description Removes a product from the confirmed basket.
   * @param product - The product to be removed.
   */
  removeProductFromConfirmedBasket(product) {
    this.basketService.removeItemFromConfirmedBasket(this.clientID, this.practiceID, product).subscribe((result) => {
      this.basketService.setConfirmedBasket(this.clientID, this.practiceID);
    });
  }

  /**
   * @description Edits a basket item, removing it from the confirmed basket and adding it to the client basket.
   * @param basketItem - The basket item to be edited.
   */
  editBasketItem(basketItem: BasketItemInterface) {
    this.basketService.removeItemFromConfirmedBasket(this.clientID, this.practiceID, basketItem).pipe(
      mergeMap(x => this.getMedicationById(basketItem.source.id))
    ).subscribe(medication => {
      this.basketService.setConfirmedBasket(this.clientID, this.practiceID);
      if (medication) {
        this.basketService.addProductToClientBasket(medication);
        this.activeTabIndex = 0;
      }
    }, data => {
      this.messageService.add({key: 'sidebar', summary: 'Error', severity: 'error',
        detail: this.errorHandlerService.getErrorMessage(data?.error)});
    });
  }

  /**
   * @description Confirms a basket item by adding it to the confirmed basket and removing it from the client basket.
   * @param medication - The medication to be confirmed.
   */
  confirmBasketItem(medication: any) {
    // Ensure required IDs, product ID, and batch numbers are set
    if (
      !this.clientID ||
      !this.practiceID ||
      !this.selectedAddress?.id ||
      !medication.product?.id ||
      !medication.batchNumbers ||
      medication.batchNumbers.length === 0
    ) {
      const errorMessage = 'Missing required IDs, product ID, or batch numbers for confirming the basket item.';
      this.messageService.add({ key: 'sidebar', summary: 'Error', severity: 'error', detail: errorMessage });
      return; // Fail safely and exit the function
    }

    const basketPayload: BasketItemPayloadInterface = {
      user_id: this.clientID,
      practice_id: this.practiceID,
      content_id: medication.product.id,
      type: 'product',
      source: {id: medication.id, type: 'medication'},
      address_id: this.selectedAddress.id,
      batches: uniq(medication.batchNumbers.map(x => x.id)),
      quantity: medication.quantity
    };
    this.basketService.addItemToConfirmedBasket(this.clientID, this.practiceID, basketPayload).subscribe((result) => {
      this.basketService.removeItemFromClientBasket(medication, this.basketService.getMedicationIndex(medication));
      this.basketService.setConfirmedBasket(this.clientID, this.practiceID);
    }, data => {
      this.messageService.add({key: 'sidebar', summary: 'Error', severity: 'error',
        detail: this.errorHandlerService.getErrorMessage(data?.error)});
    });
  }

  /**
   * @description Handles changes in pending products, updating quantity and batch numbers.
   * @param products - Array of consultation selected products.
   * @param update - Updated product information.
   */
  handlePendingProductChange(products: Array<ConsultationSelectedProductInterface>, update): void {
    const updatedProducts = products.map(existing => {
      if (existing.product.doc_id !== update.doc_id) {
        return existing;
      }
      const updated = {
        ...existing,
        quantity: update.quantity,
        batchNumbers: update.batchNumbers
      };
      if (update.prescription) {
        updated.prescription = {
            ...update.prescription,
            unit_quantity: update.quantity
        };
      }
      return updated;
    });
    this.basketService.updatePendingBasket(updatedProducts);
  }
  /**
   * @description TrackBy function for ngFor to optimise rendering by tracking item changes using the product ID.
   * @param index - The index of the item in the array.
   * @param item - The item being tracked.
   * @returns {number} - The product ID, or undefined if item or item.product is undefined.
   */
  trackBy(index, item) {
    return item?.product?.id;
  }

  /**
   * @description Gets available printers. If retry is true or local data is not available, fetches printers from the server.
   * @param retry - Flag indicating whether to retry fetching printers from the server.
   */
  getPrinters(retry: boolean) {
    const localPrintersCheck = this.storageService.getData('basketAvailablePrinters');
    const localSelectedCheck = this.storageService.getData('basketSelectedPrinter');
    if (!localPrintersCheck || retry) {
      this.labelPrinterService.getPrinters().subscribe(data => {
        this.availablePrinters = this.labelPrinterService.parsePrinterDetails(data);
        this.storageService.setData('basketAvailablePrinters', this.availablePrinters);
        if (this.availablePrinters && this.availablePrinters.length > 0) {
          this.selectedPrinter = this.availablePrinters[0];
          this.storageService.setData('basketSelectedPrinter', this.selectedPrinter);
        }
      }, data => {
        this.messageService.add({key: 'sidebar', summary: 'Error', severity: 'error',
          detail: this.errorHandlerService.getErrorMessage(data?.message || 'There was a problem connecting to Dymo Connect')});
      });
    } else {
      this.availablePrinters = localPrintersCheck;
      this.selectedPrinter = localSelectedCheck;
    }
  }

  /**
   * @description Initiates the printing of labels for all items in the confirmed basket.
   */
  setupPrintAllLabels() {
    this.printingAllLabels = true;
    this.printAllCount = 0;
    this.printAllJobs = [];
    this.subscription.add(this.basketService.getAPIConfirmedBasket(this.clientID,
      this.practiceID).subscribe(data => {
      this.printAllCount = data.items.length;
      data.items.forEach(item => {
        this.subscription.add(this.productsService.getPrescriptionLabels(this.clientID, this.practiceID,
          false, item.source.id).subscribe(labelData => {
          // Push label config to print all jobs
          this.printAllJobs.push({label_data: labelData, item_details: item, printed: false});
          // Once all label data gathered call printAllLabels
          if (this.printAllJobs.length === this.printAllCount) {
            this.printAllLabels();
          }
        }, labelData => {
          if (this.failedPrintAllLabels.indexOf(item) === -1) {
            this.failedPrintAllLabels.push({label_data: null, item_details: item, printed: false});
          }
          if (this.printAllCount - this.failedPrintAllLabels.length === 0) {
            this.printingAllLabels = false;
          }
          this.messageService.add({key: 'sidebar', summary: 'Error', severity: 'error',
            detail: this.errorHandlerService.getErrorMessage(labelData?.error)});
        }));
      });
    }, data => {
      this.printingAllLabels = false;
      this.messageService.add({key: 'sidebar', summary: 'Error', severity: 'error',
        detail: this.errorHandlerService.getErrorMessage(data?.error)});
    }));
  }

  /**
   * @description Filters printAllJobs (by printed false) and uses the first item to call handleLabelDetails
   */
  printAllLabels() {
    const nextLabelCheck = this.printAllJobs.filter(label => label.printed === false);
    if (nextLabelCheck.length > 0) {
      this.handleLabelDetails(nextLabelCheck[0]);
    }
  }

  /**
   * @description Handles label details and initiates the printing process.
   * @param job - Data containing label details.
   */
  handleLabelDetails(job) {
    if (job.label_data && job.label_data.length > 0) {
      job.label_data[0].practice_address = this.practiceDetails.address;
      const labelDetails = this.labelPrinterService.readyLabel(prescriptionLabel, job.label_data[0]);
      this.labelPrinterService.printLabel(this.selectedPrinter, labelDetails).subscribe(printData => {
        job.printed = true;
        this.printAllCount--;
        this.printAllLabels();
        if (this.failedPrintAllLabels.indexOf(job.item) !== -1) {
          this.failedPrintAllLabels.splice(this.failedPrintAllLabels.indexOf(job.item), 1);
        }
        if (this.printAllCount === 0 || (this.printAllCount - this.failedPrintAllLabels.length === 0)) {
          this.printingAllLabels = false;
        }
      }, printData => {
        this.printAllCount--;
        this.printAllJobs.splice(this.printAllJobs.indexOf(job), 1);
        if (this.failedPrintAllLabels.indexOf(job.item) === -1) {
          this.failedPrintAllLabels.push(job);
        }
        if (this.printAllCount - this.failedPrintAllLabels.length === 0) {
          this.printingAllLabels = false;
        }
        this.messageService.add({key: 'sidebar', summary: 'Error', severity: 'error', sticky: true,
          detail: this.errorHandlerService.getErrorMessage('Problem printing label')});
        if (this.printAllCount > 0) {
          this.printAllLabels();
        }
      });
    }
  }

  /**
   * @description Retries printing labels for items that previously failed to print.
   */
  retryPrintAll() {
    this.printAllJobs = [];
    let needsRePrint = false;
    this.failedPrintAllLabels.forEach(label => {
      needsRePrint = label.label_data && !label.printed;
    });
    if (needsRePrint) {
      this.printAllCount = this.failedPrintAllLabels.length;
      this.printAllJobs = this.failedPrintAllLabels;
      this.printAllLabels();
    }
  }

  /**
   * @description Manually calls the API to check the status of an active payment.
   */
  updatePaymentStatus(): void {
    this.manualSubscription.add(
      this.paymentsService.getPaymentIntent(this.paymentIntent.id).subscribe(result => {
        this.handlePaymentIntentUpdate(result, true);
      }, data => {
        this.messageService.add({key: 'sidebar', summary: 'Error', severity: 'error',
          detail: this.errorHandlerService.getErrorMessage(data?.error)});
      })
    );
  }

  /**
   * @description Gets medication information by ID.
   * @param ID - The ID of the medication.
   * @returns {Observable<MedicationInterface>} - An observable of medication details.
   */

  getMedicationById(ID: number): Observable<MedicationInterface> {
    return this.medicationService.getMedicationById(ID).pipe(
      catchError(data => {
        this.messageService.add({key: 'sidebar', summary: 'Error', severity: 'error',
          detail: this.errorHandlerService.getErrorMessage(data?.error)});
        return of(null);
      })
    );
  }
}
