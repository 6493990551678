<div @list>
    <ng-container *ngFor="let notice of noticeMessages | async">
      <ng-container *ngTemplateOutlet="Banner;context:{message: notice}"></ng-container>
    </ng-container>
    <ng-container *ngFor="let message of messages | async">
      <ng-container *ngTemplateOutlet="Banner;context:{message: message}"></ng-container>
    </ng-container>
  <ng-container *ngFor="let message of inlineMessages">
    <ng-container *ngTemplateOutlet="Banner;context:{message: message}"></ng-container>
  </ng-container>
</div>

<ng-template #Banner let-message="message">
  <div
    *ngIf="message?.messageType === messageTypeEnum.banner"
    class="banner banner-status-{{messageStatusTypeEnum[message?.statusType]}}"
    @message-items>
    <mat-icon *ngIf="message?.icon">{{message?.icon}}</mat-icon>
    <span>{{message?.message}}</span>
  </div>
</ng-template>
