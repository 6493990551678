import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'petVariables'
})
export class PetVariablesPipe implements PipeTransform {

  transform(value: string, petName: string): any {
    return value?.replace(new RegExp('{petName}', 'g'), petName);
  }

}
