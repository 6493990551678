import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from './google-analytics.service';
import { AnalyticsEventInterface } from '../models/interfaces/analytics-event.interface';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(
    private readonly googleAnalytics: GoogleAnalyticsService
  ) { }
  /**
   * logEvent function - Log a custom event to google analytics
   */
  public logAnalyticsEvent(customEvent: AnalyticsEventInterface): void {
    if (environment.tracking?.console_logging_enabled) {
      console.log('Log Analytics Event', customEvent);
    }
    this.googleAnalytics.logEvent(customEvent);
  }

  public logAnalyticsError(error: any, fatal = false) {
    if (environment.tracking?.console_logging_enabled) {
      console.error('Log Analytics Exception', error);
    }
    this.googleAnalytics.logException(error, fatal);
  }
}
